import React, { useState } from "react";
import Helmet from "react-helmet";
import "./RedefinePassword.scss";
import Input from "../../../global/form/Input/Input";
import Button from "../../../global/Button/Button";
import LoaderButton from "../../../global/LoaderButton/LoaderButton";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../../redux/actions/user";
import { useEmailField } from "../../../../hooks/formHooks";

const RedefinePassword = () => {
  const history = useHistory();
  const [email, setEmail, validEmail] = useEmailField("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const userData = useSelector((state) => state.user?.data);
  const dispatch = useDispatch();

  const handleRecoverPasswordRequest = (event) => {
    event.preventDefault();
    setCheckEmail(false)
    if (!validEmail) {
      setCheckEmail(true);
    } else {
      setIsLoading(true);
      dispatch(forgotPassword(userData?.token, { email }))
        .then(() => {
          setModalMessage("Código enviado com sucesso!");
          setIsLoading(false);
          if (window.innerWidth > 1024) {
            setShowModal(true);
          } else {
            history.push({
              pathname: "redefinir/codigo",
              state: { email, token: userData?.token }
            });
          } 
        })
        .catch(() => {
          setModalMessage("Ocorreu um erro ao tentar enviar o email");
          setIsLoading(false);
          setShowModal(true);
        });
    }
  };

  return (
    <div className="RedefinePassword">
      <Helmet title="Recuperação de Senha - Alexandria" />
      <h2>Recuperar senha</h2>
      <h3>Esqueceu a senha?</h3>
      <Input
        updateState={setEmail}
        type="email"
        valid={validEmail}
        displayError={checkEmail}
        errorMessage="Email inválido"
        placeholder="Email"
        value={email}
      />
      <p>Enviaremos um código de recuperação de senha para o seu email.</p>
      {isLoading ? (
        <LoaderButton />
      ) : (
        <Button
          primaryColor="#CFEC59"
          children="Enviar"
          onClick={(event) => handleRecoverPasswordRequest(event)}
        />
      )}
      <div className="comeback-login">
        <Button
          primaryColor="#FFFFFF"
          children="Voltar"
          onClick={() => history.push("/login")}
        />
      </div>
      {showModal && (
        <BluePopUp
          text={modalMessage}
          onClick={() => {
            setShowModal(false);
            history.push({
              pathname: "redefinir/codigo",
              state: { email, token: userData?.token }
            });
          }}
        />
      )}
    </div>
  );
};

export default RedefinePassword;
