export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED = "USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED";
export const USER_FORGOT_PASSWORD_REQUEST_FAILED = "USER_FORGOT_PASSWORD_REQUEST_FAILED";

export const NEW_PASSWORD_REQUEST = "NEW_PASSWORD_REQUEST";
export const NEW_PASSWORD_REQUEST_SUCCEEDED = "NEW_PASSWORD_REQUEST_SUCCEEDED";
export const NEW_PASSWORD_REQUEST_FAILED = "NEW_PASSWORD_REQUEST_FAILED";

export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_REQUEST_SUCCEEDED = "GET_USER_INFO_REQUEST_SUCCEEDED";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_REQUEST_FAILED";

export const UPDATE_USER_INFO_REQUEST = "UPDATE_USER_INFO_REQUEST";
export const UPDATE_USER_INFO_REQUEST_SUCCEEDED = "UPDATE_USER_INFO_REQUEST_SUCCEEDED";
export const UPDATE_USER_INFO_FAILED = "UPDATE_USER_INFO_REQUEST_FAILED";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_REQUEST_SUCCEEDED = "UPDATE_PASSWORD_REQUEST_SUCCEEDED";
export const UPDATE_PASSWORD_REQUEST_FAILED = "UPDATE_PASSWORD_REQUEST_FAILED";

export const UPLOAD_USER_PHOTO_REQUEST = "UPLOAD_USER_PHOTO_REQUEST";
export const UPLOAD_USER_PHOTO_REQUEST_SUCCEEDED = "UPLOAD_USER_PHOTO_REQUEST_SUCCEEDED";
export const UPLOAD_USER_PHOTO_FAILED = "UPLOAD_USER_PHOTO_REQUEST_FAILED";

export const SET_USER_INFO = "SET_USER_INFO";