import React from 'react';
import './ResearchInformation.scss';
import ResultTag from "./ResultTag/ResultTag";
import {useSelector} from "react-redux";

const ResearchInformation = ({tags, setTags, search}) => {

  const globalTags = useSelector(state => state.tags);

  const getTagName = (id) => {
      for (let tag of globalTags.data) {
          if(tag.id === id) {
              return tag.name;
          }
      }
  }

  const removeTag = (id) => {
    const newList = [...tags];
    newList.splice(tags.indexOf(id), 1)
    setTags(newList)
  }

  return (
    <div className="ResearchInformation">
      <p>Resultados para: </p>{search && <h3>{search}</h3>}
      <div style={{display: search && "none"}} className="tags-result">
        <div className="adjust-tags">
          {tags && tags?.map((id, key) => (
            <ResultTag onClick={() => {
              removeTag(id)
            }} name={getTagName(id)} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResearchInformation;
