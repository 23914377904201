import React, { useState } from "react";
import "./SearchArea.scss";
import SearchTag from "../SearchTag/SearchTag";
import TagList from "../TagList/TagList";

const SearchArea = ({items, activeTag, setActiveTag}) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div className="search-area">
      <h2>Como deseja iniciar <strong>sua jornada?</strong></h2>
        <div className="search-area-container">
        <div className="search-area-top">
          <SearchTag activeTag={activeTag} setActiveTag={setActiveTag} />
          <TagList items={items} activeTag={activeTag} setActiveTag={setActiveTag} isShown={isShown} setIsShown={setIsShown}  onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)} />
        </div>
      </div>
    </div>
  );
};

export default SearchArea;
