import React from 'react';
import "./TagFollow.scss";

const TagFollow = ({invertColors, item, onClick, active}) => {
  return (
    <button className={`TagFollow ${invertColors ? "invertColors" : ""} ${active ? "active" : ""}`} onClick={onClick}>
      {item && item}
    </button>
  );
};

export default TagFollow;
