import {baseUrl, buildHeaders, getResponseOrThrow, urlFilters} from "./common";

export  const apiContents = (filters) => {
  return fetch(urlFilters(`${baseUrl}/content/contents`, filters),
    {
      method: "GET",
      headers: buildHeaders()
    }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((error) => Promise.reject(error));
};

export  const apiUserContents = (filters, userID) => {
  return fetch(urlFilters(`${baseUrl}/content/user/${userID}/contents`, filters),
    {
      method: "GET",
      headers: buildHeaders()
    }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((error) => Promise.reject(error));
};
