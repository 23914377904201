import {
  authorizedFetch,
  baseUrl,
  buildHeaders,
  getResponseOrThrow,
} from "./common";

export const apiUploadImage = (payload) => {
  let body = new FormData();
  body.append("file", payload.file);

  return authorizedFetch(`${baseUrl}/user/upload`, {
    method: "POST",
    headers: buildHeaders("", true),
    body: body,
  })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};
