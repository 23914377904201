import {GET_HISTORY_REQUEST, GET_HISTORY_REQUEST_FAILED, GET_HISTORY_REQUEST_SUCCEEDED} from "../constants/history";

export const getHistoryRequest = () => {
  return {
    type: GET_HISTORY_REQUEST
  }
};

export const getHistoryRequestSucceeded = (json) => {
  return {
    type: GET_HISTORY_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getHistoryRequestFailed = (error) => {
  return {
    type: GET_HISTORY_REQUEST_FAILED,
    error: error
  }
};
