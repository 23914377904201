import React, {useEffect, useState} from 'react';
import './TagList.scss';
import Tag from "../Tag/Tag";
import {useHistory} from "react-router-dom";
import ZoomButton from '../ZoomButton/ZoomButton';

const TagList = ({activeTag, setActiveTag, items, isShown, onMouseEnter, onMouseLeave}) => {
  const history = useHistory();
  const [selectedTags, setSelectedTags] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const tagsList = [];
  const widthWindow = window.innerWidth;

  Array.isArray(items) && items.forEach((name) => (
    tagsList.push(name)
  ))

  let listOne = [];
  let listTwo = [];
  let listThree = [];
  let listFour = [];
  let listFive = [];
  let listSix = [];

  if (widthWindow > 1440) {
    listOne = tagsList.splice(0, 11);
    listTwo = tagsList.splice(0, 10);
    listThree = tagsList.splice(0, 11);
    listFour = tagsList.splice(0, 10);
    listFive = tagsList.splice(0, 11);
    listSix = tagsList.splice(0, 10);
  } else {
    listOne = tagsList.splice(0, 8)
    listTwo = tagsList.splice(0, 7);
    listThree = tagsList.splice(0, 8);
    listFour = tagsList.splice(0, 7);
    listFive = tagsList.splice(0, 8);
    listSix = tagsList.splice(0, 7);
  }

  const handleActive = () => {
    setActiveTag(true);
  }

  const handleClicked = (item) => {
    let state = [...selectedTags];
    let index = state.indexOf(item);
    if (index === -1) {
      state.push(item)
    } else {
      state.splice(index, 1);
    }
    setSelectedTags(state);
    setShowMessage(false);
  }

  useEffect(() => {
    if (!activeTag) {
      isShown ? handleClicked(listOne[0]?.id) : setSelectedTags([])
    }
  }, [isShown]);

  useEffect(() => {
    activeTag && handleClicked(listOne[0]?.id)
  }, [activeTag])

  const getQueryString = () => {
    let string = '?'
    for(let tag of selectedTags) {
      if (string.length > 1) {
        string += "&"
      }
      string += "tags=" + tag;
    }
    return string
  }

  return (
    <>
      {activeTag && <button onClick={() => setActiveTag(false)} className='exit-tags'><span>➜</span><p>Voltar</p></button>}
      <div className={`TagList ${activeTag && 'active'}`} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
      <div className="tags-container" onClick={() => {handleActive()}}>
      <p className='title-search'>Busque por <strong>Tags</strong></p>
        <div className="tags-gradient" />
        <div className="tag">
          {listOne.map((item, key) => {
            let active = selectedTags.indexOf(item.id) !== -1;
            return <Tag passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="tag">
          {listTwo.map((item, key) => {
            let active = selectedTags.indexOf(item.id) !== -1;
            return <Tag passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="tag">
          {listThree.map((item, key) => {
            let active = selectedTags.indexOf(item.id) !== -1;
            return <Tag passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="tag">
          {listFour.map((item, key) => {
            let active = selectedTags.indexOf(item.id) !== -1;
            return <Tag passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="tag">
          {listFive.map((item, key) => {
            let active = selectedTags.indexOf(item.id) !== -1;
            return <Tag passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="tag">
          {listSix.map((item, key) => {
            let active = selectedTags.indexOf(item.id) !== -1;
            return <Tag passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
      </div>
      <div>
        {showMessage && <p className="tag-error">Selecione ao menos uma tag!</p>}
        {activeTag && <ZoomButton text="Buscar" onClick={
          () => selectedTags.length > 0 ? 
            history.push('/pesquisa' + getQueryString()) :
            setShowMessage(true)
        }/>}
      </div>
    </div>
    </>
    
  );
};

export default TagList;
