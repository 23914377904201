export const titlePlatforms = {
  LUNE: "Lune",
  ALEXANDRIA: "Alexandria",
  BAIAO_BINARIO: "Baião Binário",
  BLOG: "Blog",
  CULTURA: "Cultura",
  JUKEBOX: "Jukebox",
  E2W: "E2W"
}

export const origins = {
  INTERNATIONAL: "Internacional",
  NATIONAL: "Nacional"
}

export const mediaTypes = {
  AUDIO: "Áudio",
  VIDEO: "Vídeo",
  TEXT: "Texto",
}

export const contentCreationFields = {
  title: "título",
  platform: "plataforma",
  mediaType: "tipo de mídia",
  estimatedDuration: "tempo estimado",
  isAccessible: "acessível",
  previewImage: "link da imagem",
  shortDescription: "breve descrição",
  content: "texto do conteúdo",
  origin: "origem",
  tags: "tags",
  diversityAspects: "aspectos de diversidade",
  communicationVehicle: "veículo de comunicação",
  isDraft: "rascunho",
}
