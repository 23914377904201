import React from "react";
import GoogleLogin from "react-google-login";
import { GOOGLE_CLIENT_ID } from "../../../constants/login";
import "./GoogleSignIn.scss";
import {
  registerGoogleLogin,
  registerGoogleLoginFailed,
} from "../../../redux/actions/login";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateUserStorage } from "../../../helper/loginStorage";

const GoogleSignIn = ({ buttonText, openDesktop }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSuccess = (response) => {
    const googleData = {
      tokenId: response.tokenId,
    };

    dispatch(registerGoogleLogin(googleData))
      .then((json) => {
        updateUserStorage(json);
        setTimeout(() => {
          openDesktop ? window.location.reload() : history.push("/");
        }, 1000);
      })
      .catch(() => {});
  };

  const onFailure = (response) => {
    dispatch(registerGoogleLoginFailed(response.error));
  };

  return (
    <div className="GoogleSignIn">
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText={buttonText}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
    </div>
  );
};

export default GoogleSignIn;
