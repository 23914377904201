export const GET_CONTENT_REQUEST = "GET_CONTENT_REQUEST";
export const GET_CONTENT_REQUEST_SUCCEEDED = "GET_CONTENT_REQUEST_SUCCEEDED";
export const GET_CONTENT_REQUEST_FAILED = "GET_CONTENT_REQUEST_FAILED";

export const CREATE_CONTENT_REQUEST = "CREATE_CONTENT_REQUEST";
export const CREATE_CONTENT_REQUEST_SUCCEEDED = "CREATE_CONTENT_REQUEST_SUCCEEDED";
export const CREATE_CONTENT_REQUEST_FAILED = "CREATE_CONTENT_REQUEST_FAILED";

export const DELETE_CONTENT_REQUEST = "DELETE_CONTENT_REQUEST";
export const DELETE_CONTENT_REQUEST_SUCCEEDED = "DELETE_CONTENT_REQUEST_SUCCEEDED";
export const DELETE_CONTENT_REQUEST_FAILED = "DELETE_CONTENTS_REQUEST_FAILED";

export const UPDATE_CONTENT_REQUEST = "UPDATE_CONTENT_REQUEST";
export const UPDATE_CONTENT_REQUEST_SUCCEEDED = "UPDATE_CONTENT_REQUEST_SUCCEEDED";
export const UPDATE_CONTENT_REQUEST_FAILED = "UPDATE_CONTENT_REQUEST_FAILED";
