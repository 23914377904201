import React from 'react';
import UserProfileInfo from "../UserProfileInfo/UserProfileInfo";
import CollectionList from "../collectionInfo/CollectionList/CollectionList";
import SeeLater from "../SeeLater/SeeLater";
import Notes from "../notes/Notes/Notes";
import History from "../history/History/History";
import PrivateRoute from "../../privateRoute/PrivateRoute/PrivateRoute";
import Favorite from "../Favorite/Favorite";
import MyContents from "../MyContents/MyContents";

const UserProfile = () => {
  return (
    <div className="UserProfile">
      <UserProfileInfo />
      <PrivateRoute path="/perfil/meus-conteudos" exact={false} ><MyContents/></PrivateRoute>
      <PrivateRoute path="/perfil/colecao" exact={false}><CollectionList/></PrivateRoute>
      <PrivateRoute path="/perfil/salvos" exact={false} ><SeeLater/></PrivateRoute>
      <PrivateRoute path="/perfil/notas" exact={false} ><Notes/></PrivateRoute>
      <PrivateRoute path="/perfil/favoritos" exact={false} ><Favorite/></PrivateRoute>
      <PrivateRoute path="/perfil/historico" exact={false} ><History/></PrivateRoute>
    </div>
  );
};

export default UserProfile;
