import React, { useEffect, useState } from "react";
import "./CommentListMobile.scss";
import ContentComment from "../ContentComment/ContentComment";
import CommentTextMobile from "../../global/CommentTextMobile/CommentTextMobile";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const CommentListMobile = ({ setOpenComment, openComment, comments }) => {
  const history = useHistory();
  const [createComment, setCreateComment] = useState(false);
  const userData = useSelector((state) => state?.user?.data);

  useEffect(() => {
    openComment || createComment
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [openComment, createComment]);

  return (
    <section
      style={{
        visibility: `${openComment ? "visible" : "hidden"}`,
        opacity: `${openComment ? 1 : 0}`,
      }}
      className="CommentListMobile"
    >
      <button
        className="exit-entry-mobile"
        onClick={() => setOpenComment(false)}
      />
      <div className="comment-list-container">
        <p>Respostas ({comments?.contributions?.length})</p>
        <div className="comment-list">
          {comments?.contributions?.map((item, key) => (
            <ContentComment item={item} key={key} />
          ))}
        </div>
      </div>
      <button
        onClick={() => {
          userData && userData.username
            ? setCreateComment(true)
            : history.push("/login");
        }}
      >
        Qual a sua opinião sobre essa lente?
      </button>
      {
        <CommentTextMobile
          content={comments}
          createComment={createComment}
          setCreateComment={setCreateComment}
        />
      }
    </section>
  );
};

export default CommentListMobile;
