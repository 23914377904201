import React from 'react';
import "./ContentCardMobile.scss";
import {ReactComponent as TextIcon} from "../../../assets/images/card-text-black.svg";
import {ReactComponent as AudioIcon} from "../../../assets/images/card-audio-black.svg";
import {ReactComponent as VideoIcon} from "../../../assets/images/card-video-black.svg";
import Tag from "../../home/searchArea/Tag/Tag";
import logoLune from "../../../assets/images/card-lune.png";
import womanProfile from "../../../assets/images/woman-profile.svg";
import {slugify} from "../../../redux/api/slugify";
import {useHistory, useLocation} from "react-router-dom";

const icon = {
  TEXT: <TextIcon/>,
  AUDIO: <AudioIcon/>,
  VIDEO: <VideoIcon/>
}

const ContentCardMobile = ({item, setOpenModal, favoriteId}) => {
  const history = useHistory();
  const slug = slugify(item && item.title);
  const location = useLocation();
  const {pathname} = location;

  const redirectContent = () => {
    window.scrollTo(0, 0)
    history.push(`/conteudo/${item && item.id}/${slug}`)
    pathname !== `/conteudo/${item && item.id}/${slug}` && window.location.reload()
  }

  const handleTagClick = name => {
    window.scrollTo(0, 0)
    history.push('/pesquisa?tags=' + name)
  }

  return (
    <div className="ContentCardMobile" >
      <div className="left-card-mobile" onClick={() => redirectContent()}>
        <div className="user-card-mobile">
          <img src={item?.author?.photo === undefined || item?.author?.photo === null ? womanProfile : item?.author?.photo} alt="imagem de perfil" />
          <p>pela lente de <strong>{item?.author?.username}</strong></p>
        </div>
        <h3>{item?.title}</h3>
        <div className="infos-card-mobile">
          <div>
            <p>2h atrás &nbsp;•</p>
            {icon[item?.mediaType]}
            <p>{item?.mediaType}</p>
          </div>
        </div>
      </div>
      <div className="tag-card-mobile">
        {item?.tags && [...item?.tags].splice(0, 1).map((item, key) => {
          return <Tag onClick={() => handleTagClick(item?.id)}
                      item={item.name} key={key}/>
        })}
      </div>
      <div className="right-card-mobile">
        <img onClick={() => {redirectContent()}} style={{backgroundColor: `${item?.platform === "LUNE" && "black"}`}}
             src={item?.platform === "LUNE" ? logoLune : item?.previewImage} alt="imagem do conteúdo" />
        <div className="buttons-card-mobile">
          <button />
          <button onClick={() => {
            favoriteId && favoriteId(item?.id)
            setOpenModal && setOpenModal(true)
          }} />
        </div>
      </div>
    </div>
  );
};

export default ContentCardMobile;
