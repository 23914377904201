import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useTags from '../../../hooks/useTags';
import "./SlideTags.scss";

const SlideTags = ({ isLogged, marginTop }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const tags = useSelector(state => state?.tags?.data);
  const tags = useTags();
  const tagsList = [];

  // useEffect(() => {
  //   dispatch(getTagsRequest())
  // }, [dispatch])

  Array?.isArray(tags) && tags?.map((item) => {
    if (item?.referenceCount * item?.followerCount) {
      tagsList.push(item)
    }

    return item
  })

  const slideTagList = tagsList.reverse().splice(0, 30)

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
        <span>➜</span>
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
        <span>➜</span>
      </div>
    );
  }


  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    scroll: true,
    variableWidth: true,
    slidesToScroll: 3,
    swipeToSlide: true,
    slide: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleTagClick = nome => {
    history.push('/pesquisa?tags=' + nome)
  }

  return (
    <div className="SlideTags" style={{ marginTop: marginTop }}>
      <p><strong>Conceitos mais procurados</strong> </p>
      {isLogged ? <p className='mobile-text-slide'>MAIS PROCURADOS NO <strong>ALEXANDRIA</strong></p> : <p className='mobile-text-slide'>PESQUISE POR ASSUNTO</p>}
      <Slider {...settings}>
        {slideTagList?.map((item, key) => {
          return <div
            key={key}
            className={`carousel ${key % 3 === 0 ? "bgOne" : key % 3 === 1 ? "bgTwo" : "bgThird"}`}
            onClick={() => {
              window.scrollTo(0, 0)
              handleTagClick(item.id)
            }}><p>{item.name}</p></div>
        })}
      </Slider>
    </div>
  );
};
export default SlideTags;
