import React from 'react';
import "./HomeLanding.scss";
import ExtensionImage from "../../../assets/images/extension-01.png"
import {ReactComponent as WhiteArrow} from "../../../assets/images/white-arrow-down.svg";

const HomeLanding = () => {

  return (
    <section className="HomeLanding">
      <div className="container-landing">
        <img src={ExtensionImage} alt="Mulher com notebook na mão"/>
      </div>
      <div className="bottom-landing">
        <p>Aprenda a reindexar</p>
        <p>a internet com Alexandria</p>
      </div>
      <div className="arrowBox">
        <WhiteArrow/>
      </div>
    </section>
  );
};

export default HomeLanding;
