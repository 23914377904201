import {
  authorizedFetch,
  baseUrl,
  buildHeaders, getResponseOrThrow,
} from "./common";

export const apiUserForgotPasswordRequest = (token, email) => {
  return fetch(`${baseUrl}/user/user/password/recovery`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(email)
  }).then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)}
    )
};

export const apiUserNewPasswordRequest = (token, code, payload) => {
  return fetch(`${baseUrl}/user/user/password/recovery/${code}`, {
    method: "PUT",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)}
    )
};

export const apiInfoRequest = () => {
  return authorizedFetch(`${baseUrl}/user/user`, {
    method: "GET",
  }).then(getResponseOrThrow)
    .catch(json => {
      return Promise.reject(json)}
    )
};

export const apiUpdateUserInfoRequest = (payload) => {
  return authorizedFetch(`${baseUrl}/user/user`, {
    method: "PATCH",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
};

export const apiUserUpdatePasswordRequest = (userID, payload) => {
  return fetch(`${baseUrl}/user/user/${userID}/password`, {
    method: "PUT",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)}
    )
};

export const apiUploadPhoto = (payload, userId) => {
  let body = new FormData();
  body.append("file", payload.file);

  return authorizedFetch(`${baseUrl}/user/user/${userId}/photo`,
    {
      method: "POST",
      headers: buildHeaders("", true),
      body: body
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};
