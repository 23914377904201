import React, { useState, useEffect } from "react";
import "./CardSlide.scss";
import womanProfile from "../../../../assets/images/woman-profile.svg";
import Tag from "../../searchArea/Tag/Tag";
import logoLune from "../../../../assets/images/card-lune.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import iconSave from "../../../../assets/images/save-content-black.svg";
import iconSaveBackground from "../../../../assets/images/save-content-background.svg";
import iconCollection from "../../../../assets/images/playlist-icon-black.svg";
import { slugify } from "../../../../redux/api/slugify";
import { getItemFromStorage } from "../../../../helper/storage";
import { addFavorite } from "../../../../redux/actions/favorite";

const CardSlide = ({
  item,
  number,
  slideContain,
  setSeeLaterMessage,
  setClickedContentId,
  setIsOpenCreateCollection,
  setActiveLogin,
  setOpenSeeLaterPopUp,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const slug = slugify(item && item.title);
  const [isOpen, setIsOpen] = useState(false);
  const hasVerifyLogin = getItemFromStorage("ALEXANDRIA_AUTH_TOKEN");

  const addContentInSeeLater = (id) => {
    const payload = {
      platform: "ALEXANDRIA",
    };
    dispatch(addFavorite(id, payload))
      .then(() => {
        setOpenSeeLaterPopUp(true);
        setSeeLaterMessage(`Conteúdo adicionado ao "Salvos"`);
        item.isFavoriteApresentation = true;

        setTimeout(() => {
          setOpenSeeLaterPopUp(false);
        }, 3000);
      })
      .catch(() => {
        setOpenSeeLaterPopUp(true);
        setSeeLaterMessage("");
      });
  };

  return item?.isDraft === undefined ? (
    <div className="CardSlide-empty">
      <h2>Conteúdo excluído</h2>
    </div>
  ) : (
    <div
      className="CardSlide"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onClick={() => {
        window.innerWidth < 1024 &&
          window.scrollTo(0, 0) &&
          history.push(`/conteudo/${item && item.id}/${slug}`);
      }}
    >
      <div
        onClick={() => {
          window.scrollTo(0, 0);
          history.push(`/conteudo/${item && item.id}/${slug}`);
        }}
        className="top-card-slide"
      >
        {item?.isFavoriteApresentation && (
          <img className="save-icon" src={iconSaveBackground} />
        )}
        {slideContain && <p>{number + 1}</p>}
        <img
          className={`${isOpen ? "show" : ""}`}
          style={{ backgroundColor: `${item?.platform === "LUNE" && "black"}` }}
          src={item?.platform === "LUNE" ? logoLune : item?.previewImage}
          alt="imagem"
        />
      </div>
      <div className="info-card-slide">
        <div className="user-card-slide">
          <div>
            <img
              src={
                item?.author?.photo === undefined ||
                item?.author?.photo === null
                  ? womanProfile
                  : item?.author?.photo
              }
              alt="imagem de perfil"
            />
            <p>
              pela lente de &nbsp;<strong>{item?.author?.username}</strong>
            </p>
          </div>
          <div className="container-tag">
            <Tag item={item?.tags[0]?.name} />
          </div>
          <button />
        </div>
        <div className="title-card-slide">
          <p>{item?.title}</p>
          <strong
            dangerouslySetInnerHTML={{
              __html: item?.shortDescription || "Conteúdo sem descrição",
            }}
          />
        </div>
        <div className="title-card-desktop">
          <p>{item?.title}</p>
          <strong
            dangerouslySetInnerHTML={{
              __html: item?.shortDescription || "Conteúdo sem descrição",
            }}
          />
          <div className={`about-slide-card ${isOpen ? "show" : ""}`}>
            <div className="buttons-about">
              {!item?.isFavoriteApresentation && (
                <button
                  onClick={() =>
                    hasVerifyLogin
                      ? addContentInSeeLater(item?.id)
                      : setActiveLogin(true)
                  }
                >
                  <img src={iconSave} />
                  Salvar
                </button>
              )}
              <button
                onClick={() =>
                  setClickedContentId(item.id) ||
                  (hasVerifyLogin
                    ? setIsOpenCreateCollection(true)
                    : setActiveLogin(true))
                }
                className="button-blue"
              >
                <img src={iconCollection} />
                Adicionar a Coleção
              </button>
            </div>
          </div>
        </div>
        <div className="bottom-card-slide">
          <div className="tags-card-slide">
            {item &&
              [...item?.tags].splice(0, 2).map((item, key) => {
                return <Tag item={item?.name} key={key} />;
              })}
          </div>
          <div className="buttons-card-slide">
            <button />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSlide;
