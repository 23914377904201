import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { getItemFromStorage } from "../../../helper/storage";
import { useWindowSizeHook } from "../../../hooks/WindowHooks";
import Button from "../../global/Button/Button";
import Chat from '../../global/chat/Chat/Chat';
import Footer from '../../global/Footer/Footer';
import Navbar from "../../global/Navbar/Navbar";
import NavbarMobile from "../../global/NavbarMobile/NavbarMobile";
import UserMenu from "../../global/UserMenu/UserMenu";
import './GenericPage.scss';

const GenericPage = ({ children, setActiveTag }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { width } = useWindowSizeHook();

  const renderNavbarMobile = () => {
    if (getItemFromStorage("ALEXANDRIA_AUTH_TOKEN")) {
      return (
        <>
          <NavbarMobile />
          {pathname.substr(0, 9) === "/conteudo" ? "" : <UserMenu />}
        </>
      )
    } else {
      return (<nav className="nav-logged-out">
        <h3 onClick={() => history.push("/")}>Alexandria</h3>
        <Button children="Entrar" onClick={() => history.push("/login")} />
      </nav>)
    }
  }

  const renderFooterMobile = () => {
    if (width > 1024) {
      return <Footer />
    } else {
      if (!getItemFromStorage("ALEXANDRIA_AUTH_TOKEN")) {
        return <Footer />
      }
    }
  }

  return (
    <>
      <Navbar setActiveTag={setActiveTag} />
      {renderNavbarMobile()}
      {children}
      <Chat />
      {renderFooterMobile()}
    </>
  );
};

export default GenericPage;
