import React, { useEffect, useRef, useState } from "react";
import "./DeleteCollectionPopUp.scss";
import Button from "../../../../global/Button/Button";
import LoaderButton from "../../../../global/LoaderButton/LoaderButton";
import GreyPopUp from "../../../../global/GreyPopUp/GreyPopUp";
import { useDispatch } from "react-redux";
import {
  deleteJourney,
  infoJourney,
} from "../../../../../redux/actions/journey";
import { useParams } from "react-router-dom";
import { useOnClickOutside } from "../../../../../hooks/useOnClickOutside";

const DeleteCollectionPopUp = ({
  setPopUpDeleteJourneyList,
  setPopUpDeleteBlue,
  isOpenPopUpDeleteBlue,
}) => {
  const dispatch = useDispatch();
  const { playlistId } = useParams();
  const modalRef = useRef();
  useOnClickOutside(modalRef, () => setPopUpDeleteJourneyList(false));

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    dispatch(infoJourney(playlistId));
  }, [dispatch, playlistId]);

  const handleDelete = (e) => {
    e.preventDefault();
    setIsFetching(true);
    dispatch(deleteJourney(playlistId))
      .then(() => {
        setIsFetching(false);
        setPopUpDeleteJourneyList(false);
        setPopUpDeleteBlue(true);
      })
      .catch(() => {
        setOpenErrorModal(true);
        setIsFetching(false);
      });
  };

  return (
    <div className="DeleteCollectionPopUp">
      {openErrorModal && (
        <GreyPopUp
          onClick={() => {
            setOpenErrorModal(false);
            setPopUpDeleteJourneyList(false);
          }}
        />
      )}
      <div
        className="container-delete-collection-list"
        ref={isOpenPopUpDeleteBlue ? null : modalRef}
      >
        {console.log(isOpenPopUpDeleteBlue)}
        {console.log(modalRef.current)}
        <div className="header-delete-collection-list">
          <button
            className="exit-container-journey"
            onClick={() => setPopUpDeleteJourneyList(false)}
          />
        </div>
        <div className="body-delete-collection-list">
          <h2>Deseja excluir esta coleção?</h2>
          <p>Essa ação não pode ser desfeita.</p>
          {!isFetching ? (
            <Button
              children="Cancelar"
              primaryColor="#CFEC59"
              onClick={() => setPopUpDeleteJourneyList(false)}
            />
          ) : <LoaderButton />}
          {!isFetching && (
            <Button
              children="Excluir Coleção"
              primaryColor="#ffffff"
              onClick={(e) => handleDelete(e)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteCollectionPopUp;
