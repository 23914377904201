import {
  CREATE_JOURNEY_REQUEST,
  CREATE_JOURNEY_REQUEST_FAILED,
  CREATE_JOURNEY_REQUEST_SUCCEEDED,
  GET_JOURNEY_LIST_REQUEST,
  GET_JOURNEY_LIST_REQUEST_FAILED, 
  GET_JOURNEY_LIST_REQUEST_SUCCEEDED,
  ADD_CONTENT_IN_JOURNEY_REQUEST,
  ADD_CONTENT_IN_JOURNEY_REQUEST_FAILED,
  ADD_CONTENT_IN_JOURNEY_REQUEST_SUCCEEDED,
  GET_JOURNEY_REQUEST, 
  GET_JOURNEY_REQUEST_FAILED,
  GET_JOURNEY_REQUEST_SUCCEEDED,
  DELETE_JOURNEY_REQUEST,
  DELETE_JOURNEY_REQUEST_FAILED,
  DELETE_JOURNEY_REQUEST_SUCCEEDED,
  UPDATE_JOURNEY_REQUEST,
  UPDATE_JOURNEY_REQUEST_FAILED,
  UPDATE_JOURNEY_REQUEST_SUCCEEDED,
  SHARE_JOURNEY_REQUEST,
  SHARE_JOURNEY_REQUEST_FAILED,
  SHARE_JOURNEY_REQUEST_SUCCEEDED
} from "../constants/journey";

export const createJourneyRequest = () => {
  return {
    type: CREATE_JOURNEY_REQUEST
  }
};

export const createJourneyRequestSucceeded = (json) => {
  return {
    type: CREATE_JOURNEY_REQUEST_SUCCEEDED,
    data: json
  }
};

export const createJourneyRequestFailed = (error) => {
  return {
    type: CREATE_JOURNEY_REQUEST_FAILED,
    error: error
  }
};

export const getJourneyListRequest = () => {
  return {
    type: GET_JOURNEY_LIST_REQUEST
  }
};

export const getJourneyListRequestSucceeded = (json) => {
  return {
    type: GET_JOURNEY_LIST_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getJourneyListRequestFailed = (error) => {
  return {
    type: GET_JOURNEY_LIST_REQUEST_FAILED,
    error: error
  }
};

export const addContentInJourneyRequest = () => {
  return {
    type: ADD_CONTENT_IN_JOURNEY_REQUEST
  }
};

export const addContentInJourneyRequestSucceeded = (json) => {
  return {
    type: ADD_CONTENT_IN_JOURNEY_REQUEST_SUCCEEDED,
    data: json
  }
};

export const addContentInJourneyRequestFailed = (error) => {
  return {
    type: ADD_CONTENT_IN_JOURNEY_REQUEST_FAILED,
    error: error
  }
};

export const getJourneyRequest = () => {
  return {
    type: GET_JOURNEY_REQUEST
  }
};

export const getJourneyRequestSucceeded = (json) => {
  return {
    type: GET_JOURNEY_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getJourneyRequestFailed = (error) => {
  return {
    type: GET_JOURNEY_REQUEST_FAILED,
    error: error
  }
};

export const deleteJourneyRequest = () => {
  return {
    type: DELETE_JOURNEY_REQUEST
  }
};

export const deleteJourneyRequestSucceeded = (json) => {
  return {
    type: DELETE_JOURNEY_REQUEST_SUCCEEDED,
    data: json
  }
};

export const deleteJourneyRequestFailed = (error) => {
  return {
    type: DELETE_JOURNEY_REQUEST_FAILED,
    error: error
  }
};

export const UpdateJourneyRequest = () => {
  return {
    type: UPDATE_JOURNEY_REQUEST
  }
};

export const UpdateJourneyRequestSucceeded = (json) => {
  return {
    type: UPDATE_JOURNEY_REQUEST_SUCCEEDED,
    data: json
  }
};

export const UpdateJourneyRequestFailed = (error) => {
  return {
    type: UPDATE_JOURNEY_REQUEST_FAILED,
    error: error
  }
};

export const shareJourneyRequest = () => {
  return {
    type: SHARE_JOURNEY_REQUEST
  }
};

export const shareJourneyRequestSucceeded = (json) => {
  return {
    type: SHARE_JOURNEY_REQUEST_SUCCEEDED,
    data: json
  }
};

export const shareJourneyRequestFailed = (error) => {
  return {
    type: SHARE_JOURNEY_REQUEST_FAILED,
    error: error
  }
};
