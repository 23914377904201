import {SIGNUP_REQUEST, SIGNUP_REQUEST_FAILED, SIGNUP_REQUEST_SUCCEEDED} from "../constants/signup";

export const signup = (
  state = {
    data: {},
    isFetching: false,
    error: {}
  },
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case SIGNUP_REQUEST:
      newState.isFetching = true;
      return newState;

    case SIGNUP_REQUEST_SUCCEEDED:
      newState.data = {
        ...state.data,
      };
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case SIGNUP_REQUEST_FAILED:
      newState.error = action.error;
      newState.data = {};
      newState.isFetching = false;
      return newState;

    default:
      return state;
  }
};
