import {
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_REQUEST_FAILED,
  DELETE_COMMENT_REQUEST_SUCCEEDED,
  GET_COMMENT_REQUEST,
  GET_COMMENT_REQUEST_FAILED,
  GET_COMMENT_REQUEST_SUCCEEDED,
  POST_COMMENT_REQUEST,
  POST_COMMENT_REQUEST_FAILED,
  POST_COMMENT_REQUEST_SUCCEEDED
} from "../constants/comment";

export const comment = (
  state = {
    isFetching: false,
    data: {},
    error: {},
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case POST_COMMENT_REQUEST:
    case GET_COMMENT_REQUEST:
    case DELETE_COMMENT_REQUEST:
      newState.isFetching = true;
      return newState;

    case POST_COMMENT_REQUEST_SUCCEEDED:
    case GET_COMMENT_REQUEST_SUCCEEDED:
    case DELETE_COMMENT_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case POST_COMMENT_REQUEST_FAILED:
    case GET_COMMENT_REQUEST_FAILED:
    case DELETE_COMMENT_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
