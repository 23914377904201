import {
  contentsRequest,
  contentsRequestFailed,
  contentsRequestSucceeded, userContentsRequest, userContentsRequestFailed, userContentsRequestSucceeded
} from "../action-creators/contents";
import {apiContents, apiUserContents} from "../api/contents";
import {getItemFromStorage} from "../../helper/storage";
import {USER_ID} from "../constants/local";

const createContentsRequest = (filters) => dispatch => {
  dispatch(contentsRequest());

  return apiContents(filters)
    .then((json) => {
      dispatch(contentsRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(contentsRequestFailed(error));
      return Promise.reject(error);
    })
};

export const getContentsRequest = (filters) => dispatch => dispatch(createContentsRequest(filters));

const createUserContentsRequest = (filters) => dispatch => {
  dispatch(userContentsRequest());
  const userID = getItemFromStorage(USER_ID);
  return apiUserContents(filters, userID)
    .then(json => {
      dispatch(userContentsRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
        dispatch(userContentsRequestFailed(response));
        return Promise.reject(response);
      }
    );
};

export const getUserContents = (filters) => dispatch =>
  dispatch(createUserContentsRequest(filters));
