import {
  checkFollowTagRequest,
  checkFollowTagRequestFailed,
  checkFollowTagRequestSucceeded,
  followTagRequest,
  followTagRequestFailed,
  followTagRequestSucceeded, suggestionTagsRequest, suggestionTagsRequestFailed, suggestionTagsRequestSucceeded,
  tagsRequest,
  tagsRequestFailed,
  tagsRequestSucceeded,
  unfollowTagRequest,
  unfollowTagRequestFailed,
  unfollowTagRequestSucceeded,
  updateFollowTagRequest,
  updateFollowTagRequestFailed,
  updateFollowTagRequestSucceeded
} from "../action-creators/tags";
import {
  apiCheckFollowTag,
  apiFollowTag,
  apiTags,
  apiTagsSuggestion,
  apiUnfollowTag,
  apiUpdateFollowTag
} from "../api/tags";

const createTagsRequest = (filters) => dispatch => {
  dispatch(tagsRequest());

  return apiTags(filters)
    .then((json) => {
      dispatch(tagsRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(tagsRequestFailed(error));
      return Promise.reject(error);
    })
};

export const getTagsRequest = (filters) => dispatch => dispatch(createTagsRequest(filters));

const checkFollowTag = (TAG_ID) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(checkFollowTagRequest());
  return apiCheckFollowTag(userID, TAG_ID)
    .then((json) => {
      dispatch(checkFollowTagRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(checkFollowTagRequestFailed(error));
      return Promise.reject(error);
    })
};

export const getCheckFollowTag = (TAG_ID) => dispatch => dispatch(checkFollowTag(TAG_ID));

const followTag = (TAG_ID) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(followTagRequest());
  return apiFollowTag(userID, TAG_ID)
    .then((json) => {
      dispatch(followTagRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(followTagRequestFailed(error));
      return Promise.reject(error);
    })
};

export const addFollowTag = (TAG_ID) => dispatch => dispatch(followTag(TAG_ID));

const unfollowTag = (TAG_ID) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(unfollowTagRequest());
  return apiUnfollowTag(userID, TAG_ID)
    .then((json) => {
      dispatch(unfollowTagRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(unfollowTagRequestFailed(error));
      return Promise.reject(error);
    })
};

export const getUnfollowTag = (TAG_ID) => dispatch => dispatch(unfollowTag(TAG_ID));

const userFollowTag = (payload) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(updateFollowTagRequest());
  return apiUpdateFollowTag(userID, payload)
    .then((json) => {
      dispatch(updateFollowTagRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(updateFollowTagRequestFailed(error));
      return Promise.reject(error);
    })
};

export const updateFollowTag = (payload) => dispatch => dispatch(userFollowTag(payload));

export const suggestionTags = (payload) => dispatch => {
  dispatch(suggestionTagsRequest());
  return apiTagsSuggestion(payload)
    .then((json) => {
      dispatch(suggestionTagsRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(suggestionTagsRequestFailed(error));
      return Promise.reject(error);
    })
};
