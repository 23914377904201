import React, {useEffect} from 'react';
import "./CollectionShare.scss";
import ContentCard from "../../../global/ContentCard/ContentCard";
import {shareJourney} from "../../../../redux/actions/journey";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import CardFetching from "../../../global/CardFetching/CardFetching";

const CollectionShare = () => {
  const dispatch = useDispatch();
  const {playlistId} = useParams();
  const collection = useSelector(state => state.journey?.data)
  const collectionFetching = useSelector(state => state.journey?.isFetching)

  useEffect(() => {
    dispatch(shareJourney(playlistId))
  }, [dispatch, playlistId])
  
  return (
    <div>
      <Helmet title={`${collection?.name} - Alexandria`} />
      <div className="CollectionShare">
        <div className="edit-collection-share">
          <div>
            <h2>{collection?.name}</h2>
            <p>Playlist</p>
          </div>
        </div>
        <div className="collection-share-cards">
          {collectionFetching ? <CardFetching amount={4} /> :
            collection?.contents && [...collection?.contents].map((item, key) => {
              return <ContentCard item={item} key={key}/>
            })}
        </div>
      </div>
    </div>
  );
};

export default CollectionShare;
