import React, {useEffect} from 'react';
import "./HistoryMobile.scss";
import {getHistory} from "../../../redux/actions/history";
import {useDispatch, useSelector} from "react-redux";
import ContentCardMobile from "../../global/ContentCardMobile/ContentCardMobile";
import CardFetching from "../../global/CardFetching/CardFetching";

const HistoryMobile = () => {
  const dispatch = useDispatch();
  const historyData = useSelector(state => state.history?.data);
  const historyFetching = useSelector(state => state.history?.isFetching)

  useEffect(() => {
    dispatch(getHistory())
  }, [dispatch]);
  
  return (
    <section className="HistoryMobile">
      <div className="history-view-cards">
        {historyFetching && <CardFetching amount={4} />}
        {!historyFetching && historyData?.items && [...historyData?.items].map((item, key) => {
          return <ContentCardMobile key={key} item={item?.content}/>
        })}
      </div>
    </section>
  );
};

export default HistoryMobile;
