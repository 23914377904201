import React from 'react';
import "./TopAbout.scss";
import ExtensionImage from "../../../assets/images/extension-01.png"

const TopAbout = () => {
  return (
    <div className="TopAbout">
        <div className="topContainer">
            <div className="textBox">
                <h2>Aprendizado está em todo lugar.</h2>
                <h6>Alexandria é seu ponto de partida em sua jornada de conhecimento. A internet reindexada por quem busca conhecimento.</h6>
            </div>
            <div className="imageBox">
                <img 
                  src={ExtensionImage} 
                  alt="Ilustração de uma pessoa cartunizada estudando em um notebook" 
                />
            </div>
        </div>
    </div>
  );
};

export default TopAbout;
