import React from 'react';
import './ResultTag.scss';
import {ReactComponent as XWhite} from '../../../../../assets/images/x-one-white.svg'

const ResultTag = ({name, onClick}) => {
  return (
    <div className="ResultTag" onClick={onClick}>
      <XWhite />
      <p>{name}</p>
    </div>
  );
};

export default ResultTag;
