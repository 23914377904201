import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWindowSizeHook } from "../../../hooks/WindowHooks";
import { deleteContent } from "../../../redux/actions/content";
import { getUserContents } from "../../../redux/actions/contents";
import Button from "../../global/Button/Button";
import CardFetching from "../../global/CardFetching/CardFetching";
import ContentCard from "../../global/ContentCard/ContentCard";
import ContentCardMobile from "../../global/ContentCardMobile/ContentCardMobile";
import GenericCreationCard from "../../global/GenericCreationCard/GenericCreationCard";
import "./MyContents.scss";

const MyContents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowSizeHook();
  const contents = useSelector(state => state.contents);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadedItems, setLoadedItems] = useState([]);
  const observer = useRef(null);
  const endOfPageRef = useRef(null);

  useEffect(() => {
    if (pageNo === 1) {
      setLoadedItems([]);
    }

    if (!loading) {
      setLoading(true);
      dispatch(getUserContents({ ...buildFilters(), page: pageNo })).then((newData) => {
        setLoadedItems((prevItems) => [...prevItems, ...newData.items]);
        setLoading(false);
      });
    }
  }, [pageNo]);

  useEffect(() => {
    if (contents.isFetching || pageNo >= contents?.myContents?.totalPages) {
      return;
    }

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading) {
        setPageNo((prevPage) => prevPage + 1);
      }
    });

    if (endOfPageRef.current) {
      observer.current.observe(endOfPageRef.current);
    }
  }, [loading, contents.isFetching]);

  const buildFilters = () => {
    let filters = {};
    return filters;
  }

  const handleDeleteContent = (e, item) => {
    e.stopPropagation();
    dispatch(deleteContent(item?.id)).then(() => {
      dispatch(getUserContents());
    });
  }

  return (
    <section className="MyContents">
      {width > 1024
        ? <GenericCreationCard
          label="Criar conteúdo"
          action={() => history.push("/criar-conteudo")} /> :
        <Button children="Criar conteúdo" primaryColor="#CFEC59" onClick={() => history.push("/criar-conteudo")} />
      }
      <div className="content-list-fetching">
        {
          loadedItems?.length > 0 ?
            loadedItems?.map((item, key) => {
              return width > 1024
                ? <ContentCard item={item} key={key} onDelete={handleDeleteContent} />
                : <ContentCardMobile key={key} item={item} />
            })
            : !contents.isFetching && <p className="no-content">Você ainda não possui conteúdos publicados</p>
        }
        {contents.isFetching
          && (
            <CardFetching amount={4} />
          )
        }
      </div>

      {pageNo !== contents?.myContents?.totalPages && !contents.isFetching && contents?.items?.length !== 0 && (
        <div className="expand-mobile">
          <button onClick={() => setPageNo(pageNo + 1)}>Carregar mais</button>
        </div>
      )}

      {pageNo !== contents?.myContents?.totalPages && !contents.isFetching && contents?.items?.length !== 0 && (
        <div className="expand-contents">
          <div className='end-of-page' ref={endOfPageRef} />
        </div>
      )}
    </section>
  );
};

export default MyContents;
