import React from 'react';
import "./Extension.scss";
import TopComponentView from "../TopComponentView/TopComponentView";
import BottomComponent from "../BottomComponent/BottomComponent";

const Extension = () => {
  return (
    <div className="Extension">
        <TopComponentView />
        <BottomComponent />
    </div>
  );
};

export default Extension;