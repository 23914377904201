import React from 'react';
import './NotesFetching.scss';

const NotesFetching = () => {
  return (
    <div className="NotesFetching">
      <div className="fetching-title-container">
        <div className="blue-marker">
          <span />
        </div>
        <div className="fetching-suggestion-head" />
        <div className="fetching-text-fetching">
          <div />
        </div>
      </div>
    </div>
  );
};

export default NotesFetching;
