import React from 'react';
import "./PresentationHome.scss";
import Polygon from "../../../assets/images/polygon-home.svg";

function PresentationHome() {
  return (
    <div className='PresentationHome'>
      <div className='container-presentation'>
        <div className='text-presentation'>
          <h1>Seu buscador de aprendizados.</h1>
          <p>Alexandria é a biblioteca centrada em quem aprende.
             Somos feitos de conteúdos marcados como relevantes por pessoas como você,
              que estão buscando conhecimento.</p>
              <p><strong>Sem anúncios, sem lero-lero.</strong></p>
        </div>
      </div>
      <div className='matrix'>
        <img src={Polygon} />
      </div>
    </div>
  );
}

export default PresentationHome;
