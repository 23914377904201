import React, { useEffect, useState } from "react";
import "./EditorText.scss";
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import {stateFromHTML} from 'draft-js-import-html';

const EditorText = ({ setContent, textComplete }) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText( "")));
  const [convertedContent, setConvertedContent] = useState(textComplete);
  const [haveBeenFocused, setHaveBeenFocused] = useState(false);
  let contentState = stateFromHTML(textComplete);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }
  
  useEffect(() => {
    textComplete && setEditorState(EditorState.createWithContent(contentState))
    let currentContentAsHTML = convertToHTML(contentState);
    setConvertedContent(currentContentAsHTML);
    setContent(prevState => ({ ...prevState, text: convertedContent}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const convertContentToHTML = () => {
    let currentContentAsHTML = haveBeenFocused ? convertToHTML(editorState.getCurrentContent()) : convertToHTML(contentState);
    setConvertedContent(currentContentAsHTML);
  }

  useEffect(() => {
    haveBeenFocused && setContent(prevState => ({ ...prevState, text: convertedContent }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedContent]);

  return (
    <div style={{border: "3px solid black"}}>
      <Editor
        placeholder="Dê seu ponto de vista sobre este conteúdo. De que forma ele trouxe aprendizado?"
        editorState={editorState}
        onFocus={() => setHaveBeenFocused(true)}
        onEditorStateChange={handleEditorChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    </div>
  )
}

export default EditorText;
