import {
  FACEBOOK_DATA_REQUEST,
  FACEBOOK_DATA_REQUEST_FAILED,
  FACEBOOK_DATA_REQUEST_SUCCEEDED,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILED,
  LOGIN_REQUEST_SUCCEEDED,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_GOOGLE_FAILED,
  LOGIN_WITH_GOOGLE_SUCCEEDED
} from "../constants/login";

export const login = (
  state = {
    data: {},
    token: "",
    isFetching: false,
    error: {}
  },
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case LOGIN_WITH_GOOGLE:
    case LOGIN_REQUEST:
    case FACEBOOK_DATA_REQUEST:
      newState.isFetching = true;
      return newState;

    case LOGIN_WITH_GOOGLE_SUCCEEDED:
    case LOGIN_REQUEST_SUCCEEDED:
    case FACEBOOK_DATA_REQUEST_SUCCEEDED:
      newState = {
        ...state.data,
        data: {...action.data},
        token: action.data.token,
        refreshToken: action.data.refresh
      };
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case LOGIN_WITH_GOOGLE_FAILED:
    case LOGIN_REQUEST_FAILED:
    case FACEBOOK_DATA_REQUEST_FAILED:
      newState.error = action.error;
      newState.isFetching = false;
      newState.data = {};
      return newState;

    default:
      return state;
  }
};
