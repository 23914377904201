import { AUTH_TOKEN, EXPIRE_LOGIN, REFRESH_TOKEN, USER_ID } from "../redux/constants/local";
import { getItemFromStorage, saveItemInStorage } from "./storage";

export const hasToken = () => {
  return !!getToken()
}
export const getToken = () => {
  return getItemFromStorage("ALEXANDRIA_AUTH_TOKEN")
}
export const tokenStillValid = () => {
  if (hasToken()) {
    const expireTime = getItemFromStorage("EXPIRE_LOGIN");
    return expireTime > new Date().getTime();
  }
}
export const getRefreshToken = () => {
  return getItemFromStorage("ALEXANDRIA_REFRESH_TOKEN")
}
export const updateToken = (data) => {
  localStorage.setItem("ALEXANDRIA_REFRESH_TOKEN", data['refresh_token']);
  localStorage.setItem("ALEXANDRIA_AUTH_TOKEN", data.token);
  localStorage.setItem("EXPIRE_LOGIN", new Date().getTime() + (data?.accessTokenRenewInterval * 1000))
}
export const saveLoginStorage = (json) => {
  saveItemInStorage(AUTH_TOKEN, json.token, true);
  saveItemInStorage(REFRESH_TOKEN, json.refresh_token, true);
  saveItemInStorage(USER_ID, json?.user?.id, true);
  saveItemInStorage(EXPIRE_LOGIN, json?.accessTokenRenewInterval, true);
}
export const updateUserStorage = (json) => {
  localStorage.setItem("user", JSON.stringify(json.user))
}
export const getUserStorage = () => {
  return getItemFromStorage("user")
}

export const logoutUser = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();
}