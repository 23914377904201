import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import "./NewPassword.scss";
import Input from "../../../global/form/Input/Input";
import Button from "../../../global/Button/Button";
import LoaderButton from "../../../global/LoaderButton/LoaderButton";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import { newPasswordToUser } from "../../../../redux/actions/user";
import { usePasswordField } from "../../../../hooks/formHooks";

const NewPassword = () => {
  const history = useHistory();
  const [newPassword, setNewPassword] = usePasswordField("");
  const [passwordConfirmation, setPasswordConfirmation, validPasswordConfirmation] = usePasswordField("");
  const [errorPasswordPattern, setErrorPasswordPattern] = useState(false);
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const email = location?.state?.email;
  const token = location?.state?.token;
  const { code } = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user?.data);

  const handleNewPassword = (e) => {
    setErrorPasswordConfirmation(false);
    e.preventDefault();
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    const isMatchedRegex = passwordRegex.test(newPassword);
    if (newPassword !== passwordConfirmation) {
      setErrorPasswordConfirmation(true);
    } else if(!isMatchedRegex) {
      setErrorPasswordPattern(true);
      return;
    } else {
      setErrorPasswordConfirmation(false);
      setIsLoading(true);
      const payload = {
        password: newPassword,
      };
      dispatch(newPasswordToUser(userData.token, code, payload))
        .then(() => {
          setIsLoading(false);
          if (window.innerWidth > 1024) {
            setShowModal(true);
          } else {
            history.push("/login")
          } 
        })
        .catch(() => {
          setIsLoading(false);
          setErrorMessage(true);
          setTimeout(() => {
            setErrorMessage(false);
          }, 5000);
        });
    }
  };
  
  return (
    <div className="NewPassword">
      <Helmet title="Recuperação de Senha - Alexandria" />
      <h2>Redefinir senha</h2>
      <h3>Crie uma nova senha</h3>
      <p className={`error ${errorPasswordConfirmation ? "visible" : null}`}>As duas senhas não coincidem</p>
      <p className={`error ${errorPasswordPattern ? "visible" : null}`}>
        Sua senha deve conter 6 caracteres dentre eles letras e números
      </p>
      <p className={`error ${errorMessage ? "visible" : null}`}>
        Código de Recuperação inválido
      </p>
      <Input
        updateState={setNewPassword}
        type="password"
        displayErrorMessage={false}
        errorMessage="Senha inválida"
        placeholder="Digíte uma nova senha"
        value={newPassword}
      />
      <Input
        updateState={setPasswordConfirmation}
        type="password"
        valid={validPasswordConfirmation}
        displayError={errorPasswordConfirmation}
        errorMessage="Senha inválida"
        placeholder="Confirme a nova senha"
        value={passwordConfirmation}
      />
      {isLoading ? (
        <LoaderButton />
      ) : (
        <Button
          primaryColor="#CFEC59"
          children="Enviar"
          onClick={(e) => handleNewPassword(e)}
        />
      )}
      <div className="comeback-login">
        <Button
          primaryColor="#FFFFFF"
          children="Voltar"
          onClick={() =>
            history.push({
              pathname: `/redefinir/codigo/`,
              state: { email, token },
            })
          }
        />
      </div>
      {showModal && (
        <BluePopUp
          text={"Senha atualizada com sucesso"}
          onClick={() => history.push("/login")}
        />
      )}
    </div>
  );
};

export default NewPassword;
