import React from 'react';
import "./HistoryDay.scss";
import ContentCard from "../../../global/ContentCard/ContentCard";

const HistoryDay = ({items, title}) => {
  return (
    <div className="HistoryDay">
      <p>{title}</p>
      <div className="history-day-container">
        {items && items.map((item, key) => {
          return <ContentCard item={item?.content || item} key={key}/>
        })}
      </div>
    </div>
  );
};

export default HistoryDay;
