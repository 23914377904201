import React from 'react';
import "./AdvancedSearchArea.scss";
import DatePickerSearch from "../DatePickerSearch/DatePickerSearch";
import CheckboxSearch from "../../global/CheckboxSearch/CheckboxSearch";

const AdvancedSearchArea = ({
                                contents,
                                mediaType,
                                setMediaType,
                                origin,
                                setOrigin,
                                author,
                                setAuthor,
                                platform,
                                setPlatform,
                                fromState,
                                setFromState,
                                toState,
                                setToState,
                                communicationVehicle,
                                setCommunicationVehicle,
                                contentSearch,
                                setPageNo
                            }) => {
    const vehicle = contentSearch?.popularCommunicationVehicle?.filter(vehicle => vehicle?.length > 1)
    const isChecked = (filter, checkboxValue) => {
        if(Array.isArray(filter)) {
            return filter.indexOf(checkboxValue) > -1;
        }else{
            return filter === checkboxValue;
        }
    }

    const toggle = (filter, updateFilter, checkboxValue, checkboxState) => {
        let newFilter = filter;
        setPageNo(1)
        if(Array.isArray(filter)) {
            newFilter = [...filter];
            if(checkboxState) {
                newFilter.push(checkboxValue);
            }else{
                newFilter.splice(filter.indexOf(checkboxValue), 1)
                if (newFilter.length === 0) {
                    newFilter = undefined;
                }
            }
        }else{
            if (checkboxState) {
                if(!filter) {
                    newFilter = checkboxValue;
                }else{
                    newFilter = [checkboxValue, filter]
                }
            }else{
                newFilter = undefined;
            }
        }
        updateFilter(newFilter);
    }

  return (
    <div className="AdvancedSearchArea">
      <h2>Busca por data</h2>
      <DatePickerSearch
          fromState={fromState}
          setFromState={setFromState}
          toState={toState}
          setToState={setToState}
      />
      <div className="checkbox-div">
        <h2>Tipo do mídia</h2>
        <CheckboxSearch
            checked={isChecked(mediaType, 'AUDIO')}
            updateState={(newState) => {
                toggle(mediaType, setMediaType, 'AUDIO', newState)
            }}
            label="Áudio"/>
        <CheckboxSearch
            checked={isChecked(mediaType, 'VIDEO')}
            updateState={(newState) => {
                toggle(mediaType, setMediaType, 'VIDEO', newState)
            }}
            label="Vídeo"/>
        <CheckboxSearch
            checked={isChecked(mediaType, 'TEXT')}
            updateState={(newState) => {
                toggle(mediaType, setMediaType, 'TEXT', newState)
            }}
            label="Texto"/>
      </div>
      <div className="checkbox-div">
        <h2>Origem</h2>
          <CheckboxSearch
              checked={isChecked(origin, 'NATIONAL')}
              updateState={(newState) => {
                  toggle(origin, setOrigin, 'NATIONAL', newState)
              }}
              label="Nacional"/>
          <CheckboxSearch
              checked={isChecked(origin, 'INTERNATIONAL')}
              updateState={(newState) => {
                  toggle(origin, setOrigin, 'INTERNATIONAL', newState)
              }}
              label="Internacional"/>
      </div>
      <div className="checkbox-div">
          <h2>Plataforma</h2>
          <CheckboxSearch
              checked={isChecked(platform, 'ALEXANDRIA')}
              updateState={(newState) => {
                  toggle(platform, setPlatform, 'ALEXANDRIA', newState)
              }}
              label="Alexandria"/>
          <CheckboxSearch
              checked={isChecked(platform, 'LUNE')}
              updateState={(newState) => {
                  toggle(platform, setPlatform, 'LUNE', newState)
              }}
              label="Lune"/>
          <CheckboxSearch
              checked={isChecked(platform, 'BAIAO_BINARIO')}
              updateState={(newState) => {
                  toggle(platform, setPlatform, 'BAIAO_BINARIO', newState)
              }}
              label="Baião Binário"/>
          <CheckboxSearch
              checked={isChecked(platform, 'BLOG')}
              updateState={(newState) => {
                  toggle(platform, setPlatform, 'BLOG', newState)
              }}
              label="Blog"/>
          <CheckboxSearch
              checked={isChecked(platform, 'CULTURA')}
              updateState={(newState) => {
                  toggle(platform, setPlatform, 'CULTURA', newState)
              }}
              label="Cultura"/>
          <CheckboxSearch
              checked={isChecked(platform, 'JUKEBOX')}
              updateState={(newState) => {
                  toggle(platform, setPlatform, 'JUKEBOX', newState)
              }}
              label="Jukebox"/>
      </div>
        <div className="checkbox-div">
            <h2>Autores</h2>
            <CheckboxSearch
                checked={isChecked(origin, 'Mastertech')}
                updateState={(newState) => {
                    toggle(origin, setOrigin, 'Mastertech', newState)
                }}
                label="Originais Mastertech"/>
            {
                contents && contents.popularAuthors && contents.popularAuthors.length > 0 && contents.popularAuthors.map(popularAuthor => {
                    return <CheckboxSearch
                        checked={isChecked(author, popularAuthor.username)}
                        updateState={(newState) => {
                            toggle(author, setAuthor, popularAuthor.username, newState)
                        }}
                        label={"@" + popularAuthor.username}/>
                })
            }
        </div>
        <div className="checkbox-div">
          <h2>Veículos de comunicação</h2>
          {
            vehicle?.map(communication => {
              return  <CheckboxSearch
                checked={isChecked(communicationVehicle, communication)}
                updateState={(newState) => {
                  toggle(communicationVehicle, setCommunicationVehicle, communication, newState)
                }}
                label={communication}/>
            })
          }
        </div>
     </div>
  );
};

export default AdvancedSearchArea;
