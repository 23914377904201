import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeSpecialCharacters } from "../../../helper/removeSpecialCharacters";
import useTags from "../../../hooks/useTags";
import { suggestionTags } from "../../../redux/actions/tags";
import Button from "../../global/Button/Button";
import SearchTagComment from "../../global/CommentText/SearchTagComment/SearchTagComment";
import DeleteTag from "../../global/DeleteTag/DeleteTag";
import Input from "../../global/form/Input/Input";
import "./SuggestionTags.scss";

const SuggestionTags = ({
  listTagsID,
  setListTagsID,
  listTags,
  setListTags,
  setVisitedSuggestion,
  text,
}) => {
  const dispatch = useDispatch();
  const [newTags, setNewTags] = useState([]);
  const [selectSuggestion, setSelectSuggestion] = useState([]);
  const [valueInput, setValueInput] = useState("");
  const tags = useTags();
  const [nameTag, setNameTag] = useState("");
  const [activeTags, setActiveTag] = useState(false);

  useEffect(() => {
    const payload = {
      text,
    };
    activeTags &&
      dispatch(suggestionTags(payload))
        .then((json) => {
          json?.foundTags?.map((item) => {
            listTags.push(item?.tag.name);
            listTagsID.push(item?.tag?.id.toUpperCase());

            let uniqueListTags = listTags.filter(function (elem, i, listTags) {
              return listTags.indexOf(elem) === i;
            });

            setListTags(uniqueListTags);

            let uniqueListTagsID = listTags.filter(function (
              elem,
              i,
              listTags
            ) {
              return listTags.indexOf(elem) === i;
            });

            setListTagsID(uniqueListTagsID);
          });

          setNewTags(json?.tagsSuggestion);
          setVisitedSuggestion(true);
        })
        .catch(() => { });
  }, [activeTags]);

  const handleClick = (name) => {
    setValueInput(name);
  };

  const handleCreateTag = () => {
    if (valueInput.includes(",")) {
      const noSpecialCharacters = removeSpecialCharacters(valueInput);
      if (noSpecialCharacters.length < 2) {
        setValueInput(removeSpecialCharacters(valueInput));
      } else if (noSpecialCharacters.length > 40) {
        setValueInput(removeSpecialCharacters(valueInput));
      } else {
        setValueInput("");
        listTags.push(noSpecialCharacters);
      }
    }
  };

  const resultInput = !valueInput
    ? tags
    : Array.isArray(tags) &&
    tags
      ?.filter((item) =>
        item.name.toLowerCase().includes(valueInput.toLocaleLowerCase())
      )
      ?.filter((item) => item.name !== valueInput);

  const cards = Array.isArray(resultInput) && resultInput?.slice(0, 4);

  const removeTag = (name) => {
    setNameTag(name);
    listTags.splice(listTags.indexOf(name), 1);
  };

  const removeTagID = (name) => {
    listTagsID.splice(listTagsID.indexOf(name), 1);
  };

  return (
    <div className={`SuggestionTags`}>
      {activeTags || listTags?.length >= 1 ? (
        <div className="suggestion-container">
          <div className="title-tags">
            <h5>Tags</h5> <p>identificamos estas tags em seu conteúdo</p>
          </div>
          <div className="container-delete-tags">
            {listTags.map((item, key) => {
              return (
                <DeleteTag
                  item={item}
                  key={key}
                  onClick={() => {
                    removeTag(item);
                    removeTagID(item);
                  }}
                />
              );
            })}
          </div>
          <Input
            className={""}
            value={valueInput}
            updateState={setValueInput}
            type="text"
            placeholder="Adicionar tags"
            o
            nChange={handleCreateTag()}
          />
          {valueInput && cards.length > 0 ? (
            <div
              style={{ display: !valueInput ? "none" : "flex" }}
              className="container-search-tags"
            >
              {cards.map((item, key) => (
                <SearchTagComment
                  item={item}
                  key={key}
                  onClick={() => {
                    listTags.push(item.name);
                    listTagsID.push(item.id);
                    handleClick(item.name);
                    setValueInput("");
                  }}
                />
              ))}
            </div>
          ) : (
            <p className="insert-tag">Insira uma vírgula depois de cada tag</p>
          )}
        </div>
      ) : (
        <div className="suggestion-container">
          <div className="title-tags">
            <h5>Tags</h5>
          </div>
          <Button
            onClick={() => text?.length >= 50 && setActiveTag(true)}
            style={{
              opacity: text?.length >= 50 ? "1" : "0.4",
              backgroundColor: "#CEEC58",
              cursor: text?.length >= 50 ? "pointer" : "not-allowed",
            }}
            children="Clique para detectar tags em seu conteúdo"
          />
        </div>
      )}
    </div>
  );
};

export default SuggestionTags;
