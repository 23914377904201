import React from 'react';
import './ZoomButton.scss';
import Logo from "../../../../assets/images/search-zoom.svg";

const ZoomButton = ({text, onClick}) => {
  return (
    <button className="ZoomButton" onClick={onClick}>
      {text} <img src={Logo} alt="Ícone de lupa" />
    </button>
  );
};

export default ZoomButton;
