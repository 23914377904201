import {
  GET_COMMENT_REQUEST,
  GET_COMMENT_REQUEST_FAILED,
  GET_COMMENT_REQUEST_SUCCEEDED,
  POST_COMMENT_REQUEST,
  POST_COMMENT_REQUEST_FAILED,
  POST_COMMENT_REQUEST_SUCCEEDED,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_REQUEST_FAILED,
  DELETE_COMMENT_REQUEST_SUCCEEDED
} from "../constants/comment";

export const postCommentRequest = () => {
  return {
    type: POST_COMMENT_REQUEST
  }
};

export const postCommentRequestSucceeded = (json) => {
  return {
    type: POST_COMMENT_REQUEST_SUCCEEDED,
    data: json
  }
};

export const postCommentRequestFailed = (error) => {
  return {
    type: POST_COMMENT_REQUEST_FAILED,
    error: error
  }
};

export const getCommentRequest = () => {
  return {
    type: GET_COMMENT_REQUEST
  }
};

export const getCommentRequestSucceeded = (json) => {
  return {
    type: GET_COMMENT_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getCommentRequestFailed = (error) => {
  return {
    type: GET_COMMENT_REQUEST_FAILED,
    error: error
  }
};

export const deleteCommentRequest = () => {
  return {
    type: DELETE_COMMENT_REQUEST
  }
};

export const deleteCommentRequestSucceeded = (json) => {
  return {
    type: DELETE_COMMENT_REQUEST_SUCCEEDED,
    data: json
  }
};

export const deleteCommentRequestFailed = (error) => {
  return {
    type: DELETE_COMMENT_REQUEST_FAILED,
    error: error
  }
};
