import React from 'react';
import "./NotesDescription.scss";
import {useDispatch} from "react-redux";
import {commentDelete, CommentList} from "../../../../redux/actions/comment";

const NotesDescription = ({info, item, setDeleteNotePopUp, setDeleteNoteMessage}) => {
  const dispatch = useDispatch();

  const handleDeleteFavorite= (id) => {
    dispatch(commentDelete(id))
      .then(() => {
        setDeleteNotePopUp(true)
        setDeleteNoteMessage("Nota excluída com sucesso!")
        dispatch(CommentList())
      })
      .catch(() => {
        setDeleteNotePopUp(true)
        setDeleteNoteMessage("")
        dispatch(CommentList())
      });
  }

  return (
    <div className="NotesDescription">
      <div className="notes-container-comment">
        <div className="notes-text-comment">
          <div className="notes-select-comment">
            <span className="notes-border-select" />
            <p>{info?.text}</p>
          </div>
          <div className="notes-description-comment">
            <p>{info?.contentReference?.text}</p>
          </div>
        </div>
        <div className="notes-delete-comment" onClick={() => handleDeleteFavorite(item[0].id)} />
      </div>
    </div>
  );
};

export default NotesDescription;
