import {baseUrl, buildHeaders, getResponseOrThrow, urlFilters} from "./common";

export  const apiRecommendation = (filters) => {
  return fetch(urlFilters(`${baseUrl}/recommendation/contents/recommendation`, filters),
    {
      method: "GET",
      headers: buildHeaders()
    }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((error) => Promise.reject(error));
};
