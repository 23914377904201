import React, {useState} from "react";
import './Entry.scss';
import Login from "../Login/Login";
import {Route, useHistory, useLocation} from 'react-router-dom';
import SignUp from "../SignUp/SignUp";
import RedefinePassword from "../PasswordReset/RedefinePassword/RedefinePassword";
import RecoveryInputCode from "../PasswordReset/RecoveryInputCode/RecoveryInputCode";
import NewPassword from "../PasswordReset/NewPassword/NewPassword";

const Entry = ({openDesktop, setOpenDesktop}) => {
  const history = useHistory();
  const location = useLocation()
  const { pathname } = location;
  const [activeMobileButton, setActiveMobileButton] = useState((pathname.split("/").pop()));
  const [successMessage, setSuccessMessage] = useState(false);

  const renderMobile = () => {
    if (successMessage || pathname === "/login" || pathname === "/registrar") {
      return (
        <div className="select-mobile">
          <h3 className="title-mobile">Alexandria</h3>
          <div>
            <button className={`${activeMobileButton === "login" ? "login" : ""}`}
                    onClick={() => {
                      history.push('/login')
                      setActiveMobileButton("login")
                    }}>Iniciar sessão
            </button>
            <button className={`${activeMobileButton === "registrar" ? "registrar" : ""}`}
                    onClick={() => {
                      history.push('/registrar')
                      setActiveMobileButton("registrar")
                    }}>Registrar-se
            </button>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="Entry" style={{position: `${openDesktop ? "fixed" : ""}`}}>
      {!openDesktop && <div className="bg-entry"/>}
      <div className="entry-container">
        {!successMessage && <button className="exit-entry-mobile" onClick={() => pathname === "/" ? history.push("/") : history.go(-1)}/>}
        {!openDesktop && <button className="exit-entry" onClick={() => history.push("/")}/>}
        {renderMobile()}
        <Route path="/login" exact component={() => <Login successMessage={successMessage} setSuccessMessage={setSuccessMessage} />}/>
        <Route path="/registrar" exact component={() => <SignUp/>}/>
        <Route path="/redefinir" exact component={() => <RedefinePassword/>}/>
        <Route path="/redefinir/codigo" exact component={() => <RecoveryInputCode/>}/>
        <Route path="/redefinir/codigo/:code" exact component={() => <NewPassword/>}/>
        {openDesktop && <Login openDesktop={openDesktop} setOpenDesktop={setOpenDesktop} 
                            successMessage={successMessage} setSuccessMessage={setSuccessMessage} />}
      </div>
    </div>
  );
};

export default Entry;
