export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCEEDED = "LOGIN_REQUEST_SUCCEEDED";
export const LOGIN_REQUEST_FAILED = "LOGIN_REQUEST_FAILED";

export const GOOGLE_LOGIN_SUCCEEDED = "GOOGLE_LOGIN_SUCCEEDED";
export const GOOGLE_LOGIN_FAILED = "GOOGLE_LOGIN_FAILED";
export const LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";
export const LOGIN_WITH_GOOGLE_SUCCEEDED = "LOGIN_WITH_GOOGLE_SUCCEEDED";
export const LOGIN_WITH_GOOGLE_FAILED = "LOGIN_WITH_GOOGLE_FAILED";

export const FACEBOOK_OAUTH_SUCCEEDED = "FACEBOOK_OAUTH_SUCCEEDED";
export const FACEBOOK_DATA_REQUEST = "FACEBOOK_DATA_REQUEST";
export const FACEBOOK_DATA_REQUEST_SUCCEEDED = "FACEBOOK_DATA_REQUEST_SUCCEEDED";
export const FACEBOOK_DATA_REQUEST_FAILED = "FACEBOOK_DATA_REQUEST_FAILED";

