import React from "react";
import "./OptionsDiversityAspects.scss";
import Checkbox from "../../../global/Checkbox/Checkbox";
import Input from "../../../global/form/Input/Input";
import Button from "../../../global/Button/Button";

const OptionsDiversityAspects = ({
  listDiversity,
  fixedDiversityItems,
  diversity,
  textDiversity,
  setFixedDiversityItems,
  setListDiversity,
  setTextDiversity,
}) => {
  const removeTag = (name) => {
    const copyList = [...listDiversity];
    const index = listDiversity.indexOf(name);
    copyList.splice(index, 1);
    setListDiversity(copyList);
  };

  const toggleDiversity = (name) => {
    const index = fixedDiversityItems.indexOf(name);
    const copyList = [...fixedDiversityItems];

    if (index > -1) {
      const index = fixedDiversityItems.indexOf(name);
      copyList.splice(index, 1);
      setFixedDiversityItems(copyList);
    } else {
      setFixedDiversityItems([...fixedDiversityItems, name]);
    }
  };

  const isChecked = (name) => {
    return fixedDiversityItems.indexOf(name) !== -1;
  };

  return (
    <div
      className="OptionsDiversityAspects"
      style={{ display: !diversity && "none" }}
    >
      <div className="diversityContainer">
        <p>Quais?</p>
        <div className="diversityCheckbox">
          <Checkbox
            label="Gênero"
            value="Gênero"
            checked={isChecked("Gênero")}
            updateState={(name) => toggleDiversity(name)}
          />
          <Checkbox
            label="Racial"
            value="Racial"
            checked={isChecked("Racial")}
            updateState={(name) => toggleDiversity(name)}
          />
          <Checkbox
            label="Geográfico"
            value="Geográfico"
            checked={isChecked("Geográfico")}
            updateState={(name) => toggleDiversity(name)}
          />
        </div>
        <div className="list-diverysity">
          {listDiversity &&
            listDiversity.map((item, key) => {
              return (
                <Checkbox
                  value={item}
                  label={item}
                  key={key}
                  checked={true}
                  getValue
                  updateState={(item) => {
                    removeTag(item);
                  }}
                />
              );
            })}
        </div>
      </div>
      <div className="diversityContainer">
        <p>Outros</p>
        <div className="diversityOthersCheckbox">
          <Input
            value={textDiversity}
            updateState={setTextDiversity}
            placeholder="Digite aqui"
          />
          <Button
            children="+"
            onClick={() => {
              setListDiversity([...listDiversity, textDiversity]);
              setTextDiversity("");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OptionsDiversityAspects;
