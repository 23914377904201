import React, { useRef, useState } from "react";
import "./CreateCollectionPopUp.scss";
import Input from "../../../global/form/Input/Input";
import Button from "../../../global/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { infoJourneyList, newJourney } from "../../../../redux/actions/journey";
import LoaderButton from "../../../global/LoaderButton/LoaderButton";

const CreateCollectionPopUp = ({
  setIsCreateCollectionPopUpOpen,
  openPopUpCreateCollection,
  setOpenPopUpCreateCollection,
  setPopUpCreateCollectionMessage,
  setInvisibleEmpty,
  contentList,
}) => {
  const dispatch = useDispatch();
  const modalRef = useRef();
  useOnClickOutside(modalRef, () => setIsCreateCollectionPopUpOpen(false));
  const [journeyName, setJourneyName] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const journeyIsFetching = useSelector((state) => state?.journey?.isFetching);

  const handleJourneyCreation = (e) => {
    e.preventDefault();
    setErrorMessage(null);

    let payload = {
      name: journeyName,
      platform: "ALEXANDRIA",
    };

    if (contentList) {
      payload.contentIds = contentList;
    }

    if (!journeyName || journeyName.length < 1) {
      setErrorMessage("Dê um nome a coleção!");
      return;
    }

    dispatch(newJourney(payload))
      .then(() => {
        setIsCreateCollectionPopUpOpen(false);
        setOpenPopUpCreateCollection(true);
        setPopUpCreateCollectionMessage("Coleção criada com sucesso!");
        setInvisibleEmpty && setInvisibleEmpty(false);
        dispatch(infoJourneyList());
      })
      .catch((err) => {
        setErrorMessage(err);
        setOpenPopUpCreateCollection(true);
        setJourneyName("");
        setInvisibleEmpty && setInvisibleEmpty(false);
        dispatch(infoJourneyList());
      });
  };

  return (
    <div className="CreateCollectionPopUp">
      <div className="container-collection-popup" ref={!openPopUpCreateCollection ? modalRef : null}>
        <div className="header-collection-popup">
          <button
            className="exit-container-collection"
            onClick={() => setIsCreateCollectionPopUpOpen(false)}
          />
        </div>
        <div className="body-collection-popup">
          <h2>Criar Coleção</h2>
          <p>Agrupe seus conhecimentos, organize suas ideias.</p>
          {errorMessage && <p>{errorMessage}</p>}
          <Input
            placeholder="Introdução"
            updateState={setJourneyName}
            type="text"
            value={journeyName}
          />
          {journeyIsFetching ? (
            <LoaderButton />
          ) : (
            <Button
              children="Criar"
              primaryColor="#CFEC59"
              onClick={(e) => {
                handleJourneyCreation(e);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateCollectionPopUp;
