import React from 'react';
import "./ContentCardListMobile.scss";
import ContentCardMobile from "../../global/ContentCardMobile/ContentCardMobile";

const ContentCardListMobile = ({items, amount, isFetching}) => {
  let amountFetching = [];

  const renderIsFetching = (key) => {
    return (isFetching && <div key={key} className={`${isFetching ? "fetching-card-mobile" : ""}`}/>)
  }

  for (let i = 0; i < amount; i++) {
    amountFetching.push(i)
  }

  return (
    <div className="ContentCardListMobile">
      {amountFetching.map(renderIsFetching)}
      {items && [...items].splice(0, amount).map((item, key, id) => {
        return <ContentCardMobile item={item} id={id} key={key}/>
      })}
    </div>
  );
};

export default ContentCardListMobile;
