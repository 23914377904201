import React from 'react';
import "./BottomAbout.scss";

const BottomAbout = () => {
  return (
    <div className="BottomAbout">
      <div className="cardContainer">
        <div className="textBox">
          <h2>Busque</h2>
          <h3>Conteúdos relevantes de verdade.</h3>
          <p>Alexandria é a biblioteca centrada em quem aprende. Somos feitos de conteúdos marcados como relevantes por
            pessoas como você, que estão buscando conhecimento. <strong>Sem anúncios, sem lero-lero.</strong></p>
        </div>
        <div className="imageBox">
          <img
            className="firstSize"
          />
        </div>
      </div>
      <div className="cardContainer">
        <div className="imageBox">
          <img
            className="firstSize"
          />
        </div>
        <div className="textBox">
          <h2>Amplie</h2>
          <h3>Conheça o ponto de vista de outras pessoas.</h3>
          <p>Esta é a base do Alexandria, ser construído pela comunidade que aprende e ensina através de sua curadoria e perspectivas.
            Mais do que expectadores passivos, os usuários do Alexandria podem atribuir novas tags e emitir suas opiniões sobre
            qualquer conteúdo da web.</p>
        </div>
      </div>
      <div className="cardContainer">
        <div className="textBox">
          <h2>Organize</h2>
          <h3>Reúna em um só lugar todos os tipos de conteúdos.</h3>
          <h3>Seja texto, áudio ou vídeo.</h3>
          <p>Crie suas próprias playlists de aprendizado e acesse facilmente mais tarde.</p>
        </div>
        <div className="imageBox">
          <img
            className="firstSize"
          />
        </div>
      </div>
      <div className="cardContainer">
        <div className="imageBox">
          <img
            className="firstSize"
          />
        </div>
        <div className="textBox">
          <h2>Compartilhe</h2>
          <h3>Compartilhe suas playlists de aprendizado com quem tem os mesmos interesses.</h3>
          <p>Ideal para trabalhos acadêmicos, Alexandria é uma opção prática para reunir e consultar bibliografias e informações.</p>
        </div>
      </div>
      <div className="cardContainer">
        <div className="textBox">
          <h2>Reindexe</h2>
          <h3>Adicione o seu ponto de vista e sugira novas tags.</h3>
          <p>Com nossa extensão para o navegador, você rapidamente consegue indexar a internet no momento que está acessando a
            informação. Instale, participe e faça a educação circular!</p>
        </div>
        <div className="imageBox">
          <img
            className="firstSize"
          />
        </div>
      </div>
    </div>
  );
};

export default BottomAbout;
