import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerSearch.scss";

const DatePickerSearch = ({
                            fromState,
                            setFromState,
                            toState,
                            setToState
                          }) => {
  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  const days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

  const onChange = dates => {
    const [start, end] = dates;
    setFromState(start);
    setToState(end);
  };

  const locale = {
    localize: {
      month: n => months[n],
      day: n => days[n]
    },
    formatLong: {}
  }

  return (
    <div className="DatePickerSearch">
      <DatePicker
        selected={fromState}
        onChange={onChange}
        startDate={fromState}
        endDate={toState}
        selectsRange
        locale={locale}
        inline
      />
    </div>
  );
};

export default DatePickerSearch;
