import React, {useState} from 'react';
import './SuggestionTagList.scss';
import Tag from "../searchArea/Tag/Tag";
import Button from "../../global/Button/Button";
import {useHistory} from "react-router-dom";

const SuggestionTagList = ({items}) => {
  const history = useHistory();
  const [selectSuggestion, setSelectSuggestion] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const tagsList = [];
  Array.isArray(items) && items.map((name) => (
    tagsList.push(name)
  ))
  const listOne = tagsList.splice(0, 6)
  const listTwo = tagsList.splice(0, 6);
  const listThree = tagsList.splice(0, 6);
  const listFour = tagsList.splice(0, 6);

  const handleClicked = (item) => {
    let state = [...selectSuggestion];
    let index = state.indexOf(item);
    if (index === -1) {
      state.push(item)
    } else {
      state.splice(index, 1);
    }
    setSelectSuggestion(state);
    setShowMessage(false);
  }

  const getQueryString = () => {
    let string = '?'
    for(let tag of selectSuggestion) {
      if (string.length > 1) {
        string += "&"
      }
      string += "tags=" + tag;
    }
    return string
  }

  return (
    <div className="SuggestionTagList">
      <h2>Ainda não encontrou o que procura?</h2>
      <p>Nos ajude a te ajudar, escolha abaixo alguns dos temas que se aproximam do seu interesse.</p>
      <div className="suggestion-container">
        <div className="suggestion-tags">
          {listOne.map((item, key) => {
            let active = selectSuggestion.indexOf(item.id) !== -1;
            return <Tag item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="suggestion-tags">
          {listTwo.map((item, key) => {
            let active = selectSuggestion.indexOf(item.id) !== -1;
            return <Tag item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="suggestion-tags">
          {listThree.map((item, key) => {
            let active = selectSuggestion.indexOf(item.id) !== -1;
            return <Tag item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="suggestion-tags">
          {listFour.map((item, key) => {
            let active = selectSuggestion.indexOf(item.id) !== -1;
            return <Tag item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
      </div>
      {showMessage && <p className="tag-error">Selecione ao menos uma tag!</p>}
      <Button 
        children="Buscar" 
        primaryColor="#CFEC59" 
        onClick={
          () => selectSuggestion.length > 0 ? 
            history.push('/pesquisa' + getQueryString()) :
            setShowMessage(true)
        }
      />
    </div>
  );
};

export default SuggestionTagList;
