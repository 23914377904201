import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  contentInJourneyList,
  infoJourneyList,
  newJourney,
} from "../../../../redux/actions/journey";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import Button from "../../../global/Button/Button";
import Input from "../../../global/form/Input/Input";
import Loader from "../../../global/Loader/Loader";
import LoaderButton from "../../../global/LoaderButton/LoaderButton";
import CreateCollectionPopUp from "../../../userProfile/collectionInfo/CreateCollectionPopUp/CreateCollectionPopUp";
import "./AddInCollectionPopUp.scss";

const AddInCollectionPopUp = ({
  setOpenAddJourney,
  setOpenBluePopJourney,
  setOpenBluePopJourneyMessage,
  contentIdProp,
  contentID
}) => {
  let { contentIdParam } = useParams();
  const dispatch = useDispatch();
  const collectionList = useSelector(
    (state) => state.journey?.journeyList?.items
  );
  const journeyFetching = useSelector((state) => state.journey?.isFetching);
  const [isCreateCollectionPopUpOpen, setIsCreateCollectionPopUpOpen] =
    useState(false);
  const [openPopUpCreateCollection, setOpenPopUpCreateCollection] =
    useState(false);
  const [popUpCreateCollectionMessage, setPopUpCreateCollectionMessage] =
    useState("");
  const [invisibleEmpty, setInvisibleEmpty] = useState(false);
  const [newCollection, setNewCollection] = useState("");
  const emptyJourney = collectionList && collectionList.length < 1;
  const [errorMessage, setErrorMessage] = useState("");
  const [activeCollection, setActiveCollection] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(infoJourneyList());
    setSearchResults(collectionList);
  }, [dispatch, emptyJourney]);

  useEffect(() => {
    const results = collectionList?.filter(
      (collection) =>
        collection.name?.toString().includes(searchTerm) ||
        collection.name?.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);

  const handleClicked = (item) => {
    let state = [...activeCollection];
    let index = state.indexOf(item);
    if (index === -1) {
      state.push(item);
    } else {
      state.splice(index, 1);
    }
    setActiveCollection(state);
  };

  const addContentInJourney = (e) => {
    e.preventDefault();
    let newCollections = activeCollection?.map((journeyId) => {
      dispatch(contentInJourneyList(journeyId, contentIdParam ? contentIdParam : contentIdProp || contentID))
    }
    );
    Promise.all(newCollections)
      .then((results) => {
        if (results.length > 1) {
          setOpenAddJourney(false);
          setOpenBluePopJourney(true);
          setOpenBluePopJourneyMessage(
            "Conteúdos adicionados as coleções com sucesso"
          );
          setTimeout(() => {
            setOpenBluePopJourney(false);
          }, 3000);
        } else {
          setOpenAddJourney(false);
          setOpenBluePopJourney(true);
          setOpenBluePopJourneyMessage(
            "Conteúdo adicionado a coleção com sucesso"
          );
          setTimeout(() => {
            setOpenBluePopJourney(false);
          }, 3000);
        }
      })
      .catch(() => {
        setOpenBluePopJourney(true);
        setOpenBluePopJourneyMessage("");
      });
  };

  const handleJourneyCreation = (e) => {
    e.preventDefault();
    setErrorMessage(null);

    let payload = {
      name: newCollection,
      platform: "ALEXANDRIA",
    };

    if (!newCollection || newCollection.length < 1) {
      setErrorMessage("Dê um nome a coleção!");
      return;
    }

    dispatch(newJourney(payload))
      .then(() => {
        setInvisibleEmpty && setInvisibleEmpty(false);
        dispatch(infoJourneyList());
        setNewCollection("");
      })
      .catch(() => {
        setNewCollection("");
        setInvisibleEmpty && setInvisibleEmpty(false);
        dispatch(infoJourneyList());
        setOpenPopUpCreateCollection(true);
      });
  };

  return (
    <div className="AddInCollectionPopUp">
      <div className="container-add-journey-popup">
        <div className="header-add-journey-popup">
          <button
            className="exit-container-journey"
            onClick={() => setOpenAddJourney(false)}
          />
        </div>
        <div className="body-add-journey-popup">
          <h2>Adicionar à coleção de aprendizado</h2>
          <Input
            placeholder="Pesquisar"
            value={searchTerm}
            type="text"
            updateState={(e) => {
              setSearchTerm(e);
              emptyJourney && setInvisibleEmpty(true);
            }}
          />
          {journeyFetching ? (
            <Loader />
          ) : (
            <div className={`container-add-journey ${emptyJourney && "empty"}`}>
              {searchResults &&
                searchResults?.map((item, key) => {
                  let active = activeCollection.indexOf(item.id) !== -1;
                  return (
                    <div
                      className={`${active ? "active" : ""}`}
                      key={key}
                      onClick={() => {
                        handleClicked(item.id);
                      }}
                    >
                      <p>{item?.name}</p>
                    </div>
                  );
                })}
            </div>
          )}
          <div class="input-create-container">
            <input
              onChange={(e) => setNewCollection(e.target.value)}
              value={newCollection}
              placeholder="Crie uma nova playlist"
            />
            <button onClick={(e) => handleJourneyCreation(e)} type="submit">
              {" "}
            </button>
          </div>
          {journeyFetching
            ? !invisibleEmpty && <LoaderButton />
            : !invisibleEmpty && (
              <Button
                children="Adicionar"
                primaryColor="#CFEC59"
                onClick={(e) => addContentInJourney(e)}
              />
            )}
          {isCreateCollectionPopUpOpen && (
            <CreateCollectionPopUp
              setOpenPopUpCreateCollection={setOpenPopUpCreateCollection}
              setPopUpCreateCollectionMessage={setPopUpCreateCollectionMessage}
              setIsCreateCollectionPopUpOpen={setIsCreateCollectionPopUpOpen}
              setInvisibleEmpty={setInvisibleEmpty}
            />
          )}
        </div>
      </div>
      {openPopUpCreateCollection && (
        <BluePopUp
          text={popUpCreateCollectionMessage}
          onClick={() => {
            setOpenPopUpCreateCollection(false);
            setIsCreateCollectionPopUpOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default AddInCollectionPopUp;
