import React, {useState} from 'react';
import "./UsernameSignUp.scss";
import Button from "../../../global/Button/Button";
import {signUp} from "../../../../redux/actions/signup";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useTextField} from "../../../../hooks/formHooks";
import {login} from "../../../../redux/actions/login";

const UsernameSignUp = ({password, email, name, setActiveModalSignUp, setEmail}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [username, setUsername, validUserName] = useTextField("");
  const [errorUsername, setErrorUsername] = useState(false);

  const handleUsername = (e) => {
    setUsername(e.target.value)
  }

  const handleSignUpUser = () => {
    if (!validUserName) {
      setErrorUsername(true)
      setUsername("")
    } else {
      const payload = {
        name,
        email,
        username,
        password
      }
      dispatch(signUp(payload))
        .then(() => {
          dispatch(login({email, password}, true)).then(() => history.push("/seguir-tags"));
        })
        .catch((response) => {
          const responseError = () => {
            response.then((response) => {
              if (response.error === "User already registered") {
                setErrorUsername(true)
                setUsername("")
              }
              if (response.error === "Email already registered") {
                setEmail("")
                setActiveModalSignUp(false)
              }
              return response.error;
            });
          };
          responseError()
        });
    }
  }

  return (
    <div className="UsernameSignUp">
      <h3>Crie um nome de usuário</h3>
      <p>Escolha um nome de usuário para a sua conta. Você poderá alterá-lo depois.</p>
      <div className="username-container">
        <div>
          <p>@</p>
          <input onKeyUp={() => setErrorUsername(false)}
                 onChange={(e) => handleUsername(e)}
          />
          <div style={{display: `${username.length > 5 ? "flex" : "none"}`}} className="checked-input"/>
        </div>
        {errorUsername && <p>Nome de usuário já existente</p>}
        <Button
          className={`${username.length < 5 ? "block-invisible" : "Button"}`}
          style={{opacity: `${username.length > 5 ? "1" : "0"}`}}
          children="Continuar" onClick={() => handleSignUpUser()}/>
      </div>
    </div>
  );
};

export default UsernameSignUp;
