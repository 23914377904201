import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DeleteIcon from "../../../assets/images/delete.svg";
import EditIcon from "../../../assets/images/green-edit-icon.svg";
import { getItemFromStorage } from "../../../helper/storage";
import { deleteContent, getContent } from "../../../redux/actions/content";
import { getContentsRequest } from "../../../redux/actions/contents";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import Button from "../../global/Button/Button";
import CommentText from "../../global/CommentText/CommentText";
import Loader from "../../global/Loader/Loader";
import PopUp from "../../global/PopUp/PopUp";
import LoginHome from "../../home/LoginHome/LoginHome";
import ContentIcon from "../ContentIcon/ContentIcon";
import ContentIconList from "../ContentIconList/ContentIconList";
import ContentRelated from "../ContentRelated/ContentRelated";
import UserContent from "../UserContent/UserContent";
import UserContentFetching from "../UserContent/UserContentFetching/UserContentFetching";
import "./ContentScreen.scss";

const ContentScreen = () => {
  const content = useSelector((state) => state.content);
  const [openComment, setOpenComment] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("ALEXANDRIA_AUTH_TOKEN");
  const userId = localStorage.getItem("USER_ID");
  const [createCommentPopUp, setCreateCommentPopUp] = useState(false);
  const [createCommentMessage, setCreateCommentMessage] = useState("");
  const [deletePopUp, setDeletePopUp] = useState(false);
  const params = useParams();
  const history = useHistory();
  const contentId = params?.contentId;
  const widthWindow = window.innerWidth;
  const userToken = getItemFromStorage("ALEXANDRIA_AUTH_TOKEN");
  const handleDeleteContent = () => {
    dispatch(deleteContent(contentId)).then(() => history.push("/perfil/meus-conteudos"));
  }

  useEffect(() => {
    dispatch(getContent(contentId));
  }, [contentId, dispatch]);

  useEffect(() => {
  }, [token, dispatch])

  useEffect(() => {
    dispatch(getContentsRequest({ pageSize: 4 }));
  }, [dispatch]);

  useEffect(() => {
    openComment
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [openComment]);

  const renderContentScreen = () => {
    if (widthWindow > 1024) {
      return (
        <div className="ContentScreen">
          <ContentIconList setOpenComment={setOpenComment} />
          {Number(userId) === content?.data?.author?.id && <div className="author-options">
            <ContentIcon
              onClick={() => history.push(`/editar-conteudo/${contentId}`)}
              textButton="editar"
              urlButton={EditIcon}
              className="green" />
            <ContentIcon
              onClick={() => setDeletePopUp(true)}
              textButton="excluir"
              urlButton={DeleteIcon}
              className="red" />
          </div>}
          {content.isFetching ? (
            <UserContentFetching />
          ) : (
            <UserContent content={content.data} fetching={content.isFetching} />
          )}
          {openComment && (
            <CommentText
              content={content.data}
              setCreateCommentPopUp={setCreateCommentPopUp}
              setCreateCommentMessage={setCreateCommentMessage}
              setOpenComment={setOpenComment}
            />
          )}
          {createCommentPopUp && (
            <BluePopUp
              text={createCommentMessage}
              onClick={() => setCreateCommentPopUp(false)}
            />
          )}
          {deletePopUp &&
            <PopUp>
              <p>Você tem certeza que deseja exccluir este conteúdo?</p>
              <div className="buttons">
                <Button primaryColor="#0505BE" onClick={handleDeleteContent}>
                  Sim
                </Button>
                <Button primaryColor="#0505BE" onClick={() => setDeletePopUp(false)}>
                  Não
                </Button>
              </div>
            </PopUp>
          }
        </div>
      );
    } else {
      return content.isFetching ? (
        <Loader />
      ) : (
        <div className="ContentScreen">
          {content.data.title && (
            <UserContent content={content.data} fetching={content.isFetching} />
          )}
          {content.data.id && <ContentRelated content={content.data} />}
          {!userToken && <LoginHome />}
        </div>
      );
    }
  };

  return renderContentScreen();
};

export default ContentScreen;
