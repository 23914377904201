import React from 'react';
import "./CardFetching.scss";

const CardFetching = ({amount}) => {
  const isFetchingCard = [];

  const renderIsFetching = (key) => {
    return (<div key={key} className="CardFetching"/>)
  }

  for (let i = 0; i < amount; i++) {
    isFetchingCard.push(i)
  }

  return (
    <>
      {isFetchingCard.map(renderIsFetching)}
    </>
  );
};

export default CardFetching;
