import React, { useEffect, useState } from 'react';
import "./ContentCommentList.scss";
import ContentComment from "../ContentComment/ContentComment";
import CommentText from '../../global/CommentText/CommentText';

const ContentCommentList = ({comments}) => {
  const [commentsText, setCommentsText] = useState([])
  
  useEffect(() => {
    setCommentsText(comments)
  }, [comments]);
  
  return (
    <div className="ContentCommentList">
      <h2>{commentsText?.length || 0} Comentários</h2>
      {commentsText?.map((item, key) => (
        <ContentComment item={item} key={key} />
      ))}
      <CommentText comments={commentsText} setCommentsText={setCommentsText}/>
    </div>
  );
};

export default ContentCommentList;
