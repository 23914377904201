import React from 'react';
import "./EditFavoriteModal.scss";
import {deleteContentFavorite, infoFavoriteList} from "../../../../redux/actions/favorite";
import {useDispatch} from "react-redux";

const EditFavoriteModal = ({setEditFavoriteModal, editFavoriteModal, id,
                             setDeletePopUpOpen, setDeleteMessage}) => {
  const dispatch = useDispatch();

  const handleDeleteFavorite= () => {
    dispatch(deleteContentFavorite(id))
      .then(() => {
        setDeletePopUpOpen(true)
        setDeleteMessage("Conteúdo excluido da lista de favoritos")
        setEditFavoriteModal(false)
        dispatch(infoFavoriteList())
      })
      .catch(() => {
        setDeletePopUpOpen(true)
        setDeleteMessage("")
        setEditFavoriteModal(false)
        dispatch(infoFavoriteList())
      });
  }

  return (
    <div style={{
      visibility: `${editFavoriteModal ? "visible" : "hidden"}`,
      opacity: `${editFavoriteModal ? 1 : 0}`
    }}
         className="EditFavoriteModal">
      <button onClick={() => handleDeleteFavorite()} >Remover conteúdo</button>
      <button onClick={() => setEditFavoriteModal(false)}>Cancelar</button>
    </div>
  );
};

export default EditFavoriteModal;
