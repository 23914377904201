import React from "react";
import "./DeleteTag.scss";
import xBlack from "../../../assets/images/x-one-black.svg";

const DeleteTag = ({ item, onClick }) => {
  return (
    <button role="application"  aria-label="Botão para deletar tag da pesquisa" className="DeleteTag" onClick={onClick}>
      {item && item}
      <img src={xBlack} />
    </button>
  );
};

export default DeleteTag;
