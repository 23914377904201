import React, {useState} from 'react';
import './EditUser.scss';
import EditPassword from "./EditPassword/EditPassword";
import EditProfile from "./EditProfile/EditProfile";
import {useHistory} from "react-router-dom";
import {useWindowSizeHook} from "../../../hooks/WindowHooks";
import LogoutUser from "./LogoutUser/LogoutUser";

const EditUser = () => {
  const [editProfile, setEditProfile] = useState(true);
  const [openLogout, setOpenLogout] = useState(false);
  const history = useHistory();
  const { width } = useWindowSizeHook();

  const renderMobile = () => {
    if (width > 1024) {
      return <>
        <button onClick={() => setEditProfile(true)}>Editar perfil</button>
        <button onClick={() => setEditProfile(false)}>Alterar senha</button>
      </>
    } else {
      return <>
        <button onClick={() => history.push("/editar/perfil/usuario")}>Editar perfil</button>
        <button onClick={() => history.push("/editar/perfil/senha")}>Alterar senha</button>
        <button onClick={() => setOpenLogout(true)} className="mobile">Sair da conta</button>
        <button className="mobile">Excluir conta</button>
      </>
    }
  }

  return (
    <section className="EditUser">
      <div className="exit-edit">
        <button onClick={() => {history.push('/perfil/colecao')}}>VOLTAR</button>
      </div>
      <div className="container-edit">
        <div className="select-edit">
          <div className="buttons-edit">
            {renderMobile()}
          </div>
          <div className="delete-profile">
            <p>Alexandria</p>
            <button>Excluir conta</button>
          </div>
        </div>
        <div className="area-edit">
          {editProfile ?  <EditProfile /> : <EditPassword />}
        </div>
      </div>
      {<LogoutUser setOpenLogout={setOpenLogout} openLogout={openLogout}/>}
    </section>
  );
};

export default EditUser;
