import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiChatAI = async (payload) => {
  try {
    const response = await fetch(`${baseUrl}/recommendation/ai/chat`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: buildHeaders()
    });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (json_1) {
    return await Promise.reject(json_1);
  }
}
