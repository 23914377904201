export const ADD_FAVORITE_REQUEST = "ADD_FAVORITE_REQUEST";
export const ADD_FAVORITE_REQUEST_SUCCEEDED = "ADD_FAVORITE_REQUEST_SUCCEEDED";
export const ADD_FAVORITE_REQUEST_FAILED = "ADD_FAVORITE_REQUEST_FAILED";

export const GET_FAVORITE_REQUEST = "GET_FAVORITE_REQUEST";
export const GET_FAVORITE_REQUEST_SUCCEEDED = "GET_FAVORITE_REQUEST_SUCCEEDED";
export const GET_FAVORITE_REQUEST_FAILED = "GET_FAVORITE_REQUEST_FAILED";

export const DELETE_FAVORITE_REQUEST = "DELETE_FAVORITE_REQUEST";
export const DELETE_FAVORITE_REQUEST_SUCCEEDED = "DELETE_FAVORITE_REQUEST_SUCCEEDED";
export const DELETE_FAVORITE_REQUEST_FAILED = "DELETE_FAVORITE_REQUEST_FAILED";

export const GET_FAVORITE_LIST_REQUEST = "GET_FAVORITE_LIST_REQUEST";
export const GET_FAVORITE_LIST_REQUEST_SUCCEEDED = "GET_FAVORITE_LIST_REQUEST_SUCCEEDED";
export const GET_FAVORITE_LIST_REQUEST_FAILED = "GET_FAVORITE_LIST_REQUEST_FAILED";
