import React from 'react';
import { Route, useHistory } from "react-router-dom";
import {hasToken} from "../../../helper/loginStorage";

const PrivateRoute = ( { children, ...rest } ) => {
  const history = useHistory();

  const isUserLogged = () => {
    if(hasToken()) {
      return true;
    }
  };

  return (
    <Route
      path={ rest.path }
      exact={ rest.exact }
      component={ isUserLogged() ? ( () => children)
        : history.push( "/login" ) }
    />
  );
};

export default PrivateRoute;
