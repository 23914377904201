import React from 'react';
import "./ContentIcon.scss";

const ContentIcon = ({textButton, urlButton, onClick, className}) => {
  return (
    <div className={`ContentIcon ${className ? className : ""}`} onClick={onClick}>
      <p>{textButton}</p>
      <button style={{backgroundImage: `url(${urlButton})`}}/>
    </div>
  );
};

export default ContentIcon;
