import React from 'react';
import "./UserMenu.scss";
import HomeIcon from "../../../assets/images/home-menu-icon.svg";
import HomeIconWhite from "../../../assets/images/home-menu-icon-white.svg";
import LibraryIcon from "../../../assets/images/library-menu-icon.svg";
import LibraryIconWhite from "../../../assets/images/library-menu-icon-white.svg";
import NotificationIcon from "../../../assets/images/notification-menu-icon.svg";
import ProfileIcon from "../../../assets/images/profile-menu-icon.svg";
import ProfileIconWhite from "../../../assets/images/profile-menu-icon-white.svg";
import {useHistory, useLocation} from "react-router-dom";

const UserMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const localization = location.pathname;
  
  const scrollTop = () => {
    window.scrollTo(0, 0)
  }
  
  const pathnameLibrary = () => {
    if (localization === "/perfil/colecao" ||
      localization === "/perfil/ver-mais" ||
      localization === "/perfil/favoritos" ||
      localization === "/perfil/historico"){
      return "library-active"
    }
  }

  return (
    <div className="UserMenu">
      <div onClick={() => {
        history.push("/")
        scrollTop()
      }}
           className={`${localization === "/" && "home-active"}`}>
        <button style={{backgroundImage: `url(${localization === "/" ? HomeIconWhite : HomeIcon})`}}/>
        <p>Home</p>
      </div>
      <div onClick={() => {
          history.push("/perfil/colecao")
          scrollTop()
        }}
        className={pathnameLibrary()}>
        <button style={{backgroundImage: `url(${pathnameLibrary() ? LibraryIconWhite : LibraryIcon})`}}/>
        <p>Biblioteca</p>
      </div>
      <div>
        <button style={{backgroundImage: `url(${NotificationIcon})`}}/>
        <p>Notificações</p>
      </div>
      <div onClick={() => {
        history.push("/editar/perfil")
        scrollTop()
      }}
           className={localization.includes("editar") ? "profile-active" : ""}>
        <button style={{backgroundImage: `url(${localization.includes("editar") ? ProfileIconWhite : ProfileIcon})`}}/>
        <p>Perfil</p>
      </div>
    </div>
  );
};

export default UserMenu;
