import {
  ADD_SEE_LATER_REQUEST,
  ADD_SEE_LATER_REQUEST_FAILED,
  ADD_SEE_LATER_REQUEST_SUCCEEDED,
  GET_SEE_LATER_REQUEST,
  GET_SEE_LATER_REQUEST_FAILED,
  GET_SEE_LATER_REQUEST_SUCCEEDED,
  DELETE_SEE_LATER_REQUEST,
  DELETE_SEE_LATER_REQUEST_FAILED, 
  DELETE_SEE_LATER_REQUEST_SUCCEEDED
} from "../constants/seeLater";

export const seeLater = (
  state = {
    isFetching: false,
    data: {},
    error: {},
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case ADD_SEE_LATER_REQUEST:
    case GET_SEE_LATER_REQUEST:
    case DELETE_SEE_LATER_REQUEST:
      newState.isFetching = true;
      return newState;

    case ADD_SEE_LATER_REQUEST_SUCCEEDED:
    case GET_SEE_LATER_REQUEST_SUCCEEDED:
    case DELETE_SEE_LATER_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case ADD_SEE_LATER_REQUEST_FAILED:
    case GET_SEE_LATER_REQUEST_FAILED:
    case DELETE_SEE_LATER_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
