import React, { useState } from "react";
import "./Login.scss";
import Button from "../../global/Button/Button";
import Input from "../../global/form/Input/Input";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/actions/login";
import LoaderButton from "../../global/LoaderButton/LoaderButton";
import Helmet from "react-helmet";
import GoogleSignIn from "../GoogleSignIn/GoogleSignIn";
import FacebookSignIn from "../FacebookSignIn/FacebookSignIn";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import LoginSuccessMessage from "./LoginSucessMessage/LoginSuccessMessage";
import { useEmailField, usePasswordField } from "../../../hooks/formHooks";
import { updateUserStorage } from "../../../helper/loginStorage";
import SignUp from "../SignUp/SignUp";

const Login = ({ successMessage, setSuccessMessage, openDesktop, setOpenDesktop }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail, validEmail] = useEmailField("");
  const [password, setPassword, validPassword] = usePasswordField("");
  const [displayError, setDisplayError] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [errorFacebook, setErrorFacebook] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);

  const handleLogin = (e) => {
    e && e.preventDefault();

    if (!validEmail || !validPassword) {
      setDisplayError(true);
    } else {
      const payload = {
        email: email,
        password: password,
      };

      dispatch(login(payload, true))
        .then((json) => {
          updateUserStorage(json)
          setSuccessMessage(true);
          setTimeout(() => {
            setSuccessMessage(false);
            openDesktop ? window.location.reload() : history.push("/");
          }, 1000);
        })
        .catch(() => {
          setDisplayError(true);
          setPassword("");
          setTimeout(() => {
            setDisplayError(false);
          }, 5000);
        });
    }
  };

  return successMessage ? (
    <LoginSuccessMessage text="LOGIN EFETUADO COM SUCESSO!" />
  ) : (
    <>
      {openSignUp ? <SignUp openSignUp={openSignUp} setOpenSignUp={setOpenSignUp} setOpenDesktop={setOpenDesktop} /> :
      <div className="loginContent">
        {openDesktop && <button className="exit-entry" onClick={() => setOpenDesktop(false)}/>}
        <Helmet title="Login - Alexandria" />
        <div
          className={`Login ${loginState && loginState.isFetching ? "fetching-login" : ""
            }`}
        >
          <div className="buttonsBox">
            <h2>Faça seu login com</h2>
            <div>
              <GoogleSignIn buttonText="Continuar com o Google" openDesktop={openDesktop} />
              <FacebookSignIn
                openDesktop={openDesktop}
                errorFacebook={setErrorFacebook}
                textButton="Continuar com o Facebook"
              />
            </div>
          </div>
          <div className="screen-splitter">
            <span />
            <p>OU</p>
            <span />
          </div>
          <form onSubmit={(e) => handleLogin(e)}>
            <div className="inputsBox">
              <Input
                updateState={setEmail}
                type="email"
                valid={validEmail}
                displayError={displayError}
                errorMessage="Email inválido"
                placeholder="Email"
                value={email}
              />
              <Input
                updateState={value => setPassword(value)}
                type="password"
                displayError={displayError}
                valid={validPassword}
                errorMessage="Senha inválida"
                placeholder="Digíte a senha"
                value={password}
              />
            </div>
            <div className="entryContent">
              {loginState && loginState.isFetching ? (
                <LoaderButton />
              ) : (
                <Button
                  primaryColor="#CFEC59"
                  children="Entrar"
                  type="submit"
                />
              )}
              <div className="registration-login">
                <p>Não tem cadastro?</p>
                <Button
                  children="Cadastrar"
                  onClick={() => openDesktop ? setOpenSignUp(true) : history.push("/registrar")}
                />
              </div>
            </div>
          </form>
          <div className="container-forgot">
            <Button
              primaryColor="#FFFFFF"
              children="Esqueceu a senha?"
              onClick={() => history.push("/redefinir")}
            />
            <Button
              primaryColor="#FFFFFF"
              children="ESQUECI A SENHA"
              onClick={() => history.push("/redefinir")}
            />
          </div>
        </div>
        {errorFacebook && (
          <BluePopUp text="" onClick={() => setErrorFacebook(false)} />
        )}
      </div>}
    </>
  );
};

export default Login;
