import { getItemFromStorage } from "../../helper/storage";
import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";

export const apiAddSeeLater = (userID, contentId, payload) => {
  return authorizedFetch(`${baseUrl}/user/user/${userID}/seelater/${contentId}`, {
    method: "PUT",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiGetSeeLaterList = (userID, filters) => {
  return authorizedFetch(urlFilters(`${baseUrl}/user/user/${userID}/seelater`, filters), {
    method: "GET",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiDeleteSeeLater = (userID, contentId) => {
  return authorizedFetch(`${baseUrl}/user/user/${userID}/seelater/${contentId}`, {
    method: "DELETE",
    headers: buildHeaders(),
  }).then(json => {
    return Promise.resolve(json);
  }).catch(json => {
    return Promise.reject(json)
  }
  )
};

export const apiGetIsMarkedOnSeeLater = (contentId) => {
  const userId = getItemFromStorage("USER_ID");
  return authorizedFetch(`${baseUrl}/user/user/${userId}/seelater/${contentId}`, {
    method: "GET",
    headers: buildHeaders()
  }).then((response) => {
    if (response.status === 404) {
      return Promise.reject(false);
    }
    return Promise.resolve(true);
  }).catch(() => Promise.reject(false));
};
