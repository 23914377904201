import {
  ADD_SEE_LATER_REQUEST,
  ADD_SEE_LATER_REQUEST_FAILED,
  ADD_SEE_LATER_REQUEST_SUCCEEDED,
  DELETE_SEE_LATER_REQUEST,
  DELETE_SEE_LATER_REQUEST_FAILED,
  DELETE_SEE_LATER_REQUEST_SUCCEEDED,
  GET_SEE_LATER_REQUEST,
  GET_SEE_LATER_REQUEST_FAILED,
  GET_SEE_LATER_REQUEST_SUCCEEDED
} from "../constants/seeLater";

export const addSeeLaterRequest = () => {
  return {
    type: ADD_SEE_LATER_REQUEST
  }
};

export const addSeeLaterRequestSucceeded = (json) => {
  return {
    type: ADD_SEE_LATER_REQUEST_SUCCEEDED,
    data: json
  }
};

export const addSeeLaterRequestFailed = (error) => {
  return {
    type: ADD_SEE_LATER_REQUEST_FAILED,
    error: error
  }
};

export const getSeeLaterRequest = () => {
  return {
    type: GET_SEE_LATER_REQUEST
  }
};

export const getSeeLaterRequestSucceeded = (json) => {
  return {
    type: GET_SEE_LATER_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getSeeLaterRequestFailed = (error) => {
  return {
    type: GET_SEE_LATER_REQUEST_FAILED,
    error: error
  }
};

export const deleteSeeLaterRequest = () => {
  return {
    type: DELETE_SEE_LATER_REQUEST
  }
};

export const deleteSeeLaterRequestSucceeded = (json) => {
  return {
    type: DELETE_SEE_LATER_REQUEST_SUCCEEDED,
    data: json
  }
};

export const deleteSeeLaterRequestFailed = (error) => {
  return {
    type: DELETE_SEE_LATER_REQUEST_FAILED,
    error: error
  }
};
