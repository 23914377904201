import React from 'react';
import ContentCard from "../../global/ContentCard/ContentCard";
import './ContentCardList.scss';

const ContentCardList = ({ amount, items, isFetching }) => {
  let amountFetching = [];

  const renderIsFetching = (key) => {
    return ((isFetching && items?.length <= 0) && <div key={key} className={`${isFetching ? "fetching-box" : ""}`} />)
  }

  for (let i = 0; i < amount; i++) {
    amountFetching.push(i)
  }

  return (
    <div className="ContentCardList">
      <div className={`cards-list ${isFetching ? "fetching" : ""}`}>
        {amountFetching.map(renderIsFetching)}
        {items && [...items].splice(0, amount).map((item, key, id) => {
          return <ContentCard isFetching={isFetching} item={item} id={id} key={key} />
        })}
      </div>
    </div>
  );
};

export default ContentCardList;
