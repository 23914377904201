import React from 'react';
import "./ContentComment.scss";
import womanProfile from "../../../assets/images/woman-profile.svg";
import Tag from "../../home/searchArea/Tag/Tag";
import {useHistory} from "react-router-dom";

const ContentComment = ({item}) => {
  const history = useHistory();

  return (
    <div className="ContentComment">
      <div className="user-comment">
        <div className="img-user-comment">
          <img src={item?.author?.photo === undefined || item?.author?.photo === null ? womanProfile : item?.author?.photo} alt="Foto de perfil do usuário" />
        </div>
        <div className="infos-comment">
          <div className="info-user-comment">
            <img
              src={item?.author?.photo === undefined || item?.author?.photo === null ? womanProfile : item?.author?.photo}
              alt="Foto de perfil do usuário"/>
            <strong>{item?.author?.username === undefined ? "Anônimo" : item?.author?.username}</strong><p> •&nbsp; agora mesmo</p>
          </div>
          <p className="name-user-comment">@{item?.author?.username === undefined ? "Anônimo" : item?.author?.username}</p>
          <div className="description-comment">
            <p>{item?.text}</p>
          </div>
        </div>
      </div>
      <div className="tags-comment">
        {item?.tags?.map((item, key) => {
          return <Tag item={item?.name} key={key} onClick={() => {
            history.push('/pesquisa?tags=' + item.id)
          }} />
        })}
      </div>
    </div>
  );
};

export default ContentComment;
