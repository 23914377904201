export const CREATE_JOURNEY_REQUEST = "POST_JOURNEY_REQUEST";
export const CREATE_JOURNEY_REQUEST_SUCCEEDED = "POST_JOURNEY_REQUEST_SUCCEEDED";
export const CREATE_JOURNEY_REQUEST_FAILED = "POST_JOURNEY_REQUEST_FAILED";

export const GET_JOURNEY_LIST_REQUEST = "GET_JOURNEY_LIST_REQUEST";
export const GET_JOURNEY_LIST_REQUEST_SUCCEEDED = "GET_JOURNEY_LIST_REQUEST_SUCCEEDED";
export const GET_JOURNEY_LIST_REQUEST_FAILED = "GET_JOURNEY_LIST_REQUEST_FAILED";

export const ADD_CONTENT_IN_JOURNEY_REQUEST = "ADD_CONTENT_IN_JOURNEY_REQUEST";
export const ADD_CONTENT_IN_JOURNEY_REQUEST_SUCCEEDED = "ADD_CONTENT_IN_JOURNEY_REQUEST_SUCCEEDED";
export const ADD_CONTENT_IN_JOURNEY_REQUEST_FAILED = "ADD_CONTENT_IN_JOURNEY_REQUEST_FAILED";

export const GET_JOURNEY_REQUEST = "GET_JOURNEY_REQUEST";
export const GET_JOURNEY_REQUEST_SUCCEEDED = "GET_JOURNEY_REQUEST_SUCCEEDED";
export const GET_JOURNEY_REQUEST_FAILED = "GET_JOURNEY_REQUEST_FAILED";

export const DELETE_JOURNEY_REQUEST = "DELETE_JOURNEY_REQUEST";
export const DELETE_JOURNEY_REQUEST_SUCCEEDED = "DELETE_JOURNEY_REQUEST_SUCCEEDED";
export const DELETE_JOURNEY_REQUEST_FAILED = "DELETE_JOURNEY_REQUEST_FAILED";

export const UPDATE_JOURNEY_REQUEST = "UPDATE_JOURNEY_REQUEST";
export const UPDATE_JOURNEY_REQUEST_SUCCEEDED = "UPDATE_JOURNEY_REQUEST_SUCCEEDED";
export const UPDATE_JOURNEY_REQUEST_FAILED = "UPDATE_JOURNEY_REQUEST_FAILED";

export const SHARE_JOURNEY_REQUEST = "SHARE_JOURNEY_REQUEST";
export const SHARE_JOURNEY_REQUEST_SUCCEEDED = "SHARE_JOURNEY_REQUEST_SUCCEEDED";
export const SHARE_JOURNEY_REQUEST_FAILED = "SHARE_JOURNEY_REQUEST_FAILED";
