import {
  deleteCommentRequest,
  deleteCommentRequestFailed,
  deleteCommentRequestSucceeded,
  getCommentRequest,
  getCommentRequestFailed,
  getCommentRequestSucceeded,
  postCommentRequest,
  postCommentRequestFailed,
  postCommentRequestSucceeded
} from "../action-creators/comment";
import {apiCreateComment, apiDeleteComment, apiGetCommentList} from "../api/comment";

const createComment = (contentId, payload) => dispatch => {
  dispatch(postCommentRequest());
  return apiCreateComment(contentId, payload)
    .then(json => {
      dispatch(postCommentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(postCommentRequestFailed(json));
      return Promise.reject();
    });
};

export const newComment = (contentId, payload) => dispatch => dispatch(createComment(contentId, payload));

const getCommentList = () => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(getCommentRequest());
  return apiGetCommentList(userID)
    .then(json => {
      dispatch(getCommentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
        dispatch(getCommentRequestFailed(response));
        return Promise.reject();
      }
    );
};

export const CommentList = () => dispatch => dispatch(getCommentList());

const deleteComment = (collaborationID) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(deleteCommentRequest());
  return apiDeleteComment(userID, collaborationID)
    .then(json => {
      dispatch(deleteCommentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
        dispatch(deleteCommentRequestFailed(response));
        return Promise.reject();
      }
    );
};

export const commentDelete = (collaborationID) => dispatch => dispatch(deleteComment(collaborationID));
