import React, {useState, useEffect} from 'react';
import './Favorite.scss';
import ContentCard from "../../global/ContentCard/ContentCard";
import {useDispatch, useSelector} from "react-redux";
import {deleteContentFavorite, infoFavoriteList} from "../../../redux/actions/favorite";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import Helmet from "react-helmet";
import ContentCardMobile from "../../global/ContentCardMobile/ContentCardMobile";
import {useWindowSizeHook} from "../../../hooks/WindowHooks";
import EditFavoriteModal from "./EditFavoriteModal/EditFavoriteModal";
import GreyPopUp from "../../global/GreyPopUp/GreyPopUp";
import CardFetching from "../../global/CardFetching/CardFetching";

const Favorite = () => {
  const dispatch = useDispatch();
  const favorite = useSelector(state => state.favorite?.data);
  const favoriteFetching = useSelector(state => state.favorite?.isFetching);
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const { width } = useWindowSizeHook();
  const [editFavoriteModal, setEditFavoriteModal] = useState(false);
  const [favoriteId, setFavoriteId] = useState("");

  useEffect(() => {
    dispatch(infoFavoriteList())
  }, [dispatch]);

  const clickButton = e => {
    e.stopPropagation()
  }
  
  const renderCardsFavorite = (item, key) => {
    if (width > 1024) {
      return !favoriteFetching &&
        <ContentCard item={item} key={key} onDelete={(e) => {
          handleDeleteFavorite(item.id)
          clickButton(e)
        }}/>
    } else {
      return !favoriteFetching && <ContentCardMobile favoriteId={setFavoriteId}  setOpenModal={setEditFavoriteModal} key={key} item={item} />
    }
  }
  const handleDeleteFavorite= (id) => {
    dispatch(deleteContentFavorite(id))
      .then(() => {
        setDeletePopUpOpen(true)
        setDeleteMessage("Conteúdo excluido da lista de favoritos")
        dispatch(infoFavoriteList())
      })
      .catch(() => {
        setDeletePopUpOpen(true)
        setDeleteMessage("")
        dispatch(infoFavoriteList())
      });
  }

  return (
    <div className="Favorite">
      <Helmet title="Favoritos - Alexandria" />
      <div className="favorite-view-cards">
        {favoriteFetching && <CardFetching amount={4} />}
        {!favoriteFetching && favorite?.items && [...favorite?.items].map((item, key) => {
          return renderCardsFavorite(item, key)
        })
        }
      </div>
      {deletePopUpOpen && <BluePopUp text={deleteMessage} onClick={() => setDeletePopUpOpen(false)}/>}
      {<EditFavoriteModal id={favoriteId} editFavoriteModal={editFavoriteModal} setEditFavoriteModal={setEditFavoriteModal}
                          setDeletePopUpOpen={setDeletePopUpOpen} setDeleteMessage={setDeleteMessage}/>}
      {deletePopUpOpen && <GreyPopUp text={deleteMessage} onClick={() => setDeletePopUpOpen(false)}/>}
    </div>
  );
};

export default Favorite;
