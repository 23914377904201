import React, {useEffect, useState} from 'react';
import "./PlaylistAdd.scss";
import Button from "../Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {contentInJourneyList, infoJourneyList} from "../../../redux/actions/journey";
import CreatePlaylist from "../CreatePlaylist/CreatePlaylist";

const PlaylistAdd = ({setOpenPlaylist, openPlaylist, content}) => {
  const dispatch = useDispatch();
  const journeyList = useSelector(state => state.journey?.journeyList?.items);
  const isAuth = useSelector(state => state.login?.token);
  const [createPlaylist, setCreatePlaylist] = useState(false);
  
  useEffect(() => {
    if (isAuth) {
      dispatch(infoJourneyList());
    }
  }, [dispatch, isAuth])
  
  const addContentInJourney = (e, id) => {
    e.preventDefault()
    dispatch(contentInJourneyList(id, content?.id))
      .then(() => {
        dispatch(infoJourneyList())
        alert("Conteúdo adicionado a playlist com sucesso")
      })
      .catch((response) => {
        dispatch(infoJourneyList())
        if(response.error === `The content ${content?.id} has already been added`){
          alert("Conteúdo adicionado a playlist com sucesso")
        } else {
          alert("Houve um erro inesperado, tente novamente mais tarde.")
        }
      });
  }

  return (
    <section style={{visibility: `${openPlaylist ? "visible" : "hidden"}`, opacity: `${openPlaylist ? 1 : 0}`}}
             className="PlaylistAdd">
      <button className="exit-entry-mobile" onClick={() => setOpenPlaylist(false)}/>
      <div className="playlist-add-top">
        <p>Adicionar às minhas playlists</p>
        <div className="playlist-add-container">
          <Button children="Nova playlist" primaryColor="#CFEC59" onClick={() => setCreatePlaylist(true)}/>
        </div>
        <div className="playlist-add-list">
          {journeyList && journeyList.map((item, key) => {
            return <button
              onClick={(e) => {
                addContentInJourney(e, item.id)
              }}
              key={key}
            >{item.name}</button>
          })}
        </div>
      </div>
      {<CreatePlaylist setCreatePlaylist={setCreatePlaylist} createPlaylist={createPlaylist} />}
    </section>
  );
};

export default PlaylistAdd;
