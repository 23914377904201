import React from 'react';
import "./GreyPopUp.scss";

const GreyPopUp = ({text, onClick}) => {
  return (
      <div className="GreyPopUp">
        <div>
          <p>{!text ? "Houve um erro inesperado, tente novamente." : text}</p>
          <button onClick={onClick}><span/></button>
        </div>
      </div>
  );
};

export default GreyPopUp;
