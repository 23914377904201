import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import App from './components/App';
import { ChatProvider } from './components/global/chat/ChatProvider/ChatProvider';
import './index.css';
import { comment } from "./redux/reducers/comment";
import { content } from "./redux/reducers/content";
import { contents } from "./redux/reducers/contents";
import { favorite } from "./redux/reducers/favorite";
import { history } from "./redux/reducers/history";
import { journey } from "./redux/reducers/journey";
import { learningList } from "./redux/reducers/learningList";
import { login } from "./redux/reducers/login";
import { photo } from "./redux/reducers/photo";
import { recommendation } from "./redux/reducers/recommendation";
import { related } from "./redux/reducers/related";
import { seeLater } from "./redux/reducers/seeLater";
import { signup } from "./redux/reducers/signup";
import { checkFollowTag, followTag, suggestionTags, tags } from "./redux/reducers/tags";
import { newPassword, user } from "./redux/reducers/user";

const reducers = combineReducers({
  contents,
  content,
  tags,
  signup,
  login,
  user,
  newPassword,
  related,
  learningList,
  journey,
  favorite,
  seeLater,
  comment,
  history,
  checkFollowTag,
  followTag,
  photo,
  recommendation,
  suggestionTags
})

const middlewares = [thunk];

if (process.env.NODE_ENV !== "production") {
  middlewares.push(createLogger());
}

const store = createStore(reducers, applyMiddleware(...middlewares));

ReactDOM.render(
  <Provider store={store}>
    <ChatProvider>
      <App />
    </ChatProvider>
  </Provider>,
  document.getElementById('root')
);
