import {
  CHECK_FOLLOW_TAG_REQUEST,
  CHECK_FOLLOW_TAG_REQUEST_FAILED,
  CHECK_FOLLOW_TAG_REQUEST_SUCCEEDED,
  FOLLOW_TAG_REQUEST,
  FOLLOW_TAG_REQUEST_FAILED,
  FOLLOW_TAG_REQUEST_SUCCEEDED,
  TAGS_REQUEST,
  TAGS_REQUEST_FAILED,
  TAGS_REQUEST_SUCCEEDED,
  UNFOLLOW_TAG_REQUEST,
  UNFOLLOW_TAG_REQUEST_FAILED,
  UNFOLLOW_TAG_REQUEST_SUCCEEDED,
  UPDATE_FOLLOW_TAG_REQUEST,
  UPDATE_FOLLOW_TAG_REQUEST_FAILED,
  UPDATE_FOLLOW_TAG_REQUEST_SUCCEEDED,
  SUGGESTION_TAGS_REQUEST,
  SUGGESTION_TAGS_REQUEST_FAILED,
  SUGGESTION_TAGS_REQUEST_SUCCEEDED
} from "../constants/tags";

export const tagsRequest = () => {
  return {
    type: TAGS_REQUEST
  }
};

export const tagsRequestSucceeded = (json) => {
  return {
    type: TAGS_REQUEST_SUCCEEDED,
    data: json
  }
};

export const tagsRequestFailed = (error) => {
  return {
    type: TAGS_REQUEST_FAILED,
    error: error
  }
};

export const checkFollowTagRequest = () => {
  return {
    type: CHECK_FOLLOW_TAG_REQUEST
  }
};

export const checkFollowTagRequestSucceeded = (json) => {
  return {
    type: CHECK_FOLLOW_TAG_REQUEST_SUCCEEDED,
    data: json
  }
};

export const checkFollowTagRequestFailed = (error) => {
  return {
    type: CHECK_FOLLOW_TAG_REQUEST_FAILED,
    error: error
  }
};

export const followTagRequest = () => {
  return {
    type: FOLLOW_TAG_REQUEST
  }
};

export const followTagRequestSucceeded = (json) => {
  return {
    type: FOLLOW_TAG_REQUEST_SUCCEEDED,
    data: json
  }
};

export const followTagRequestFailed = (error) => {
  return {
    type: FOLLOW_TAG_REQUEST_FAILED,
    error: error
  }
};

export const unfollowTagRequest = () => {
  return {
    type: UNFOLLOW_TAG_REQUEST
  }
};

export const unfollowTagRequestSucceeded = (json) => {
  return {
    type: UNFOLLOW_TAG_REQUEST_SUCCEEDED,
    data: json
  }
};

export const unfollowTagRequestFailed = (error) => {
  return {
    type: UNFOLLOW_TAG_REQUEST_FAILED,
    error: error
  }
};

export const updateFollowTagRequest = () => {
  return {
    type: UPDATE_FOLLOW_TAG_REQUEST
  }
};

export const updateFollowTagRequestSucceeded = (json) => {
  return {
    type: UPDATE_FOLLOW_TAG_REQUEST_SUCCEEDED,
    data: json
  }
};

export const updateFollowTagRequestFailed = (error) => {
  return {
    type: UPDATE_FOLLOW_TAG_REQUEST_FAILED,
    error: error
  }
};

export const suggestionTagsRequest = () => {
  return {
    type: SUGGESTION_TAGS_REQUEST
  }
};

export const suggestionTagsRequestSucceeded = (json) => {
  return {
    type: SUGGESTION_TAGS_REQUEST_SUCCEEDED,
    data: json
  }
};

export const suggestionTagsRequestFailed = (error) => {
  return {
    type: SUGGESTION_TAGS_REQUEST_FAILED,
    error: error
  }
};
