import React, {useEffect, useState} from 'react';
import "./LibraryInfo.scss";
import {useHistory, useLocation} from "react-router-dom";

const LibraryInfo = () => {
  const history = useHistory()
  const location = useLocation();
  const {pathname} = location;
  const [buttonActive, setButtonActive] = useState(pathname.split("perfil/").pop());
  
  useEffect(() => {
    setButtonActive(pathname.split("perfil/").pop())
  }, [location, pathname])

  return (
    <div className="LibraryInfo">
      <div>
        <button
          onClick={() => {
            setButtonActive("conteudos")
            history.push('/perfil/meus-conteudos')
          }}
          className={`${buttonActive === "conteudos" ? "active" : ""}`}
        >
          Meus conteúdos
        </button>
        <button
          onClick={() => {
            setButtonActive("jornada")
            history.push('/perfil/colecao')
          }}
          className={`${buttonActive === "jornada" ? "active" : ""}`}
        >Playlist
        </button>
        <button
          onClick={() => {
            setButtonActive("ver-mais")
            history.push('/perfil/ver-mais')
          }}
          className={`${buttonActive === "ver-mais" ? "active" : ""}`}
        >Ver mais tarde
        </button>
        <button
          onClick={() => {
            setButtonActive("favoritos")
            history.push('/perfil/favoritos')
          }}
          className={`${buttonActive === "favoritos" ? "active" : ""}`}
        >Favoritos
        </button>
        <button
          onClick={() => {
            setButtonActive("historico")
            history.push('/perfil/historico')
          }}
          className={`${buttonActive === "historico" ? "active" : ""}`}
        >Histórico
        </button>
      </div>
    </div>
  );
};

export default LibraryInfo;
