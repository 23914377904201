import {isPromise} from "../../helper/objectHelper";
import {tokenRefreshFailed} from "../action-creators/error";

const error = (action, errorObject) => dispatch => {
  dispatch(action(errorObject));
};

const failedToRefreshToken = () => dispatch => {
  dispatch(tokenRefreshFailed());
};

export const handleError = (dispatch, action, response) => {
  if (isPromise(response)) {
    return response.then(json => {
      dispatch(error(action, json));

      if (json.code === "token_not_valid" || json.msg === "Token has expired") {
        dispatch(failedToRefreshToken());
      }

      return Promise.reject(json);
    });
  } else {
    dispatch(error(action, response));
    return Promise.reject(response);
  }
};
