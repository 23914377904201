import React, { useState } from "react";
import Helmet from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./RecoveryInputCode.scss";
import Button from "../../../global/Button/Button";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import LoaderButton from "../../../global/LoaderButton/LoaderButton";
import { forgotPassword } from "../../../../redux/actions/user";

const RecoveryInputCode = () => {
  const [codeOne, setCodeOne] = useState(new Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const urlCode = codeOne.join("");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const email = location?.state?.email;
  const token = location?.state?.token;

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setCodeOne([
      ...codeOne.map((d, idx) => (idx === index ? element.value : d)),
    ]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const resendCode = () => {
    setIsLoading(true);
    dispatch(forgotPassword(token, { email }))
      .then(() => {
        setModalMessage("Código enviado com sucesso!");
        setIsLoading(false);
        setShowModal(true);
      })
      .catch(() => {
        setModalMessage("Ocorreu um erro ao tentar enviar o email");
        setIsLoading(false);
        setShowModal(true);
      });
  };

  return (
    <div className="RecoveryInputCode">
      <Helmet title="Recuperação de Senha - Alexandria" />
      <h2>Digite o código</h2>
      <p>
        Insira o código de 6 dígitos que enviamos para seu email: <b>{email}</b>
      </p>
      <div className="code-container">
        {codeOne.map((data, index) => {
          return (
            <input
              onChange={(e) => handleChange(e.target, index)}
              name="codeOne"
              className="otp-field"
              type="text"
              value={data}
              key={index}
              style={{ border: `${data && "1px solid #CFEC59"}` }}
              maxLength="1"
              onFocus={(e) => e.target.select()}
            />
          );
        })}
      </div>
      {!isLoading && (
        <Button
          primaryColor="#FFFFFF"
          children="Não recebi o código"
          onClick={() => resendCode()}
        />
      )}
      <div className="comeback-login">
        {isLoading ? (
          <LoaderButton />
        ) : (
          <Button
            primaryColor="#CFEC59"
            children="Confirmar"
            onClick={() => {
              urlCode.length >= 6
                ? history.push({
                    pathname: `/redefinir/codigo/${urlCode}`,
                    state: { email, token },
                  })
                : (() => {
                    setModalMessage("Preencha todo o código!");
                    setShowModal(true);
                  })();
            }}
          />
        )}
      </div>
      {showModal && (
        <BluePopUp text={modalMessage} onClick={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default RecoveryInputCode;
