import {
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_REQUEST_FAILED,
  ADD_FAVORITE_REQUEST_SUCCEEDED,
  DELETE_FAVORITE_REQUEST,
  DELETE_FAVORITE_REQUEST_FAILED,
  DELETE_FAVORITE_REQUEST_SUCCEEDED,
  GET_FAVORITE_REQUEST,
  GET_FAVORITE_REQUEST_FAILED,
  GET_FAVORITE_REQUEST_SUCCEEDED,
  GET_FAVORITE_LIST_REQUEST,
  GET_FAVORITE_LIST_REQUEST_FAILED,
  GET_FAVORITE_LIST_REQUEST_SUCCEEDED
} from "../constants/favorite";

export const favorite = (
  state = {
    isFetching: false,
    data: {},
    error: {},
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case ADD_FAVORITE_REQUEST:
    case GET_FAVORITE_REQUEST:
    case DELETE_FAVORITE_REQUEST:
    case GET_FAVORITE_LIST_REQUEST:  
      newState.isFetching = true;
      return newState;

    case ADD_FAVORITE_REQUEST_SUCCEEDED:
    case GET_FAVORITE_REQUEST_SUCCEEDED:
    case DELETE_FAVORITE_REQUEST_SUCCEEDED:
    case GET_FAVORITE_LIST_REQUEST_SUCCEEDED:  
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case ADD_FAVORITE_REQUEST_FAILED:
    case GET_FAVORITE_REQUEST_FAILED:
    case DELETE_FAVORITE_REQUEST_FAILED:
    case GET_FAVORITE_LIST_REQUEST_FAILED:  
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
