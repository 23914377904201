import {
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_REQUEST_FAILED,
  NEW_PASSWORD_REQUEST_SUCCEEDED,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_REQUEST_FAILED,
  USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_FAILED,
  GET_USER_INFO_REQUEST_SUCCEEDED,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_REQUEST_SUCCEEDED,
  UPDATE_USER_INFO_FAILED,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_REQUEST_SUCCEEDED,
  UPDATE_PASSWORD_REQUEST_FAILED,
  UPLOAD_USER_PHOTO_REQUEST,
  UPLOAD_USER_PHOTO_REQUEST_SUCCEEDED,
  UPLOAD_USER_PHOTO_FAILED
} from "../constants/user";

export const userForgotPasswordRequest = () => {
  return {
    type: USER_FORGOT_PASSWORD_REQUEST
  };
};

export const userForgotPasswordRequestSucceeded = (json) => {
  return {
    type: USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED,
    data: json
  };
};

export const userForgotPasswordRequestFailed = json => {
  return {
    type: USER_FORGOT_PASSWORD_REQUEST_FAILED,
    error: json
  };
};

export const userNewPasswordRequest = () => ({
  type: NEW_PASSWORD_REQUEST,
});

export const userNewPasswordRequestSucceeded = (json) => ({
  type: NEW_PASSWORD_REQUEST_SUCCEEDED,
  data: json
});

export const userNewPasswordRequestFailed = (json) => ({
  type: NEW_PASSWORD_REQUEST_FAILED,
  error: json
});

export const userInfoRequest = () => {
  return {
    type: GET_USER_INFO_REQUEST
  }
};

export const userInfoRequestSucceeded = (payload) => {
  return {
    type: GET_USER_INFO_REQUEST_SUCCEEDED,
    data: payload
  }
};


export const userInfoRequestFailed = (json) => {
  return {
    type: GET_USER_INFO_FAILED,
    error: json
  }
};

export const updateUserInfoRequest = () => {
  return {
    type: UPDATE_USER_INFO_REQUEST
  }
};

export const updateUserInfoRequestSucceeded = (payload) => {
  return {
    type: UPDATE_USER_INFO_REQUEST_SUCCEEDED,
    data: payload
  }
};


export const updateUserInfoRequestFailed = (json) => {
  return {
    type: UPDATE_USER_INFO_FAILED,
    error: json
  }
};

export const userUpdatePasswordRequest = () => ({
  type: UPDATE_PASSWORD_REQUEST,
});

export const userUpdatePasswordRequestSucceeded = (json) => ({
  type: UPDATE_PASSWORD_REQUEST_SUCCEEDED,
  data: json
});

export const userUpdatePasswordRequestFailed = (json) => ({
  type: UPDATE_PASSWORD_REQUEST_FAILED,
  error: json
});

export const uploadPhotoRequest = () => {
  return {
    type: UPLOAD_USER_PHOTO_REQUEST
  }
};

export const uploadPhotoRequestSucceeded = (payload) => {
  return {
    type: UPLOAD_USER_PHOTO_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const uploadPhotoRequestFailed = (json) => {
  return {
    type: UPLOAD_USER_PHOTO_FAILED,
    error: json
  }
};
