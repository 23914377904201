import React from 'react';
import './LoaderButton.scss'

const LoaderButton = ({twoLoader}) => {
  return (
    <div className={`LoaderButton ${twoLoader ? "twoLoader" : ""}`}>
      <div/>
    </div>
  );
};

export default LoaderButton;
