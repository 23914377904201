import React from 'react';
import './Button.scss';

const Button = ({children, primaryColor, className, ...props}) => {
  return (
    <button style={{backgroundColor: primaryColor}} className={`Button ${className ? className : ""}`} {...props}>
      {children}
    </button>
  );
}

export default Button;
