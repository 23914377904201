import {
  CREATE_JOURNEY_REQUEST,
  CREATE_JOURNEY_REQUEST_FAILED,
  CREATE_JOURNEY_REQUEST_SUCCEEDED,
  GET_JOURNEY_LIST_REQUEST,
  GET_JOURNEY_LIST_REQUEST_FAILED,
  GET_JOURNEY_LIST_REQUEST_SUCCEEDED,
  ADD_CONTENT_IN_JOURNEY_REQUEST,
  ADD_CONTENT_IN_JOURNEY_REQUEST_FAILED,
  ADD_CONTENT_IN_JOURNEY_REQUEST_SUCCEEDED,
  GET_JOURNEY_REQUEST,
  GET_JOURNEY_REQUEST_FAILED,
  GET_JOURNEY_REQUEST_SUCCEEDED,
  DELETE_JOURNEY_REQUEST,
  DELETE_JOURNEY_REQUEST_FAILED,
  DELETE_JOURNEY_REQUEST_SUCCEEDED,
  UPDATE_JOURNEY_REQUEST,
  UPDATE_JOURNEY_REQUEST_FAILED,
  UPDATE_JOURNEY_REQUEST_SUCCEEDED, SHARE_JOURNEY_REQUEST_SUCCEEDED, SHARE_JOURNEY_REQUEST, SHARE_JOURNEY_REQUEST_FAILED
} from "../constants/journey";

export const journey = (
  state = {
    isFetching: false,
    data: {},
    error: {},
    journeyList: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case CREATE_JOURNEY_REQUEST:
    case GET_JOURNEY_LIST_REQUEST:
    case ADD_CONTENT_IN_JOURNEY_REQUEST:
    case GET_JOURNEY_REQUEST:
    case DELETE_JOURNEY_REQUEST:
    case UPDATE_JOURNEY_REQUEST:
    case SHARE_JOURNEY_REQUEST:
      newState.isFetching = true;
      return newState;

    case CREATE_JOURNEY_REQUEST_SUCCEEDED:
    case GET_JOURNEY_REQUEST_SUCCEEDED:
    case ADD_CONTENT_IN_JOURNEY_REQUEST_SUCCEEDED:
    case DELETE_JOURNEY_REQUEST_SUCCEEDED:
    case UPDATE_JOURNEY_REQUEST_SUCCEEDED:
    case SHARE_JOURNEY_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;
      
    case GET_JOURNEY_LIST_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.journeyList = action.data;
      newState.error = {};
      return newState;
  
    case CREATE_JOURNEY_REQUEST_FAILED:
    case GET_JOURNEY_LIST_REQUEST_FAILED:
    case ADD_CONTENT_IN_JOURNEY_REQUEST_FAILED:
    case GET_JOURNEY_REQUEST_FAILED:
    case DELETE_JOURNEY_REQUEST_FAILED:
    case UPDATE_JOURNEY_REQUEST_FAILED:
    case SHARE_JOURNEY_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
