import React, {useState, useEffect} from 'react';
import "./Notes.scss";
import NotesTitle from "../NotesTitle/NotesTitle";
import {useDispatch, useSelector} from "react-redux";
import {CommentList} from "../../../../redux/actions/comment";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import NotesFetching from "./NotesFetching/NotesFetching";
import Helmet from "react-helmet";

const Notes = () => {
  const dispatch = useDispatch();
  const comment = useSelector(state => state.comment?.data);
  const commentIsFetching = useSelector(state => state.comment?.isFetching);
  const [commentGroupedByContent, setCommentGroupedByContent] = useState([]);
  const [deleteNotePopUp, setDeleteNotePopUp] = useState(false);
  const [deleteNoteMessage, setDeleteNoteMessage] = useState("");

  useEffect(() => {
    dispatch(CommentList())
  }, [dispatch]);

  useEffect(() => {
    let newList = {};
      comment.items?.map((mapItem) => {
        if (newList[mapItem.content.id]) {
          const newItems = comment.items.filter((item) => {
            const index = newList[mapItem.content.id].indexOf(item)
            return index === -1 && item.content.id === mapItem.content.id
          })
          if (newList[mapItem.content.id].length > 0) {
            newList[mapItem.content.id] = [...newList[mapItem.content.id], ...newItems];
          }
        }else {
          newList[mapItem.content.id] = [mapItem];
        }
        return mapItem
    })
    setCommentGroupedByContent(Object.values(newList));
  }, [comment]);

  return (
    <div className="Notes">
      <Helmet title="Notas - Alexandria" />
      {commentIsFetching ? <NotesFetching /> :
        commentGroupedByContent.length < 1 && <p className="empty-note">Você não possui nenhuma nota</p> }
      {!commentIsFetching && commentGroupedByContent.reverse().map((item, key) => {
          return <NotesTitle
            setDeleteNotePopUp={setDeleteNotePopUp}
            setDeleteNoteMessage={setDeleteNoteMessage}
            items={item} key={key} />
      })}
      {deleteNotePopUp && <BluePopUp text={deleteNoteMessage} onClick={() => setDeleteNotePopUp(false)}/>}
    </div>
  );
};

export default Notes;
