import React, {useEffect} from 'react';
import "./ContentRelated.scss";
import ContentCardMobile from "../../global/ContentCardMobile/ContentCardMobile";
import {getRelated} from "../../../redux/actions/related";
import {useDispatch, useSelector} from "react-redux";

const ContentRelated = ({content}) => {
  const related = useSelector(state => state.related?.data?.items);
  const contentId = content?.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRelated(contentId))
  }, [dispatch, contentId]);

  return (
    <div className="ContentRelated">
      <p className="title-related">CONTEÚDOS <strong>RELACIONADOS</strong></p>
      {related && [...related].splice(0, 4).map((item, key) => {
        return <ContentCardMobile item={item} key={key}/>
      })}
    </div>
  );
};

export default ContentRelated;
