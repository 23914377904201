import { saveLoginStorage } from "../../helper/loginStorage";
import {
  facebookDataRequest, facebookDataRequestFailed, facebookDataRequestSucceeded, facebookOAuthSucceeded,
  googleLoginSucceeded,
  loginRequest,
  loginRequestFailed,
  loginRequestSucceeded,
  loginWithGoogle,
  loginWithGoogleSucceeded
} from "../action-creators/login";
import { apiFacebookLogin, apiGoogleLogin, apiLogin } from "../api/login";
import { handleError } from "./error";

const requestLogin = (payload, keepLogged) => dispatch => {
  dispatch(loginRequest());
  return apiLogin(payload)
    .then(json => {
      dispatch(loginRequestSucceeded(json));
      saveLoginStorage(json)
      dispatch({ type: 'SET_TOKEN', payload: json?.token });
      dispatch({ type: 'SET_USER_INFO', payload: json?.user });
      return Promise.resolve(json);
    })
    .catch(response => handleError(dispatch, loginRequestFailed, response));
};
export const login = (payload, keepLogged) => dispatch => dispatch(requestLogin(payload, keepLogged));

const createLoginWithGoogleRequest = payload => dispatch => {
  dispatch(loginWithGoogle());

  return apiGoogleLogin(payload)
    .then(json => {
      dispatch(loginWithGoogleSucceeded(json));
      saveLoginStorage(json)
      dispatch({ type: 'SET_TOKEN', payload: json?.token });
      dispatch({ type: 'SET_USER_INFO', payload: json?.user });
      return Promise.resolve(json);
    })
    .catch(response => response);
};

export const googleLogin = payload => dispatch => dispatch(createLoginWithGoogleRequest(payload));

export const registerGoogleLogin = googleData => dispatch => {
  dispatch(googleLoginSucceeded(googleData));
  const payload = { tokenId: googleData.tokenId };

  return dispatch(googleLogin(payload));
};

export const registerGoogleLoginFailed = message => dispatch => Promise.reject(message);

const createLoginWithFacebookRequest = payload => dispatch => {
  dispatch(facebookDataRequest());

  return apiFacebookLogin(payload)
    .then(json => {
      dispatch(facebookDataRequestSucceeded(json));
      saveLoginStorage(json)
      dispatch({ type: 'SET_TOKEN', payload: json?.token });
      dispatch({ type: 'SET_USER_INFO', payload: json?.user });
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(facebookDataRequestFailed(response));
      return Promise.reject(response);
    });
};

export const FacebookLogin = payload => dispatch => dispatch(createLoginWithFacebookRequest(payload));

export const registerFacebookToken = payload => dispatch => {
  dispatch(facebookOAuthSucceeded(payload.code));
  return dispatch(FacebookLogin(payload));
};
