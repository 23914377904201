import React, {useEffect, useState} from 'react';
import "./PlaylistMobile.scss";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../global/Button/Button";
import {infoJourneyList} from "../../../redux/actions/journey";
import {useHistory} from "react-router-dom";
import CreatePlaylist from "../../global/CreatePlaylist/CreatePlaylist";
import EditPlaylistModal from "./EditPlaylistModal/EditPlaylistModal";
import GreyPopUp from "../../global/GreyPopUp/GreyPopUp";
import CardFetching from "../../global/CardFetching/CardFetching";

const PlaylistMobile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [createPlaylist, setCreatePlaylist] = useState(false);
  const [editPlaylistModal, setEditPlaylistModal] = useState(false);
  const journeyList = useSelector(state => state.journey?.journeyList?.items);
  const journeyIsFetching = useSelector(state => state.journey?.isFetching);
  const [idItem, setIdItem] = useState("");
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [createPlaylistPopUp, setCreatePlaylistPopUp] = useState(false);
  const [createMessage, setCreateMessage] = useState("");

  useEffect(() => {
    dispatch(infoJourneyList())
  }, [dispatch])

  return (
    <section className="PlaylistMobile">
      <div className="playlist-mobile-container">
        <div className="playlist-add-container">
          <Button children="Nova playlist" primaryColor="#CFEC59" onClick={() => setCreatePlaylist(true)}/>
        </div>
        <div className="playlist-mobile-list">
          {journeyIsFetching ? <div className="journeylist-fetching"><CardFetching amount={5}/></div> :
          journeyList && journeyList.map((item, key) => {
            return <><div key={key}>
              <p onClick={() => history.push(`/playlist/${item && item?.id}`)}
              >{item.name}</p>
              <button onClick={() => {
                setEditPlaylistModal(true)
                setIdItem(item?.id)
              }}/>
            </div>
            </>
          })}
        </div>
      </div>
      {<CreatePlaylist setCreateMessage={setCreateMessage} setCreatePlaylistPopUp={setCreatePlaylistPopUp}
                       setCreatePlaylist={setCreatePlaylist} createPlaylist={createPlaylist}/>}
      {<EditPlaylistModal id={idItem}
                          setEditPlaylistModal={setEditPlaylistModal} editPlaylistModal={editPlaylistModal}
                          setDeleteMessage={setDeleteMessage} setDeletePopUpOpen={setDeletePopUpOpen}/>}
      {deletePopUpOpen && <GreyPopUp text={deleteMessage} onClick={() => setDeletePopUpOpen(false)}/>}
      {createPlaylistPopUp && <GreyPopUp text={createMessage} onClick={() => setCreatePlaylistPopUp(false)}/>}
    </section>
  );
};

export default PlaylistMobile;
