import React, { useEffect, useRef, useState } from 'react';
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import iconNew from "../../../../assets/images/playlist-icon-white.svg";
import { infoJourneyList } from "../../../../redux/actions/journey";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import CardFetching from "../../../global/CardFetching/CardFetching";
import Collection from "../Collection/Collection";
import CreateCollectionPopUp from "../CreateCollectionPopUp/CreateCollectionPopUp";
import "./CollectionList.scss";

const CollectionList = () => {
  const dispatch = useDispatch();
  const [isCreateCollectionPopupOpen, setIsCreateCollectionPopUpOpen] = useState(false);
  const [openPopUpCreateCollection, setOpenPopUpCreateCollection] = useState(false);
  const [popUpCreateCollectionMessage, setPopUpCreateCollectionMessage] = useState("");
  const collectionIsFetching = useSelector(state => state.journey?.isFetching);
  const collectionData = useSelector(state => state.journey?.journeyList);

  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadedItems, setLoadedItems] = useState([]);
  const observer = useRef(null);
  const endOfPageRef = useRef(null);

  const buildFilters = () => {
    let filters = {};
    return filters;
  }

  useEffect(() => {
    if (pageNo === 1) {
      setLoadedItems([]);
    }

    if (!loading) {
      setLoading(true);
      dispatch(infoJourneyList({ ...buildFilters(), page: pageNo })).then((newData) => {
        setLoadedItems((prevItems) => [...prevItems, ...newData.items]);
        setLoading(false);
      });
    }
  }, [pageNo]);

  useEffect(() => {
    if (collectionIsFetching || pageNo >= collectionData?.totalPages) {
      return;
    }

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading) {
        setPageNo((prevPage) => prevPage + 1);
      }
    });

    if (endOfPageRef.current) {
      observer.current.observe(endOfPageRef.current);
    }
  }, [loading, collectionData?.isFetching]);

  return (
    <div className="CollectionList">
      <Helmet title="Coleções - Alexandria" />
      <div className='create-collections'>
        <h2>Coleções</h2>
        <button onClick={() => setIsCreateCollectionPopUpOpen(true)}>Nova coleção <img src={iconNew} /></button>
      </div>
      {isCreateCollectionPopupOpen && <CreateCollectionPopUp
        setOpenPopUpCreateCollection={setOpenPopUpCreateCollection}
        setPopUpCreateCollectionMessage={setPopUpCreateCollectionMessage}
        setIsCreateCollectionPopUpOpen={setIsCreateCollectionPopUpOpen} />}
      {openPopUpCreateCollection && <BluePopUp text={popUpCreateCollectionMessage} onClick={() => {
        setOpenPopUpCreateCollection(false)
        setIsCreateCollectionPopUpOpen(false)
      }} />}
      <div className='list-collections'>
        {collectionIsFetching ? <CardFetching amount={8} /> : loadedItems?.map((item, key) => {
          return <Collection item={item} key={key} />
        })}
        <div className='end-of-page' ref={endOfPageRef} />
      </div>
      <div className="expand-contents">
      </div>
    </div>
  );
};

export default CollectionList;
