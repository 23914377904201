import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationLink.scss';

const NavigationLink = ({children, active, onClick, to}) => {
    return (
    <Link onClick={onClick} to={to} className={`NavigationLink ${active ? 'active' : ''}`}>
        {children}
    </Link>);
}

export default NavigationLink;
