import React, {useState} from 'react';
import "./CreatePlaylist.scss";
import Button from "../Button/Button";
import {infoJourneyList, newJourney} from "../../../redux/actions/journey";
import {useDispatch, useSelector} from "react-redux";
import LoaderButton from "../LoaderButton/LoaderButton";
import GreyPopUp from "../GreyPopUp/GreyPopUp";

const CreatePlaylist = ({setCreatePlaylist, createPlaylist, setCreatePlaylistPopUp, setCreateMessage}) => {
  const dispatch = useDispatch();
  const journeyIsFetching = useSelector(state => state?.journey?.isFetching);
  const [playlistName, setPlaylistName] = useState("");
  const [errorName, setErrorName] = useState(false);

  const handleJourneyCreation = (e) => {
    e.preventDefault()
    let payload = {
      name: playlistName,
      platform: "ALEXANDRIA",
    }
    if (!playlistName) {
      setErrorName(true)
    }
    dispatch(newJourney(payload))
      .then(() => {
        setCreateMessage("Jornada criada com sucesso!")
        setCreatePlaylist(false)
        setPlaylistName("")
        setCreatePlaylistPopUp(true)
        dispatch(infoJourneyList())
      })
      .catch(() => {
        setPlaylistName("")
        setCreateMessage("")
        setCreatePlaylistPopUp(false)
        dispatch(infoJourneyList())
      });
  }
  
  return (
    <section style={{
      visibility: `${createPlaylist ? "visible" : "hidden"}`,
      opacity: `${createPlaylist ? 1 : 0}`
    }}
             className="CreatePlaylist">
      <button className="exit-entry-mobile" onClick={() => setCreatePlaylist(false)}/>
      <div className="create-playlist-container">
        <p>Nova playlist</p>
        <div className="create-playlist-input">
          <input
            onChange={(e) => setPlaylistName(e.target.value)}
            type="text"
            value={playlistName}
            placeholder="Dê um nome para a sua playlist"/>
          {journeyIsFetching ? <LoaderButton/>
            : <Button children="Criar playlist" onClick={(e) => {
              handleJourneyCreation(e)
            }}/>}
        </div>
      </div>
      {errorName && <GreyPopUp text={"Preencha o nome da playlist"} onClick={() => setErrorName(false)}/>}
    </section>
  );
};

export default CreatePlaylist;
