import {baseUrl, buildHeaders, getResponseOrThrow} from "./common";

export const apiSignUp = (payload) => {
  return fetch(`${baseUrl}/user/user`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json);
    })
}
