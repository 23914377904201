import {ADD_CONTENT_TO_LIST, EMPTY_LIST_LEARNING_CONTENT, REMOVE_CONTENT_TO_LIST} from "../constants/learningList";

export const learningList = (
  state = {
    data: { items: [] },
    list: [],
  }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case ADD_CONTENT_TO_LIST:
      newState.list.push(action.data);
      return newState;

    case REMOVE_CONTENT_TO_LIST:
      newState.list.splice(action.data, 1);
      return newState;

    case EMPTY_LIST_LEARNING_CONTENT:
      newState.list = [];
      return newState;

    default:
      return newState;
  }
};
