import React from 'react';
import './ArrowButton.scss';

const ArrowButton = ({text, onClick}) => {
  return (
    <button className="ArrowButton" onClick={onClick}>
      {text + " →"}
    </button>
  );
};

export default ArrowButton;
