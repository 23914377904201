import React, { useEffect, useRef, useState } from 'react';
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useAuth from '../../../hooks/useAuth';
import { addFollowTag, getCheckFollowTag, getTagsRequest, getUnfollowTag } from "../../../redux/actions/tags";
import { getUserInfo } from "../../../redux/actions/user";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import Button from "../../global/Button/Button";
import ContentCard from "../../global/ContentCard/ContentCard";
import LoaderButton from "../../global/LoaderButton/LoaderButton";
import CardSlide from "../../home/SlideCards/CardSlide/CardSlide";
import AdvancedSearchArea from "../AdvancedSearchArea/AdvancedSearchArea";
import "./SearchResultList.scss";

const SearchResultList = ({
  contents,
  mediaType,
  setMediaType,
  origin,
  setOrigin,
  author,
  setAuthor,
  platform,
  setPlatform,
  fromState,
  setFromState,
  toState,
  setToState,
  communicationVehicle,
  setCommunicationVehicle,
  tags,
  setPageNo,
  pageNo,
  totalPages,
  contentSearch,
  isFetching,
  isEmptyContent
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const infoTag = useSelector(state => state.tags?.data);
  const isCheckFollowTag = useSelector(state => state.checkFollowTag?.data);
  const followIsFetching = useSelector(state => state.followTag?.isFetching);
  const isLoggedIn = useAuth();
  const [openNotFoundPopUp, setOpenNotFoundPopUp] = useState(isEmptyContent);
  const [popUpFollowTagError, setPopUpFollowTagError] = useState(false);
  const observer = useRef();
  const endOfPageRef = useRef(null);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCheckFollowTag(tags));
  }, [tags, dispatch]);

  useEffect(() => {
    if (isFetching || pageNo >= totalPages) return;

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !history.isFetching) {
        setPageNo((prevPage) => prevPage + 1);
      }
    });


    if (endOfPageRef.current) {
      observer.current.observe(endOfPageRef.current);
    }
  }, [isFetching, history?.isFetching, contents]);

  const handleFollowTag = () => {
    dispatch(addFollowTag(tags))
      .then(() => {
        dispatch(getCheckFollowTag(tags));
        dispatch(getTagsRequest());
      })
      .catch(() => {
        setPopUpFollowTagError(true);
      });
  }

  const handleUnfollowTag = () => {
    dispatch(getUnfollowTag(tags))
      .then(() => {
        dispatch(getCheckFollowTag(tags));
        dispatch(getTagsRequest());
      })
      .catch(() => {
        setPopUpFollowTagError(true);
      });
  }

  const renderHomeTag = () => {
    const resultTag = infoTag?.find(item => item.id === tags);

    if (tags?.length === 1 && resultTag) {
      return (
        <div className="home-tag">
          <div className="follow-tag">
            <p>{resultTag.name}</p>
            {isCheckFollowTag.status === 204 ? (followIsFetching && <LoaderButton twoLoader />) ||
              <Button style={{ border: "none", backgroundColor: "#CFEC59" }}
                children="Seguindo" onClick={handleUnfollowTag} />
              : (followIsFetching && <LoaderButton />) || <Button children="Seguir" onClick={() => {
                isLoggedIn ? handleFollowTag() : history.push("/login")
              }} />}
          </div>
          <div className="references-tag">
            <p><strong>{resultTag.followerCount}</strong> seguidores</p>
            <p><strong>{resultTag.referenceCount}</strong> indexações</p>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="SearchResultList" style={{ paddingTop: renderHomeTag() && "138px" }}>
      <Helmet title="Pesquisa - Alexandria" />
      <AdvancedSearchArea
        contents={contents}
        contentSearch={contentSearch}
        mediaType={mediaType}
        setMediaType={setMediaType}
        origin={origin}
        setOrigin={setOrigin}
        author={author}
        setAuthor={setAuthor}
        platform={platform}
        setPlatform={setPlatform}
        fromState={fromState}
        setFromState={setFromState}
        toState={toState}
        setToState={setToState}
        communicationVehicle={communicationVehicle}
        setCommunicationVehicle={setCommunicationVehicle}
        setPageNo={setPageNo}
      />
      <div className="container-result">
        {renderHomeTag()}
        <div className={`cards-search-list 
          ${isFetching ? "fetching" : ""}
          ${renderHomeTag() ? "render-tag" : ""}`}>
          {contents && contents.map((item, key) => (
            <React.Fragment key={key}>
              <CardSlide item={item} />
              <ContentCard item={item} />
            </React.Fragment>
          ))}
          {isEmptyContent &&
            <p>Ops! Parece que não encontramos o que você procura.</p>
          }
          {isFetching && [...Array(12)].map((_, index) => <div key={index} className="fetching-box" />)
          }
          <div ref={endOfPageRef} />
        </div>
      </div>

      {popUpFollowTagError && <BluePopUp onClick={() => setPopUpFollowTagError(false)} />}
      {isEmptyContent && openNotFoundPopUp && (
        <BluePopUp
          text="Ops! Parece que não encontramos o que você procura."
          onClick={() => setOpenNotFoundPopUp(false)}
        />
      )}
    </div>
  );
};

export default SearchResultList;
