import { getHistoryRequest, getHistoryRequestFailed, getHistoryRequestSucceeded } from "../action-creators/history";
import { apiGetHistory } from "../api/history";

const getHistoryList = (filters) => dispatch => {
  const userID = localStorage.getItem('USER_ID');

  dispatch(getHistoryRequest());
  return apiGetHistory(filters, userID)
    .then(json => {
      dispatch(getHistoryRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(getHistoryRequestFailed(response));
      return Promise.reject(response);
    }
    );
};

export const getHistory = filters => dispatch => dispatch(getHistoryList(filters));
