import React, { useState } from 'react';
import { getItemFromStorage } from '../../../helper/storage';
import Entry from '../../entry/Entry/Entry';
import Button from '../../global/Button/Button';
import ContentCard from '../../global/ContentCard/ContentCard';
import './LearningOfTheDay.scss';

const LearningOfTheDay = ({ amount, items, isFetching, text, marginTop }) => {
  const getToken = getItemFromStorage("ALEXANDRIA_AUTH_TOKEN");
  const [activeLogin, setActiveLogin] = useState();
  let amountFetching = [];

  const renderIsFetching = (key) => {
    return ((isFetching && items?.length <= 0) && <div key={key} className={`${isFetching ? "fetching-box" : ""}`} />)
  }

  for (let i = 0; i < amount; i++) {
    amountFetching.push(i)
  }

  return (
    <>
      <div className='LearningOfTheDay' style={{ marginTop: marginTop }}>
        <div className='title-learning'>
          {text && <p className='text-slide-cards'>{text}</p>}
        </div>
        <div className={`cards-list ${isFetching ? "fetching" : ""} ${!getToken && 'get-loggin'}`}>
          {amountFetching.map(renderIsFetching)}
          {items && [...items].splice(0, amount).map((item, key, id) => {
            return <ContentCard isFetching={isFetching} item={item} id={id} key={key} />
          })}
          {!getToken && !isFetching && <div className='modal'>
            <div className='container-modal'>
              <div>
                <p>Receba todos os dias conteúdos personalizados</p>
                <p> de acordo com os seus interesses de aprendizagem.</p>
              </div>
              <Button onClick={() => setActiveLogin(true)} children="Fazer Login" />
              <p><strong>É GRATUITO PARA SEMPRE. SEM ANÚNCIOS.</strong></p>
            </div>
          </div>}
        </div>
      </div>
      {activeLogin && <Entry openDesktop={activeLogin} setOpenDesktop={setActiveLogin} />}
    </>
  );
}

export default LearningOfTheDay;
