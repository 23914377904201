import {
  recommendationRequest,
  recommendationRequestFailed,
  recommendationRequestSucceeded
} from "../action-creators/recommendation";
import {apiRecommendation} from "../api/recommendation";

const createRecommendationRequest = (filters) => dispatch => {
  dispatch(recommendationRequest());

  return apiRecommendation(filters)
    .then((json) => {
      dispatch(recommendationRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(recommendationRequestFailed(error));
      return Promise.reject(error);
    })
};

export const getRecommendation = (filters) => dispatch => dispatch(createRecommendationRequest(filters));
