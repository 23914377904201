import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow} from "./common";

export const apiCreateComment = (payload, contentId) => {
  return authorizedFetch(`${baseUrl}/content/content/${contentId}/collaboration`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)}
    )
};

export const apiGetCommentList = (userID) => {
  return authorizedFetch(`${baseUrl}/content/user/${userID}/collaborations`, {
    method: "GET",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
        return Promise.reject(json)
      }
    )
};

export const apiDeleteComment = (userID, collaborationID) => {
  return authorizedFetch(`${baseUrl}/content/user/${userID}/collaboration/${collaborationID}`, {
    method: "DELETE",
    headers: buildHeaders(),
  }).then(json => {
    return Promise.resolve(json);
  }).catch(json => {
    return Promise.reject(json)}
  )
};
