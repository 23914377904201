import {
  addSeeLaterRequest,
  addSeeLaterRequestFailed,
  addSeeLaterRequestSucceeded,
  deleteSeeLaterRequest,
  deleteSeeLaterRequestFailed,
  deleteSeeLaterRequestSucceeded,
  getSeeLaterRequest,
  getSeeLaterRequestFailed,
  getSeeLaterRequestSucceeded
} from "../action-creators/seeLater";
import { apiAddSeeLater, apiDeleteSeeLater, apiGetSeeLaterList } from "../api/seeLater";

const addContentInSeeLater = (contentId, payload) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(addSeeLaterRequest());
  return apiAddSeeLater(userID, contentId, payload)
    .then(json => {
      dispatch(addSeeLaterRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(addSeeLaterRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const addSeeLater = (contentId, payload) => dispatch => dispatch(addContentInSeeLater(contentId, payload));

const getSeeLaterList = (filters) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(getSeeLaterRequest());
  return apiGetSeeLaterList(userID, filters)
    .then(json => {
      dispatch(getSeeLaterRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(getSeeLaterRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const infoSeeLaterList = (filters) => dispatch => dispatch(getSeeLaterList(filters));

const deleteSeeLater = (contentId) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(deleteSeeLaterRequest());
  return apiDeleteSeeLater(userID, contentId)
    .then(json => {
      dispatch(deleteSeeLaterRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(deleteSeeLaterRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const deleteContentSeeLater = (contentId) => dispatch => dispatch(deleteSeeLater(contentId));
