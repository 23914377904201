import {
  GET_CONTENT_REQUEST,
  GET_CONTENT_REQUEST_FAILED,
  GET_CONTENT_REQUEST_SUCCEEDED,
  CREATE_CONTENT_REQUEST,
  CREATE_CONTENT_REQUEST_SUCCEEDED,
  CREATE_CONTENT_REQUEST_FAILED,
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_REQUEST_FAILED,
  DELETE_CONTENT_REQUEST_SUCCEEDED,
  UPDATE_CONTENT_REQUEST_FAILED,
  UPDATE_CONTENT_REQUEST_SUCCEEDED, UPDATE_CONTENT_REQUEST
} from "../constants/content";

export const content = (
  state = {
    isFetching: false,
    data: {},
    error: {},
    created: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case GET_CONTENT_REQUEST:
    case CREATE_CONTENT_REQUEST:
    case DELETE_CONTENT_REQUEST:
    case UPDATE_CONTENT_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_CONTENT_REQUEST_SUCCEEDED:
    case UPDATE_CONTENT_REQUEST_SUCCEEDED:
    case DELETE_CONTENT_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_CONTENT_REQUEST_FAILED:
    case DELETE_CONTENT_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    case CREATE_CONTENT_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.created = action.data;
      newState.error = {};
      return newState;

    case CREATE_CONTENT_REQUEST_FAILED:
    case UPDATE_CONTENT_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.created = {};
      return newState;

    default:
      return newState;
  }
};
