import React from 'react';
import PropTypes from 'prop-types';
import ShareIcon from "../../../assets/images/share-icon.svg";

const MobileShare = ({contentTitle}) => {
  const handleOnClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${contentTitle} | Alexandria`,
          text: `Visite ${contentTitle} em Alexandria`,
          url: document.location.href,
        })
        .then(() => {})
        .catch(() => {});
    }
  };

  return (
    <>
      <button onClick={handleOnClick} style={{backgroundImage: `url(${ShareIcon})`}}/>
    </>
  );
};

MobileShare.propTypes = {
  contentTitle: PropTypes.string.isRequired,
};

export default MobileShare;
