import React from 'react';
import "./LargeMobileCard.scss";
import Tag from "../searchArea/Tag/Tag";
import womanProfile from "../../../assets/images/woman-profile.svg";
import logoLune from "../../../assets/images/card-lune.png";
import {useHistory} from "react-router-dom";
import {slugify} from "../../../redux/api/slugify";

const LargeMobileCard = ({items, isFetching, isLogged}) => {
  const history = useHistory();
  const slug = slugify(items?.[0]?.title);
  
  const renderIsFetching = (key) => {
    return (isFetching && <div key={key} className={`${isFetching ? "fetching-card-large" : ""}`}/>)
  }

  return (
    <section className="LargeMobileCard">
      {isLogged ? <p style={{marginTop: "102px"}}>APRENDIZADOS DO <strong>DIA</strong></p> :
        <p>APRENDA PELA LENTE DE OUTRAS <strong>PESSOAS</strong></p>}
      {renderIsFetching()}
      {!isFetching && <div className="card-large" onClick={() => {
        window.scrollTo(0, 0)
        history.push(`/conteudo/${items[0]?.id}/${slug}`)
      }}>
        <img style={{backgroundColor: `${items?.[0]?.platform === "LUNE" && "black"}`}}
             src={items?.[0]?.platform === "LUNE" ? logoLune : items?.[0]?.previewImage} alt="imagem"/>
        <div className="info-card-large">
          <div className="user-card-large">
            <img
              src={items?.[0]?.author?.photo === undefined || items?.[0]?.author?.photo === null ? womanProfile : items?.[0]?.author?.photo}
              alt="imagem de perfil"/>
            <p>pela lente de <strong>{items?.[0]?.author?.username}</strong></p>
          </div>
          <div className="title-card-large">
            <p>{items?.[0]?.title}</p>
            <p>{items?.[0]?.shortDescription}</p>
          </div>
          <div className="bottom-card-large">
            <div className="tags-card-large">
              {items?.[0] && [...items?.[0]?.tags].splice(0, 2).map((item, key) => {
                return <Tag item={item.name} key={key}/>
              })}
            </div>
            <div className="buttons-card-large">
              <button/>
              <button/>
            </div>
          </div>
        </div>
      </div>}
    </section>
  );
};

export default LargeMobileCard;
