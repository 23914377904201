import React from "react";
import "./Footer.scss";
import { ReactComponent as FacebookIcon } from "../../../assets/images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/images/instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/images/twitter-icon.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="social-media">
            <a href="https://www.facebook.com/mastertech.tech" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
            <a href="https://twitter.com/mastertech_tech?lang=en" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a>
            <a href="https://www.instagram.com/mastertech.tech/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
          </div>
        </div>
        <div className="footer-center">
          <p> Alexandria </p>
        </div>
        <div className="footer-right">
          <p> Todos os direitos reservados. </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
