import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTagsRequest } from '../redux/actions/tags';

const useTags = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.tags?.data);

  useEffect(() => {
    const storedTags = localStorage.getItem('tags');

    const fetchTags = async () => {
      const response = await dispatch(getTagsRequest());
      localStorage.setItem('tags', JSON.stringify(response));
    };

    if (storedTags) {
      dispatch({ type: 'SET_TAGS', payload: JSON.parse(storedTags) });
    } else {
      fetchTags();
    }

    const interval = setInterval(() => {
      fetchTags();
    }, 300000); // 300000 ms = 5 minutes

    return () => clearInterval(interval);
  }, [dispatch]);

  return tags;
};

export default useTags;
