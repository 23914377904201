import React from 'react';
import "./LoginSuccessMessage.scss"
import {ReactComponent as GreenButton} from "../../../../assets/images/success-green-button.svg"

const LoginSuccessMessage = ({text}) => {
  return (
    <div className="LoginSuccessMessage">
      <div className="messageContent">
        <GreenButton/>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default LoginSuccessMessage;
