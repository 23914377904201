import React from 'react';
import "./EditPlaylistModal.scss";
import {useDispatch} from "react-redux";
import {deleteJourney, infoJourneyList} from "../../../../redux/actions/journey";

const EditPlaylistModal = ({editPlaylistModal, setEditPlaylistModal, setDeletePopUpOpen,
                             setDeleteMessage, id}) => {
  const dispatch = useDispatch();

  const handleDelete = (e) => {
    e.preventDefault()
    dispatch(deleteJourney(id))
      .then(() => {
        setDeletePopUpOpen(true)
        setDeleteMessage("Playlist excluida com sucesso!")
        setEditPlaylistModal(false)
        dispatch(infoJourneyList())
      })
      .catch(() => {
        setDeleteMessage("Houve um erro inesperado, tente novamente mais tarde.")
        setEditPlaylistModal(false)
      });
  }

  return (
    <div style={{
      visibility: `${editPlaylistModal ? "visible" : "hidden"}`,
      opacity: `${editPlaylistModal ? 1 : 0}`
    }}
      className="EditPlaylistModal">
      <button onClick={(e) => handleDelete(e)}>Excluir playlist</button>
      <button onClick={() => setEditPlaylistModal(false)}>Cancelar</button>
    </div>
  );
};

export default EditPlaylistModal;
