export const saveItemInStorage = (key, value, isLocalStorage) => {
  try {
    if (isLocalStorage) {
      key = typeof key === "string" ? key : JSON.stringify(key);

      window.localStorage.setItem(key, value);
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, value);
      window.localStorage.removeItem(key);

    }
  } catch (error) {
    throw error;
  }
}

export const getItemFromStorage = key => {
  try {
    return window.localStorage.getItem(key) || window.sessionStorage.getItem(key);
  } catch (error) {
    throw error;
  }
};
