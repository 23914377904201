import {
  CONTENTS_REQUEST,
  CONTENTS_REQUEST_FAILED,
  CONTENTS_REQUEST_SUCCEEDED,
  GET_USER_CONTENTS_REQUEST,
  GET_USER_CONTENTS_REQUEST_FAILED,
  GET_USER_CONTENTS_REQUEST_SUCCEEDED
} from "../constants/contents";

export const contents = (
  state = {
    isFetching: false,
    data: {items: []},
    error: {},
    myContents: {},
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case CONTENTS_REQUEST:
    case GET_USER_CONTENTS_REQUEST:
      newState.isFetching = true;
      return newState;

    case CONTENTS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_USER_CONTENTS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.myContents = action.data;
      newState.error = {};
      return newState;

    case CONTENTS_REQUEST_FAILED:
    case GET_USER_CONTENTS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
