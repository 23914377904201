import React, {useEffect} from 'react';
import './UserContentFetching.scss';

const UserContentFetching = () => {
  const listTags = []

  const renderIsFetching = () => {
    return (<div className="tag-ft"/>)
  }

  for (let i = 0; i < 4; i++) {
    listTags.push(i)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="UserContentFetching">
      <div className="content-ft-container">
        <div className="content-ft-title"/>
        <div className="content-ft-author"/>
      </div>
      <div className="all-ft-content"/>
      <div className="tags-ft-content">
        {listTags.map(renderIsFetching)}
      </div>
    </div>
  );
};

export default UserContentFetching;
