import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowUp } from '../../../../assets/images/chat/arrow-up.svg';
import iconBlue from '../../../../assets/images/chat/icon-blue.svg';
import iconWhite from '../../../../assets/images/chat/icon-white.svg';
import './ChatButton.scss';

const ChatButton = ({ onClick, chatStarted }) => {
  const [blink, setBlink] = useState(false);
  useEffect(() => {
    if (!chatStarted) {
      const interval = setInterval(() => {
        setBlink(prev => !prev);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [chatStarted]);

  return (
    <div className={`chat-button ${blink && !chatStarted ? 'blink' : ''} ${chatStarted ? 'expanded' : ''}`} onClick={onClick}>
      {!chatStarted && <img src={iconWhite} alt="Ally" />}
      {!chatStarted && <div className="orange-dot"></div>}

      {chatStarted && <div className='container-infos'>
        <div className='image-container'>
          <img src={iconBlue} alt="Ally" />
        </div>

        <div className="info-text">
          <p>Ally</p>
          <span>Online</span>
        </div>
      </div>
      }
      {chatStarted && <span className="arrow"><ArrowUp /></span>}
    </div>
  );
};

export default ChatButton;
