import {
  CONTENTS_REQUEST,
  CONTENTS_REQUEST_FAILED,
  CONTENTS_REQUEST_SUCCEEDED,
  GET_USER_CONTENTS_REQUEST,
  GET_USER_CONTENTS_REQUEST_FAILED,
  GET_USER_CONTENTS_REQUEST_SUCCEEDED,
} from "../constants/contents";

export const contentsRequest = () => {
  return {
    type: CONTENTS_REQUEST
  }
};

export const contentsRequestSucceeded = (json) => {
  return {
    type: CONTENTS_REQUEST_SUCCEEDED,
    data: json
  }
};

export const contentsRequestFailed = (error) => {
  return {
    type: CONTENTS_REQUEST_FAILED,
    error: error
  }
};

export const userContentsRequest = () => {
  return {
    type: GET_USER_CONTENTS_REQUEST
  }
};

export const userContentsRequestSucceeded = (payload) => {
  return {
    type: GET_USER_CONTENTS_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const userContentsRequestFailed = (json) => {
  return {
    type: GET_USER_CONTENTS_REQUEST_FAILED,
    error: json
  }
};
