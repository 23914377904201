import React from 'react';
import './ContentSpotify.scss';

const ContentSpotify = ({spotifyURL, referencesSpotify}) => {
  return (
    <section className="ContentSpotify">
      {spotifyURL && (
        <iframe title="playlist" src={spotifyURL} frameBorder="0" allowTransparency="true"
        allow="encrypted-media"/>
      )}
    </section>
  );
};

export default ContentSpotify;
