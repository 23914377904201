import React from 'react';
import "./ContentTagList.scss";
import Tag from "../../home/searchArea/Tag/Tag";
import {useHistory} from "react-router-dom";

const ContentTagList = ({tags}) => {
  const history = useHistory();

  return (
    <div className="ContentTagList">
      {tags && tags.map((item, key) => {
        return <Tag item={item.name} key={key} onClick={() => {
          history.push('/pesquisa?tags=' + item.id)
        }} />
      })}
    </div>
  );
};

export default ContentTagList;
