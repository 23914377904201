import {
  GET_HISTORY_REQUEST,
  GET_HISTORY_REQUEST_FAILED,
  GET_HISTORY_REQUEST_SUCCEEDED
} from "../constants/history";

export const history = (
  state = {
    isFetching: false,
    data: {},
    error: {},
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case GET_HISTORY_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_HISTORY_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_HISTORY_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
