import React, {useState} from 'react';
import './ModalUpdatePhoto.scss';
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../global/Button/Button";
import {getUserInfo, uploadPhoto} from "../../../../redux/actions/user";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import LoaderButton from "../../../global/LoaderButton/LoaderButton";

const ModalUpdatePhoto = ({setOpenUpdatePhoto}) => {
  const dispatch = useDispatch();
  const uploadFetching = useSelector(state => state.photo?.isFetching);
  const [error, setError] = useState(false);
  const [imageFilePath, setImageFilePath] = useState("");

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    return validTypes.indexOf(file.type) !== -1;
  }

  const handleFileUpload = (e) => {
    const file = (e.target && e.target.files[0]) || e;
    if (validateFile(file)) {
      setError(false);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const payload = {
        file: file,
      }
      dispatch(uploadPhoto(payload))
        .then(() => {
          dispatch(getUserInfo())
          setOpenUpdatePhoto(false)
        });
    }
    else {
      setImageFilePath("")
      setError(true);
    }
  };


  return (
    <div className="ModalUpdatePhoto">
      <div className="container-update-popup">
        <div className="header-update-popup">
          <button className="exit-container-update" onClick={() => setOpenUpdatePhoto(false)}/>
        </div>
        <div className="body-update-popup">
          <h2>Adicionar foto de perfil</h2>
          {imageFilePath && <img src={imageFilePath} alt="Imagem indicativa para atualizar imagem de perfil"/>}
          {uploadFetching ? <LoaderButton/> : <input className="UploadButton" accept="image/*" type="file" onChange={handleFileUpload}/>}
          <Button children="Remover foto atual" />
        </div>
      </div>
      {error && <BluePopUp onClick={() => setError(false)}/>}
    </div>
  );
};

export default ModalUpdatePhoto;
