import React from 'react';
import "./IsFetchingIcon.scss";

const IsFetchingIcon = () => {
  return (
    <div className="IsFetchingIcon">
      <div/>
    </div>
  );
};

export default IsFetchingIcon;
