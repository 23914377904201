import React from "react";
import "./CheckboxSearch.scss";
import PropTypes from "prop-types";

const CheckboxSearch = ({ label, value, updateState, checked }) => {
  const handleCheckboxSearch = event => {
    updateState(event.target.checked)
  };

  return (
    <label className="CheckboxSearch">
      <input value={value} onChange={(e) => handleCheckboxSearch(e)} type="checkbox" checked={checked} />
      <span className="checkmark" />
      {label}
    </label>
  );
};

CheckboxSearch.propTypes = {
  label: PropTypes.string,
  updateState: PropTypes.func,
  value: PropTypes.bool
};

export default CheckboxSearch;
