import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as ArrowDown } from '../../../../assets/images/chat/arrow-down.svg';
import iconBlue from '../../../../assets/images/chat/icon-blue.svg';
import { ReactComponent as SendIcon } from '../../../../assets/images/chat/send-icon.svg';
import { apiChatAI } from '../../../../redux/api/chat';
import { slugify } from '../../../../redux/api/slugify';
import { ChatContext } from '../ChatProvider/ChatProvider';
import './ChatModal.scss';

const ChatModal = ({ onClose }) => {
  const { messages, setMessages, historyCache, setHistoryCache } = useContext(ChatContext);
  const [currentTime, setCurrentTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const now = new Date();
    const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    setCurrentTime(formattedTime);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, loading]);

  const handleSendMessage = async () => {
    if (inputValue.trim() === '') return;

    const newMessage = { text: inputValue, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInputValue('');
    setLoading(true);

    try {
      const response = await apiChatAI({ message: inputValue, history: historyCache });
      setHistoryCache(response.history);
      setMessages(prevMessages => [
        ...prevMessages,
        { text: response.message, sender: 'bot', references: response.references }
      ]);
    } catch (error) {
      console.error('Erro ao buscar dados da API', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chat-modal">
      <div className="chat-header">
        <div className="chat-title">
          <div className="container-image">
            <img src={iconBlue} alt="Ally" />
          </div>
          <div className="info-text">
            <p>Ally</p>
            <span>{loading ? 'digitando...' : 'online'}</span>
          </div>
        </div>
        <button onClick={onClose}><ArrowDown /></button>
      </div>
      <div className="chat-content">
        <div className="hour">
          {currentTime}
        </div>
        {messages.map((message, index) => (
          <>
            <div key={index} className={`chat-message chat-message--${message.sender}`}>
              {message.references && (
                <div className="references-icon">
                  <p>✨</p>
                  <div className="tooltip">
                    Referências disponíveis
                  </div>
                </div>
              )}
              {message.text}
            </div>
            {
              message.references && (
                <div className="references-container">
                  <div className="references-icon">
                    <p>✨</p>
                    <div className="tooltip">
                      Referências disponíveis
                    </div>
                  </div>
                  <div className="references">
                    <p>Referências</p>
                    <ul>
                      {Object.values(message.references).map((ref, refIndex) => (
                        <li key={refIndex}>
                          <Link
                            to={`/conteudo/${ref?.content_id}/${slugify(ref?.title)}`}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {ref.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )
            }
          </>
        ))}
        {loading && (
          <div className="chat-message chat-message--loading">
            <div className="loading-dots">
              <span></span><span></span><span></span>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <input
          disabled={loading}
          type="text"
          placeholder="Digite para responder..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && e.shiftKey) {
              setInputValue(inputValue + '\n');
            } else if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
        />
        <button onClick={handleSendMessage}><SendIcon /></button>
      </div>
    </div >
  );
};

export default ChatModal;