import React from 'react';
import "./PopUp.scss";

const PopUp = ({children}) => {
  return (
    <div className="PopUp">
      <div className="content-container">
        {children}
      </div>
    </div>
  );
};

export default PopUp;
