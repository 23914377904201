import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItemFromStorage } from '../helper/storage';
import { getUserInfo } from '../redux/actions/user';

const useUserInfo = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = getItemFromStorage('ALEXANDRIA_AUTH_TOKEN');
    const storedUserInfo = localStorage.getItem('userInfo');

    if (token) {
      setIsLoggedIn(true);
      if (!storedUserInfo) {
        dispatch(getUserInfo()).then((userInfo) => {
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
        });
      } else {
        dispatch({ type: 'SET_USER_INFO', payload: JSON.parse(storedUserInfo) });
      }
    } else {
      setIsLoggedIn(false);
      localStorage.removeItem('userInfo');
    }
  }, [dispatch]);

  return { userInfo, isLoggedIn };
};

export default useUserInfo;
