import React from 'react';
import "./HistoryFilter.scss";
import DatePickerSearch from "../../../searchResult/DatePickerSearch/DatePickerSearch";
import Checkbox from "../../../global/Checkbox/Checkbox";

const HistoryFilter = ({
                         fromState,
                         setFromState,
                         toState,
                         setToState,
                       }) => {
  let fromDateDiff = Math.floor((new Date() - fromState) / (1000 * 60 * 60 * 24));
  let toDateDiff = Math.floor((new Date() - toState) / (1000 * 60 * 60 * 24));

  const updateDatesWithDiff = (diff) => {
    let diffDays = new Date();
    diffDays.setDate(diffDays.getDate() - diff);
    setFromState(diffDays);
    setToState(new Date());
  }

  return (
    <div className="HistoryFilter">
      <h2>Buscar</h2>
      <p>Busca por data</p>
      <DatePickerSearch
        fromState={fromState}
        setFromState={setFromState}
        toState={toState}
        setToState={setToState}
      />
      <div className="checkbox-days-history">
        <Checkbox
          checked={toDateDiff === 0 && fromDateDiff === 0}
          updateState={() => {
            updateDatesWithDiff(0)
          }}
          label="Hoje"/>
        <Checkbox
          checked={toDateDiff === 0 && fromDateDiff === 1}
          updateState={() => {
            updateDatesWithDiff(1)
          }}
          label="Ontem"/>
        <Checkbox
          checked={toDateDiff === 0 && fromDateDiff === 7}
          updateState={() => {
            updateDatesWithDiff(7)
          }}
          label="7 dias"/>
        <Checkbox
          checked={toDateDiff === 0 && fromDateDiff === 15}
          updateState={() => {
            updateDatesWithDiff(15)
          }}
          label="15 dias"/>
        <Checkbox
          checked={toDateDiff === 0 && fromDateDiff === 30}
          updateState={() => {
            updateDatesWithDiff(30)
          }}
          label="30 dias"/>

      </div>
    </div>
  );
};

export default HistoryFilter;
