import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";

export const apiCreateJourney = (payload, userID) => {
  return authorizedFetch(`${baseUrl}/content/user/${userID}/playlist`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiGetJourneyList = (filters) => {
  const userID = localStorage.getItem('USER_ID');
  return authorizedFetch(urlFilters(`${baseUrl}/content/user/${userID}/playlists`, filters), {
    method: "GET",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiAddContentInJourney = (journeyId, contentId, userID) => {
  return authorizedFetch(`${baseUrl}/content/user/${userID}/playlist/${journeyId}/content/${contentId}`, {
    method: "PUT",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiGetJourney = (playlistId) => {
  const userID = localStorage.getItem('USER_ID');
  return authorizedFetch(`${baseUrl}/content/user/${userID}/playlist/${playlistId}`, {
    method: "GET",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiDeleteJourneyList = (userID, playlistId) => {
  return authorizedFetch(`${baseUrl}/content/user/${userID}/playlist/${playlistId}`, {
    method: "DELETE",
    headers: buildHeaders(),
  }).then(json => {
    return Promise.resolve(json);
  }).catch(json => {
    return Promise.reject(json)
  }
  )
};

export const apiUpdateJourney = (userID, token, playlistId, payload) => {
  return fetch(`${baseUrl}/content/user/${userID}/playlist/${playlistId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};


export const apiShareJourney = (playlistId) => {
  return fetch(`${baseUrl}/content/playlist/${playlistId}`, {
    method: "GET",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};
