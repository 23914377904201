import React, { useEffect, useState } from "react";
import "./ContentText.scss";
import ExternalContent from "./ExternalContent/ExternalContent";
import ContentAudio from "../ContentAudio/ContentAudio";
import ContentVideo from "../ContentVideo/ContentVideo";
import ContentSpotify from "../ContentSpotify/ContentSpotify";
import Button from "../../global/Button/Button";
import Loader from "../../global/Loader/Loader";
import { Link } from "react-router-dom";
import { canLoadFrame } from "../../../hooks/canLoadFrame";
import { useWindowSizeHook } from "../../../hooks/WindowHooks";

const ContentText = ({ content }) => {
  const [data, setData] = useState();
  const { width } = useWindowSizeHook();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (content?.content?.origin_url) {
      canLoadFrame(content?.content?.origin_url, setData, setLoading);
    }
    setLoading(false);
  }, [content?.content?.origin_url]);

  const renderExternal = () => {
    if (width > 1024) {
      return data?.can ? (
        <div className="url-container">
          <ExternalContent url={content?.content?.origin_url} />
          <p>
            Link original:{" "}
            <Link
              to={{ pathname: content?.content?.origin_url }}
              target="_blank"
            >
              {content?.content?.origin_url}
            </Link>
          </p>
        </div>
      ) : (
        <div className="button-container">
          <Link to={{ pathname: content?.content?.origin_url }} target="_blank">
            <Button children="Link do conteúdo" />
          </Link>
        </div>
      );
    } else {
      return (
        <div className="button-container">
          <Link to={{ pathname: content?.content?.origin_url }} target="_blank">
            <Button children="Link do conteúdo" />
          </Link>
        </div>
      );
    }
  };

  return (
    <div className="ContentText">
      {loading && <Loader />}
      {content?.content?.spotify_playlist_url && (
        <ContentSpotify spotifyURL={content?.content?.spotify_playlist_url} />
      )}
      {content?.content?.anchor_redirect_url && (
        <a
          className="anchor-button"
          target="_blank"
          rel="noreferrer"
          href={content?.content?.anchor_redirect_url}
        >
          <button />
        </a>
      )}
      {content?.content?.references && (
        <div className="references-link">
          <p>Referências:</p>
          {content?.content?.references?.map((item, index) => (
            <div>
              <a id={index} target="_blank" rel="noreferrer" href={item.link}>
                {item.name.split(":")}
              </a>
            </div>
          ))}
        </div>
      )}
      {content?.content?.video_url && (
        <ContentVideo videoURL={content?.content?.video_url} />
      )}
      {content?.platform === "LUNE" && (
        <ContentAudio audioURL={content?.content?.audio_url} />
      )}
      {content?.content?.origin_url && renderExternal()}
      {content?.content?.address && (
        <strong
          dangerouslySetInnerHTML={{ __html: content?.content?.address || "Conteúdo sem endereço/url" }}
        />
      )}
      <div
        dangerouslySetInnerHTML={{ __html: content?.content?.text || "Conteúdo sem descrição" }}
      />
    </div>
  );
};

export default ContentText;
