import React, { createContext, useState } from 'react';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([{ text: 'Olá! Como posso ajudar você hoje?', sender: 'bot' }]);
  const [historyCache, setHistoryCache] = useState();

  return (
    <ChatContext.Provider value={{ messages, setMessages, historyCache, setHistoryCache }}>
      {children}
    </ChatContext.Provider>
  );
};
