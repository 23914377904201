import { getItemFromStorage } from "../../helper/storage";
import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiAddFavorite = (userID, contentId, payload) => {
  return authorizedFetch(`${baseUrl}/user/user/${userID}/favorite/${contentId}`, {
    method: "PUT",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiGetFavoriteList = (userID) => {
  return authorizedFetch(`${baseUrl}/user/user/${userID}/favorites`, {
    method: "GET",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiDeleteFavorite = (userID, contentId) => {
  return authorizedFetch(`${baseUrl}/user/user/${userID}/favorite/${contentId}`, {
    method: "DELETE",
    headers: buildHeaders(),
  }).then(json => {
    return Promise.resolve(json);
  }).catch(json => {
    return Promise.reject(json)
  }
  )
};

export const apiGetIsFavorited = (contentId) => {
  const userId = getItemFromStorage("USER_ID");
  return authorizedFetch(`${baseUrl}/user/user/${userId}/favorite/${contentId}`, {
    method: "GET",
    headers: buildHeaders()
  }).then((response) => {
    if (response.status === 404) {
      return Promise.reject(false);
    }
    return Promise.resolve(true);
  }).catch(() => Promise.reject(false));
}

export const apiGetIsFavoritedUserList = (userId, payload) => {
  return authorizedFetch(`${baseUrl}/user/user/${userId}/favorite/exists`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: buildHeaders()
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
}
