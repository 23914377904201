import {ADD_CONTENT_TO_LIST, EMPTY_LIST_LEARNING_CONTENT, REMOVE_CONTENT_TO_LIST} from "../constants/learningList";

export const addContentToList = content => ({
  type: ADD_CONTENT_TO_LIST,
  data: content
});

export const removeContentToList = contentID => ({
  type: REMOVE_CONTENT_TO_LIST,
  data: contentID
});

export const emptyListLearningContent = () => ({
  type: EMPTY_LIST_LEARNING_CONTENT
});
