import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as AudioIcon } from "../../../assets/images/card-audio.svg";
import logoLune from "../../../assets/images/card-lune.png";
import { ReactComponent as TextIcon } from "../../../assets/images/card-text.svg";
import { ReactComponent as VideoIcon } from "../../../assets/images/card-video.svg";
import iconCollection from "../../../assets/images/save-collection.svg";
import iconSaveBackground from "../../../assets/images/save-content-background.svg";
import iconSave from "../../../assets/images/save-content.svg";
import { titlePlatforms } from "../../../helper/enumsTranslators";
import { getItemFromStorage } from "../../../helper/storage";
import { addFavorite } from "../../../redux/actions/favorite";
import { slugify } from "../../../redux/api/slugify";
import AddInCollectionPopUp from "../../contentScreen/ContentIconList/AddInCollectionPopUp/AddInCollectionPopUp";
import Login from "../../entry/Entry/Entry";
import BluePopUp from "../BluePopUp/BluePopUp";
import "./ContentCard.scss";

const ContentCard = ({ item, onDelete }) => {
  const dispatch = useDispatch();
  const slug = slugify(item && item.title);
  const [isOpen, setIsOpen] = useState(false);
  const hasVerifyLogin = getItemFromStorage("ALEXANDRIA_AUTH_TOKEN");

  const [isOpenCreateCollection, setIsOpenCreateCollection] = useState(false);
  const [hasOpenBluePopCollection, setHasOpenBluePopCollection] = useState(false);
  const [openBluePopCollectionMessage, setOpenBluePopCollectionMessage] = useState("");

  const [OpenSeeLaterPopUp, setOpenSeeLaterPopUp] = useState(false);
  const [seeLaterMessage, setSeeLaterMessage] = useState("");

  const [activeLogin, setActiveLogin] = useState(false);

  const icon = {
    TEXT: <TextIcon />,
    AUDIO: <AudioIcon />,
    VIDEO: <VideoIcon />,
  };


  const addContentInSeeLater = (id) => {
    const payload = {
      platform: "ALEXANDRIA",
    };
    dispatch(addFavorite(id, payload))
      .then(() => {
        setOpenSeeLaterPopUp(true);
        setSeeLaterMessage(`Conteúdo adicionado ao "Salvos"`);
        item.isFavoriteApresentation = true;

        setTimeout(() => {
          setOpenSeeLaterPopUp(false);
        }, 3000);
      })
      .catch(() => {
        setOpenSeeLaterPopUp(true);
        setSeeLaterMessage("");
      });
  };

  return (
    <>
      <div
        style={{
          cursor: `${item?.isDraft === true && "not-allowed"}`,
        }}
        className="ContentCard"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onClick={() => {
          if (isOpen) {
            window.location.href = `/conteudo/${item && item.id
              }/${slug}`
          } else {
            item?.isDraft !== true && setIsOpen(true)
          }
        }}
      >
        <img
          className={`image-content  ${isOpen ? "show" : ""}`}
          src={
            item?.isDraft !== true && item?.platform === "LUNE"
              ? logoLune
              : item?.isDraft !== true && item?.previewImage
          }
        />
        <div className="bg-opacity">
          <div className="card-type">
            {item?.isDraft !== true && icon[item?.mediaType]}
            {onDelete && (
              <button
                style={{
                  left: `${item?.isDraft === true || (!item?.platform && "95%")
                    }`,
                }}
                className="delete-card"
                onClick={(e) => onDelete(e, item)}
              />
            )}

            {item?.isFavoriteApresentation && <img src={iconSaveBackground} />}
          </div>
          <div className="card-infos">
            <div className={`platform-card ${isOpen ? "show" : ""}`}>
              {item?.isDraft !== false ||
                (item?.platform && (
                  <p>{item && titlePlatforms[item.platform]}</p>
                ))}
              <div className="title-card">
                {item?.isDraft !== true ? (
                  <p>{item?.title}</p>
                ) : item?.isDraft === true || !item?.platform ? (
                  <p>Conteúdo excluído</p>
                ) : (
                  <p>Conteúdo sem título</p>
                )}
              </div>
              <div className={`about-content-card ${isOpen ? "show" : ""}`}>
                <div className="text-about">
                  <p>{item?.shortDescription} </p>
                  <a
                    onClick={() =>
                    (window.location.href = `/conteudo/${item && item.id
                      }/${slug}`)
                    }
                  >
                    {item?.shortDescription?.length >= 1
                      ? "Ver mais..."
                      : "Visualizar conteúdo"}
                  </a>
                </div>
                <div className="buttons-about">
                  {!item?.isFavoriteApresentation && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        hasVerifyLogin
                          ? addContentInSeeLater(item?.id)
                          : setActiveLogin(true)
                      }
                      }
                    >
                      <img src={iconSave} />
                      Salvar
                    </button>
                  )}
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      hasVerifyLogin
                        ? setIsOpenCreateCollection(true)
                        : setActiveLogin(true)
                    }
                    }
                    className="button-blue"
                  >
                    <img src={iconCollection} />
                    Adicionar a Coleção
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenCreateCollection && (
        <AddInCollectionPopUp
          setOpenAddJourney={setIsOpenCreateCollection}
          setOpenBluePopJourney={setHasOpenBluePopCollection}
          setOpenBluePopJourneyMessage={setOpenBluePopCollectionMessage}
          contentID={item?.id}
        />
      )}
      {OpenSeeLaterPopUp && (
        <BluePopUp
          text={seeLaterMessage}
          onClick={() => setOpenSeeLaterPopUp(false)}
        />
      )}
      {hasOpenBluePopCollection && (
        <BluePopUp
          text={openBluePopCollectionMessage}
          onClick={() => {
            setHasOpenBluePopCollection(false);
          }}
        />
      )}
      {activeLogin && (
        <Login openDesktop={activeLogin} setOpenDesktop={setActiveLogin} />
      )}
    </>
  );
};

export default ContentCard;
