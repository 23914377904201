import React from 'react';
import "./LogoutUser.scss"
import {useHistory} from "react-router-dom";

const LogoutUser = ({openLogout, setOpenLogout}) => {
  const history = useHistory();

  const logoutStorage = () => {
    localStorage.clear()
    history.push("/")
  }

  return (
    <section className="LogoutUser"
         style={{
           visibility: `${openLogout ? "visible" : "hidden"}`,
           opacity: `${openLogout ? 1 : 0}`
         }}>
      <div className="logout-top">
        <div><span/></div>
        <p>Tem certeza que deseja sair do Alexandria?</p>
      </div>
      <div className="logout-buttons">
        <button onClick={() => logoutStorage()}>Sair</button>
        <button onClick={() => setOpenLogout(false)}>Fechar</button>
      </div>
    </section>
  );
};

export default LogoutUser;
