import React from 'react';
import './SearchNavbar.scss';

const SearchNavbar = ({item, onClick, active}) => {
  return (
      <button className={`SearchNavbar ${active ? "active" : ""}`} onClick={onClick}>
        {item?.name}
      </button>
  );
};

export default SearchNavbar;
