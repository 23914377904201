import React, { useEffect } from "react";
import "./UserProfileInfo.scss";
import { Link, useLocation } from "react-router-dom";
import { getUserInfo } from "../../../redux/actions/user";
import { useDispatch } from "react-redux";

const UserProfileInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  return (
    <div className="UserProfileInfo">
      <div className="select-info-profile">
        <div className="buttons-profile">
          <Link
            to="/perfil/meus-conteudos"
            className={`NavigationLink ${
              pathname === "/perfil/meus-conteudos" ? "active" : ""
            }`}
          >
            Meus conteúdos
            <span />
          </Link>
          <Link
            to="/perfil/colecao"
            className={`NavigationLink ${
              pathname === "/perfil/colecao" ? "active" : ""
            }`}
          >
            Coleções
            <span />
          </Link>
          <Link
            to="/perfil/salvos"
            className={`NavigationLink ${
              pathname === "/perfil/salvos" ? "active" : ""
            }`}
          >
            Salvos
            <span />
          </Link>
          <Link
            to="/perfil/notas"
            className={`NavigationLink ${
              pathname === "/perfil/notas" ? "active" : ""
            }`}
          >
            Comentários
            <span />
          </Link>
          <Link
            to="/perfil/historico"
            className={`NavigationLink ${
              pathname === "/perfil/historico" ? "active" : ""
            }`}
          >
            Histórico
            <span />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserProfileInfo;
