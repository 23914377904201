import {
  GET_CONTENT_REQUEST,
  GET_CONTENT_REQUEST_FAILED,
  GET_CONTENT_REQUEST_SUCCEEDED,
  CREATE_CONTENT_REQUEST,
  CREATE_CONTENT_REQUEST_FAILED,
  CREATE_CONTENT_REQUEST_SUCCEEDED,
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_REQUEST_SUCCEEDED,
  DELETE_CONTENT_REQUEST_FAILED,
  UPDATE_CONTENT_REQUEST,
  UPDATE_CONTENT_REQUEST_SUCCEEDED,
  UPDATE_CONTENT_REQUEST_FAILED,
} from "../constants/content";

export const newCreateContentRequest = () => {
  return {
    type: CREATE_CONTENT_REQUEST
  }
};

export const newCreateContentRequestSucceeded = (json) => {
  return {
    type: CREATE_CONTENT_REQUEST_SUCCEEDED,
    data: json
  }
};

export const newCreateContentRequestFailed = (error) => {
  return {
    type: CREATE_CONTENT_REQUEST_FAILED,
    error: error
  }
};

export const getContentRequest = () => {
  return {
    type: GET_CONTENT_REQUEST
  }
};

export const getContentRequestSucceeded = (json) => {
  return {
    type: GET_CONTENT_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getContentRequestFailed = (error) => {
  return {
    type: GET_CONTENT_REQUEST_FAILED,
    error: error
  }
};

export const deleteContentRequest = () => ({
  type: DELETE_CONTENT_REQUEST,
});

export const deleteContentRequestSucceeded = json => ({
  type: DELETE_CONTENT_REQUEST_SUCCEEDED,
  data: json
});

export const deleteContentRequestFailed = json => ({
  type: DELETE_CONTENT_REQUEST_FAILED,
  error: json
});

export const updateContentRequest = () => ({
  type: UPDATE_CONTENT_REQUEST,
});

export const updateContentRequestSucceeded = (json) => ({
  type: UPDATE_CONTENT_REQUEST_SUCCEEDED,
  data: json
});

export const updateContentRequestFailed = (json) => ({
  type: UPDATE_CONTENT_REQUEST_FAILED,
  error: json
});
