import React from 'react';
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="Loader">
      <div className="loading">
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
      <div className="bottom-loader"/>
    </div>
  );
};

export default Loader;
