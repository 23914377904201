import React, { useEffect, useState } from "react";
import "./UserContent.scss";
import ContentText from "../ContentText/ContentText";
import ContentTagList from "../ContentTagList/ContentTagList";
import ContentCommentList from "../ContentCommentList/ContentCommentList";
import Helmet from "react-helmet";
import womanProfile from "../../../assets/images/woman-profile.svg";
import ContentMenu from "../ContentMenu/ContentMenu";
import logoLune from "../../../assets/images/card-lune.png";
import CommentListMobile from "../CommentListMobile/CommentListMobile";
import PlaylistAdd from "../../global/PlaylistAdd/PlaylistAdd";

const UserContent = ({ content }) => {
  const [openComment, setOpenComment] = useState(false);
  const [openPlaylist, setOpenPlaylist] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="UserContent">
      <Helmet
        title={content?.title ? `${content?.title} - Alexandria` : 'Alexandria'}
      />
      <div className="title-content">
        <h1>{content?.title || "Conteúdo sem título"}</h1>
        <div className="info-user-content">
          <img
            style={{
              backgroundColor: `${content?.platform === "LUNE" && "black"}`,
            }}
            src={
              content?.author?.photo === undefined ||
              content?.author.photo === "url" ||
              content?.author?.photo === null
                ? womanProfile
                : content?.author?.photo
            }
            alt="imagem de perfil"
          />
          <p>
            pela lente de&nbsp;<strong>{content?.author?.username}</strong>
          </p>
          <p> •&nbsp; 2h atrás</p>
        </div>
        <p>por {content?.author?.username}</p>
      </div>
      <img
        className="preview-content"
        alt="Imagem do conteúdo"
        style={{
          backgroundColor: `${content?.platform === "LUNE" && "black"}`,
        }}
        src={content?.platform === "LUNE" ? logoLune : content?.previewImage}
      />
      <ContentText content={content} />
      <ContentTagList tags={content && content?.tags} />
      <ContentCommentList comments={content?.contributions} />
      <ContentMenu
        setOpenComment={setOpenComment}
        setOpenPlaylist={setOpenPlaylist}
        content={content}
        title={content?.title}
      />
      <CommentListMobile
        setOpenComment={setOpenComment}
        openComment={openComment}
        comments={content}
      />
      <PlaylistAdd
        openPlaylist={openPlaylist}
        setOpenPlaylist={setOpenPlaylist}
        content={content}
      />
    </div>
  );
};

export default UserContent;
