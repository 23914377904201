import React, { useState } from "react";
import "./SlideCards.scss";
import CardSlide from "./CardSlide/CardSlide";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AddInCollectionPopUp from "../../contentScreen/ContentIconList/AddInCollectionPopUp/AddInCollectionPopUp";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import Login from "../../entry/Entry/Entry";

const SlideCards = ({
  items,
  isLogged,
  desktop,
  text,
  marginTop,
  isFetching,
}) => {
  const [isOpenCreateCollection, setIsOpenCreateCollection] = useState(false);
  const [hasOpenBluePopCollection, setHasOpenBluePopCollection] = useState(false);
  const [openBluePopCollectionMessage, setOpenBluePopCollectionMessage] = useState("");

  const [OpenSeeLaterPopUp, setOpenSeeLaterPopUp] = useState(false);
  const [seeLaterMessage, setSeeLaterMessage] = useState("");

  const [activeLogin, setActiveLogin] = useState(false);
  const [clickedContentId, setClickedContentId] = useState("");

  let amountFetching = [];

  for (let i = 0; i < 12; i++) {
    amountFetching.push(i);
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span>➜</span>
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span>➜</span>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 100,
    scroll: true,
    variableWidth: true,
    swipeToSlide: true,
    slide: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="SlideCards" style={{ marginTop: marginTop }}>
        {desktop ? (
          <p className="text-slide-cards">{text}</p>
        ) : isLogged ? (
          <p className="text-slide-cards mobile-text-slide">
            EM ALTA HOJE NO <strong>ALEXANDRIA</strong>
          </p>
        ) : (
          <p className="text-slide-cards mobile-text-slide">
            MAIS ESTUDADOS HOJE NO <strong>ALEXANDRIA</strong>
          </p>
        )}
        <Slider {...settings}>
          {isFetching &&
            amountFetching.map(() => {
              return <div className="fetching" />;
            })}
          {Array.isArray(items)
            ? items.map((item, key) => {
                return (
                  <CardSlide
                    setActiveLogin={setActiveLogin}
                    setClickedContentId={setClickedContentId}
                    setSeeLaterMessage={setSeeLaterMessage}
                    setOpenSeeLaterPopUp={setOpenSeeLaterPopUp}
                    OpenSeeLaterPopUp={OpenSeeLaterPopUp}
                    setIsOpenCreateCollection={setIsOpenCreateCollection}
                    item={item}
                    number={key}
                    slideContain={true}
                  />
                );
              })
            : null}
        </Slider>
      </div>

      {isOpenCreateCollection && (
        <AddInCollectionPopUp
          setOpenAddJourney={setIsOpenCreateCollection}
          setOpenBluePopJourney={setHasOpenBluePopCollection}
          setOpenBluePopJourneyMessage={setOpenBluePopCollectionMessage}
          contentIdProp={clickedContentId}
        />
      )}
      {OpenSeeLaterPopUp && (
        <BluePopUp
          text={seeLaterMessage}
          onClick={() => setOpenSeeLaterPopUp(false)}
        />
      )}
      {hasOpenBluePopCollection && (
        <BluePopUp
          text={openBluePopCollectionMessage}
          onClick={() => {
            setHasOpenBluePopCollection(false);
          }}
        />
      )}
      {activeLogin && (
        <Login openDesktop={activeLogin} setOpenDesktop={setActiveLogin} />
      )}
    </>
  );
};

export default SlideCards;
