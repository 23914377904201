import React from 'react';
import "./Collection.scss";
import {useHistory} from "react-router-dom";
import {slugify} from "../../../../redux/api/slugify";
import ExtensionImage from "../../../../assets/images/bg-login.png"
import BlankProfile from "../../../../assets/images/blank-profile.png"
import iconNew from "../../../../assets/images/playlist-icon-white.svg"

const Collection = ({item}) => {
  const history = useHistory();
  const slug = slugify(item && item.name);
  
  return (
    <div className="Collection">
      <div className='collection-image' 
      style={{backgroundImage: `url(${item?.contents[0]?.previewImage ? item?.contents[0]?.previewImage : ExtensionImage})`}}>
        <div className='length-collection'>
            <p>{item?.contents.length}</p>
            <img src={iconNew}/>
        </div>
      </div>
      <p className='title-collection'>{item?.name}</p>
      <div className='profile-collection'>
        <img src={item?.author?.photo ? item?.author?.photo : BlankProfile} />
        <p>{item?.author?.username}</p>
      </div>
      <button
       onClick={() => history.push(`/editar/jornada/playlist/${item && item?.id}/${slug}/${slug}`)}
       >ver coleção completa</button>
    </div>
  );
};

export default Collection;
