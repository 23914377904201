import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters} from "./common";

export const apiGetHistory = (filters, userID) => {
  return authorizedFetch(urlFilters(`${baseUrl}/user/user/${userID}/history`, filters), {
    method: "GET",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
        return Promise.reject(json)
      }
    )
};
