import {
  GET_RELATED_REQUEST,
  GET_RELATED_REQUEST_FAILED,
  GET_RELATED_REQUEST_SUCCEEDED,
} from "../constants/related";

export const getRelatedRequest = () => {
  return {
    type: GET_RELATED_REQUEST
  }
};

export const getRelatedRequestSucceeded = (json) => {
  return {
    type: GET_RELATED_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getRelatedRequestFailed = (error) => {
  return {
    type: GET_RELATED_REQUEST_FAILED,
    error: error
  }
};
