import React, {useState} from 'react';
import './SearchTag.scss';
import {useHistory} from "react-router-dom";
import ZoomButton from '../ZoomButton/ZoomButton';

const SearchTag = ({activeTag, setActiveTag}) => {
  const history = useHistory();
  const [search, setSearch] = useState("")

  const handleActive = () => {
    setActiveTag(!activeTag);
  }

  return (
    <div className={`SearchTag ${activeTag && "active"}`}>
      <p>Busque por <strong>Palavra-chave</strong></p>
      <div className="search-input">
        <input 
          value={search} 
          onChange={e => setSearch(e.target.value)} 
          placeholder="Palavra-chave" 
          onKeyPress={
            (e) => e.key === 'Enter' && 
            search.length > 0 && 
            history.push('/pesquisa?search=' + search)
          }
        />
        <ZoomButton text="Buscar" onClick={() => {search.length > 0 && history.push('/pesquisa?search=' + search)}} />
      </div>
    </div>
  );
};

export default SearchTag;
