export const POST_COMMENT_REQUEST = "POST_COMMENT_REQUEST";
export const POST_COMMENT_REQUEST_SUCCEEDED = "POST_COMMENT_REQUEST_SUCCEEDED";
export const POST_COMMENT_REQUEST_FAILED = "POST_COMMENT_REQUEST_FAILED";

export const GET_COMMENT_REQUEST = "GET_COMMENT_REQUEST";
export const GET_COMMENT_REQUEST_SUCCEEDED = "GET_COMMENT_REQUEST_SUCCEEDED";
export const GET_COMMENT_REQUEST_FAILED = "GET_COMMENT_REQUEST_FAILED";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_REQUEST_SUCCEEDED = "DELETE_COMMENT_REQUEST_SUCCEEDED";
export const DELETE_COMMENT_REQUEST_FAILED = "DELETE_COMMENT_REQUEST_FAILED";
