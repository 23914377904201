import { getToken, hasToken, logoutUser, tokenStillValid, updateToken } from "../../helper/loginStorage";
import { getItemFromStorage } from "../../helper/storage";
import { apiRefreshLogin } from "./login";
export const baseUrl = process.env.REACT_APP_ALEXANDRIA_API;

export const buildHeaders = (authorization = "", ignoreContentType) => {
  const headers = {
    "Accept": "application/json",
  };

  if (!ignoreContentType) {
    headers["Content-Type"] = "application/json";
  }

  const auth = authorization || getItemFromStorage("ALEXANDRIA_AUTH_TOKEN");

  if (auth) {
    headers["Authorization"] = `Bearer ${auth}`
  }

  return headers;
}

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getResponseOrThrow = async response => {
  let responseText = await response.text();
  let parsedResponse = isJsonString(responseText) ? JSON.parse(responseText) : responseText;

  if (response.ok) {
    return parsedResponse;
  } else {
    throw parsedResponse;
  }
};

export const urlFilters = (url, filters) => {
  if (filters === undefined) {
    return url;
  }

  const filtersKeys = Object.keys(filters);

  filtersKeys.map(key => {
    const keyValue = filters[key];
    if (keyValue !== '') {
      let symbol = url.includes('?') ? '&' : '?';
      if (Array.isArray(keyValue)) {
        for (let value of keyValue) {
          symbol = url.includes('?') ? '&' : '?';
          url += `${symbol}${key}=${value}`
        }
      } else {
        url += `${symbol}${key}=${keyValue}`
      }
    }
    return key
  });

  return url;
}

let refreshPromise = undefined;
export const authorizedFetch = (url, init) => {
  return new Promise((resolve, reject) => {
    if (getItemFromStorage("ALEXANDRIA_AUTH_TOKEN")) {
      if (!init.headers) {
        init.headers = {};
      }
      if (!tokenStillValid()) {
        if (!refreshPromise) {
          const refreshToken = getItemFromStorage("ALEXANDRIA_REFRESH_TOKEN");
          refreshPromise = apiRefreshLogin(refreshToken);
          refreshPromise.then(data => {
            updateToken(data)
            refreshPromise = undefined;
          }).catch(() => {
            window.localStorage.clear();
            window.sessionStorage.clear();
            window.location.href = "/login";
          })
        }
        refreshPromise.then(() => {
          init.headers["Authorization"] = `Bearer ${getToken()}`
          fetch(url, init).then(resolve, reject)
        })
        return;
      }
      if (hasToken()) {
        init.headers["Authorization"] = `Bearer ${getToken()}`
      }
    }
    // fetch(url, init).then(resolve, reject)
    fetch(url, init)
      .then((response) => {
        if (!response.ok) {
          response
            .json()
            .then((errorResponse) => {
              const error = {
                status: response.status,
                message: errorResponse || response.statusText || response.message,
              };

              if (error.status === 401 || error.status === 403) {
                logoutUser();
              }
              reject(error);
            })
            .catch(() => {
              const error = {
                status: response.status,
                message: response.statusText || response.message,
              };

              if (error.status === 401 || error.status === 403) {
                logoutUser();
              }

              reject(error);
            });
          return;
        }
        resolve(response);
      })
      .catch((error) => {
        if (error.status === 401 || error.status === 403) {
          logoutUser();
        }
        reject(error);
      });
  })
};
