import React from 'react';
import "./TopComponentView.scss";
import { ReactComponent as WhiteArrow } from "../../../assets/images/white-arrow-down.svg"
import ExtensionImage from "../../../assets/images/extension-01.png"
import ArrowButton from "../../home/searchArea/ArrowButton/ArrowButton";
import {Link} from "react-router-dom";

const TopComponentView = () => {
  return (
    <div className="TopComponentView">
        <div className="topContainer">
            <div className="textBox">
                <h2>Aprenda a reindexar a internet com Alexandria.</h2>
                <h6>De todo lugar, em qualquer formato.</h6>
              <Link to={{ pathname: "https://chrome.google.com/webstore/detail/alexandria-extens%C3%A3o/ilamehpcfijmklpngcbemikbpmenhdnj?hl=en" }} target="_blank">
                <ArrowButton text="Baixe a extensão" />
              </Link>
            </div>
            <div className="imageBox">
                <img 
                  src={ExtensionImage} 
                  alt="Ilustração de uma pessoa cartunizada estudando em um notebook" 
                />
            </div>
        </div>
        <div className="arrowBox">
            <WhiteArrow/>
        </div> 
    </div>
  );
};

export default TopComponentView;