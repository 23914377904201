import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters} from "./common";

export  const apiTags = (filters) => {
  return fetch(urlFilters(`${baseUrl}/content/tags`, filters),
    {
      method: "GET",
      headers: buildHeaders()
    }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((error) => Promise.reject(error));
};

export const apiCheckFollowTag = (USER_ID, TAG_ID) => {
  return authorizedFetch(`${baseUrl}/recommendation/user/${USER_ID}/followed-tags/${TAG_ID}`, {
    method: "GET",
    headers: buildHeaders()
  }).then((json) => {
    return Promise.resolve(json)
  })
    .catch(json => {
      return Promise.reject(json)}
    )
};

export const apiFollowTag = (USER_ID, TAG_ID) => {
  return authorizedFetch(`${baseUrl}/recommendation/user/${USER_ID}/followed-tags/${TAG_ID}`, {
    method: "PUT",
    headers: buildHeaders()
  }).then(getResponseOrThrow)
    .catch(json => {
      return Promise.reject(json)}
    )
};

export const apiUnfollowTag = (USER_ID, TAG_ID) => {
  return authorizedFetch(`${baseUrl}/recommendation/user/${USER_ID}/followed-tags/${TAG_ID}`, {
    method: "DELETE",
    headers: buildHeaders()
  }).then((json) => {
    return Promise.resolve(json)
  })
    .catch(json => {
      return Promise.reject(json)}
    )
};

export const apiCreateTag = (tag) => {
  return authorizedFetch(`${baseUrl}/content/tags`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(tag)
  }).then(getResponseOrThrow)
    .then((json) => {
    return Promise.resolve(json)
  })
    .catch(json => {
      return Promise.reject(json)}
    )
};

export const apiUpdateFollowTag = (USER_ID, payload) => {
  return authorizedFetch(`${baseUrl}/recommendation/user/${USER_ID}/followed-tags`, {
    method: "PUT",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
        return Promise.reject(json)
      }
    )
};

export const apiTagsSuggestion = (payload) => {
  return authorizedFetch(`${baseUrl}/recommendation/tags/recommendation`,{
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
        return Promise.reject(json)
      }
    )
};
