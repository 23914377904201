import {
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_REQUEST_FAILED,
  ADD_FAVORITE_REQUEST_SUCCEEDED,
  GET_FAVORITE_REQUEST,
  GET_FAVORITE_REQUEST_FAILED,
  GET_FAVORITE_REQUEST_SUCCEEDED,
  DELETE_FAVORITE_REQUEST,
  DELETE_FAVORITE_REQUEST_FAILED, 
  DELETE_FAVORITE_REQUEST_SUCCEEDED,
  GET_FAVORITE_LIST_REQUEST,
  GET_FAVORITE_LIST_REQUEST_FAILED,
  GET_FAVORITE_LIST_REQUEST_SUCCEEDED,
} from "../constants/favorite";

export const addFavoriteRequest = () => {
  return {
    type: ADD_FAVORITE_REQUEST
  }
};

export const addFavoriteRequestSucceeded = (json) => {
  return {
    type: ADD_FAVORITE_REQUEST_SUCCEEDED,
    data: json
  }
};

export const addFavoriteRequestFailed = (error) => {
  return {
    type: ADD_FAVORITE_REQUEST_FAILED,
    error: error
  }
};

export const getFavoriteRequest = () => {
  return {
    type: GET_FAVORITE_REQUEST
  }
};

export const getFavoriteRequestSucceeded = (json) => {
  return {
    type: GET_FAVORITE_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getFavoriteRequestFailed = (error) => {
  return {
    type: GET_FAVORITE_REQUEST_FAILED,
    error: error
  }
};

export const deleteFavoriteRequest = () => {
  return {
    type: DELETE_FAVORITE_REQUEST
  }
};

export const deleteFavoriteRequestSucceeded = (json) => {
  return {
    type: DELETE_FAVORITE_REQUEST_SUCCEEDED,
    data: json
  }
};

export const deleteFavoriteRequestFailed = (error) => {
  return {
    type: DELETE_FAVORITE_REQUEST_FAILED,
    error: error
  }
};

export const getFavoriteListRequest = () => {
  return {
    type: GET_FAVORITE_LIST_REQUEST
  }
};

export const getFavoriteListRequestSucceeded = (json) => {
  return {
    type: GET_FAVORITE_LIST_REQUEST_SUCCEEDED,
    data: json
  }
};

export const getFavoriteListRequestFailed = (error) => {
  return {
    type: GET_FAVORITE_LIST_REQUEST_FAILED,
    error: error
  }
};
