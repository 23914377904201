import {
  getContentRequest,
  getContentRequestFailed,
  getContentRequestSucceeded,
  newCreateContentRequest,
  newCreateContentRequestSucceeded,
  newCreateContentRequestFailed,
  deleteContentRequest,
  deleteContentRequestSucceeded,
  deleteContentRequestFailed,
  updateContentRequest, updateContentRequestSucceeded, updateContentRequestFailed
} from "../action-creators/content";
import {apiContent, apiCreateContent, apiDeleteContents, apiUpdateContents} from "../api/content";
import {getItemFromStorage} from "../../helper/storage";
import {AUTH_TOKEN} from "../constants/local";

const createContentRequest = (contentId) => dispatch => {
  dispatch(getContentRequest());
  return apiContent(contentId)
    .then((json) => {
      dispatch(getContentRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(getContentRequestFailed(error));
      return Promise.reject(error);
    })
};

export const getContent = (contentId) => dispatch => dispatch(createContentRequest(contentId));

const createNewContentRequest = (payload) => dispatch => {
  dispatch(newCreateContentRequest());
  const token = localStorage.getItem('ALEXANDRIA_AUTH_TOKEN');
  return apiCreateContent(token, payload)
    .then((json) => {
      dispatch(newCreateContentRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(newCreateContentRequestFailed(error));
      return Promise.reject(error);
    })
};

export const createContent = (payload) => dispatch => dispatch(createNewContentRequest(payload));

const requestDeleteContent = (id) => (dispatch) => {
  dispatch(deleteContentRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiDeleteContents(token, id)
    .then((json) => {
      dispatch(deleteContentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(deleteContentRequestFailed(json));
      return Promise.reject(json);
    });
};

export const deleteContent = (id) => (dispatch) => dispatch(requestDeleteContent(id));

const requestUpdateContent = (id, payload) => (dispatch) => {
  dispatch(updateContentRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiUpdateContents(token, id, payload)
    .then((json) => {
      dispatch(updateContentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(updateContentRequestFailed(json));
      return Promise.reject(json);
    });
};

export const updateContent = (id, payload) => (dispatch) => dispatch(requestUpdateContent(id, payload));
