import {SIGNUP_REQUEST, SIGNUP_REQUEST_FAILED, SIGNUP_REQUEST_SUCCEEDED} from "../constants/signup";

export const signUpRequest = () => ({
  type: SIGNUP_REQUEST
});

export const signUpRequestSucceeded = (json) => ({
  type: SIGNUP_REQUEST_SUCCEEDED,
  data: json
});

export const signUpRequestFailed = (json) => ({
  type: SIGNUP_REQUEST_FAILED,
  error: json
});
