import {
  getRelatedRequest,
  getRelatedRequestFailed,
  getRelatedRequestSucceeded
} from "../action-creators/related";
import {apiRelated} from "../api/related";

const createRelatedRequest = (contentId) => dispatch => {
  dispatch(getRelatedRequest());

  return apiRelated(contentId)
    .then((json) => {
      dispatch(getRelatedRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(getRelatedRequestFailed(error));
      return Promise.reject(error);
    })
};

export const getRelated = (contentId) => dispatch => dispatch(createRelatedRequest(contentId));
