import {baseUrl, buildHeaders, getResponseOrThrow} from "./common";

export  const apiRelated = (contentId) => {
  return fetch((`${baseUrl}/recommendation/content/${contentId}/similars`),
    {
      method: "GET",
      headers: buildHeaders()
    }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((error) => Promise.reject(error));
};
