import React from 'react';
import "./About.scss";
import TopAbout from "../TopAbout/TopAbout";
import BottomAbout from "../BottomAbout/BottomAbout";

const About = () => {
  return (
    <div className="About">
        <TopAbout />
        <BottomAbout />
    </div>
  );
};

export default About;
