import React, { useState } from "react";
import "./SignUp.scss";
import Button from "../../global/Button/Button";
import Input from "../../global/form/Input/Input";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../../redux/actions/signup";
import LoaderButton from "../../global/LoaderButton/LoaderButton";
import { usePasswordField } from "../../../hooks/formHooks";
import { useEmailField, useTextField } from "../../../hooks/formHooks";
import Helmet from "react-helmet";
import GoogleSignIn from "../GoogleSignIn/GoogleSignIn";
import FacebookSignIn from "../FacebookSignIn/FacebookSignIn";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import UsernameSignUp from "./UsernameSignUp/UsernameSignUp";
import { login } from "../../../redux/actions/login";

const SignUp = ({openSignUp, setOpenSignUp, setOpenDesktop}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail, validEmail] = useEmailField("");
  const [name, setName, validName] = useTextField("");
  const [username, setUsername, validUserName] = useTextField("");
  const [password, setPassword, validPassword] = usePasswordField("");
  const [passwordConfirmation, setPasswordConfirmation, validPasswordConfirmation] = usePasswordField("");
  const [fetching, setFetching] = useState(false);
  const signup = useSelector((state) => state.signup);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] =
    useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFacebookSignUp, setErrorFacebookSignUp] = useState(false);
  const [activeModalSignUp, setActiveModalSignUp] = useState(false);
  const widthWindow = window.innerWidth;

  const handleSignUp = (e) => {
    setDisplayError(false);
    e.preventDefault();
    if (password !== passwordConfirmation) {
      setErrorPasswordConfirmation(true);
    } else if (!validPassword) {
      setErrorPassword(true);
      setPassword("");
      setTimeout(() => {
        setErrorPassword(false);
      }, 6000);
    } else if (!validEmail || !validUserName || !validName) {
      setDisplayError(true)
    } else {
      const payload = {
        name,
        email,
        username,
        password,
      };
      dispatch(signUp(payload))
        .then(() => {
          setFetching(true);
          dispatch(login({ email, password })).then(() => {
            setFetching(false);
            history.push("/");
          }).catch(() => {
            setFetching(false);
            history.push("/login");
          })
        })
        .catch(({ error }) => {
          if (error === "Email already registered") {
            setErrorMessage("Email já utilizado");
          }

          if (error === "User already registered") {
            setErrorMessage("Nome de usuário já utilizado");
          }

          setFetching(true);
          setTimeout(() => {
            setFetching(false);
          }, 1500);
        });
    }
  };

  const handleSignUpMobile = (e) => {
    e.preventDefault();
    setDisplayError(false);
    if (password !== passwordConfirmation) {
      setErrorPasswordConfirmation(true);
    } else if (!validPassword) {
      setErrorPassword(true);
      setPasswordConfirmation("");
      setTimeout(() => {
        setErrorPassword(false);
      }, 8000);
    } else if (!validEmail || !validName) {
      setDisplayError(true);
    } else {
      setActiveModalSignUp(true);
    }
  };

  return activeModalSignUp ? (
    <UsernameSignUp
      password={password}
      email={email}
      name={name}
      setActiveModalSignUp={setActiveModalSignUp}
      setEmail={setEmail}
    />
  ) : (
    <div
      className={`SignUp ${
        fetching || signup.isFetching ? "fetching-signup" : ""
      }`}
    >
      <div className="exit-signup-container">
        {openSignUp && <button className="exit-entry" onClick={() => setOpenDesktop(false)}/>}
      </div>
      <Helmet title="Registrar - Alexandria" />
      <GoogleSignIn buttonText="Continuar com o Google" />
      <FacebookSignIn
        errorFacebook={setErrorFacebookSignUp}
        textButton="Continuar com o Facebook"
      />
      <div className="screen-splitter">
        <span />
        <p>OU</p>
        <span />
      </div>
      <form
        onSubmit={(e) =>
          widthWindow > 1024 ? handleSignUp(e) : handleSignUpMobile(e)
        }
      >
        <p className={errorMessage.length > 0 ? "show-error-message" : ""}>{errorMessage}</p>
        <Input
          updateState={setName}
          type="text"
          valid={validName}
          displayError={displayError}
          errorMessage="Nome inválido"
          placeholder="Nome Completo"
          value={name}
        />
        <Input
          updateState={setEmail}
          type="email"
          valid={validEmail}
          displayError={displayError}
          errorMessage="Email inválido"
          placeholder="Email"
          value={email}
        />
        <Input
          updateState={setUsername}
          type="text"
          className="disable-mobile"
          valid={validUserName}
          displayError={displayError}
          errorMessage="Nome de usuário inválido"
          placeholder="Nome de Usuário"
          value={username}
        />
        <Input
          updateState={setPassword}
          type="password"
          errorMessage="Senha inválida"
          placeholder="Senha"
          value={password}
        />
        <Input
          updateState={setPasswordConfirmation}
          type="password"
          displayError={errorPasswordConfirmation}
          valid={validPasswordConfirmation}
          errorMessage="Senha e confirmação diferentes"
          placeholder="Confirmar senha"
          value={passwordConfirmation}
        />
        {errorPassword && (
          <p className="alert-password">
            A senha escolhida deverá ter no mínimo 6 caracteres e deve possuir
            números, letras maiúsculas e minúsculas e caracteres especiais
          </p>
        )}
        {fetching || signup.isFetching ? (
          <LoaderButton />
        ) : (
          <Button
            primaryColor="#CFEC59"
            className="signup-button"
            children="Cadastrar"
          />
        )}
        <Button
          primaryColor="#CFEC59"
          className="signup-button-mobile"
          children="Iniciar sessão"
          onClick={(e) => handleSignUpMobile(e)}
        />
      </form>
      <div className="login-registration">
        <p>Já tem cadastro?</p>
        <Button
          primaryColor="#ffff"
          children="Iniciar sessão"
          onClick={() => openSignUp ? setOpenSignUp(false) : history.push("/login")}
        />
      </div>
      {errorFacebookSignUp && (
        <BluePopUp text="" onClick={() => setErrorFacebookSignUp(false)} />
      )}
    </div>
  );
};

export default SignUp;
