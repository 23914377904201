import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CommentIcon from "../../../assets/images/comment-icon.svg";
import FavoriteIconBlack from "../../../assets/images/favorite-icon-black.svg";
import FavoriteIcon from "../../../assets/images/favorite-icon.svg";
import PlayListIcon from "../../../assets/images/playlist-icon.svg";
import SeeLaterIconBlack from "../../../assets/images/seelater-icon-black.svg";
import SeeLaterIcon from "../../../assets/images/seelater-icon.svg";
import useAuth from '../../../hooks/useAuth';
import useMobileDevice from "../../../hooks/useMobileDevice";
import { getContent } from "../../../redux/actions/content";
import { addFavorite } from "../../../redux/actions/favorite";
import MobileShare from "../MobileShare/MobileShare";
import './ContentMenu.scss';

const ContentMenu = ({ content, setOpenComment, setOpenPlaylist, title }) => {
  const history = useHistory();
  const isLoggedIn = useAuth();
  const dispatch = useDispatch();
  const [isMobileOrTablet] = useMobileDevice();

  const addContentInFavorite = e => {
    e.preventDefault()
    const payload = {
      platform: "ALEXANDRIA"
    }
    dispatch(addFavorite(content?.id, payload))
      .then(() => {
        dispatch(getContent(content?.id))
      })
      .catch(() => {
        alert("Houve um erro inesperado, tente novamente.")
      });
  }
  const addContentInSeeLater = e => {
    e.preventDefault()
    const payload = {
      platform: "ALEXANDRIA"
    }
    dispatch(addFavorite(content?.id, payload))
      .then(() => {
        dispatch(getContent(content?.id))
      })
      .catch(() => {
        alert("Houve um erro inesperado, tente novamente.")
      });
  }

  return (
    <div className="ContentMenu">
      <div onClick={() => setOpenComment(true)}>
        <button style={{ backgroundImage: `url(${CommentIcon})` }} />
        <p>{content?.contributions?.length}</p>
      </div>
      <button onClick={(e) => isLoggedIn ? addContentInFavorite(e) : history.push("/login")}
        style={{ backgroundImage: `url(${content?.isFavorite ? FavoriteIconBlack : FavoriteIcon})` }}
      />
      <button onClick={(e) => isLoggedIn ? addContentInSeeLater(e) : history.push("/login")}
        style={{ backgroundImage: `url(${content?.isOnSeeLater ? SeeLaterIconBlack : SeeLaterIcon})` }} />
      <button onClick={() => isLoggedIn ? setOpenPlaylist(true) : history.push("/login")}
        style={{ backgroundImage: `url(${PlayListIcon})` }} />
      {isMobileOrTablet && (
        <MobileShare contentTitle={title} />
      )}
    </div>
  );
};

export default ContentMenu;
