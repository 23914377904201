import React, {useState} from 'react';
import "./CommentTextMobile.scss";
import Input from "../form/Input/Input";
import SearchTagComment from "../CommentText/SearchTagComment/SearchTagComment";
import DeleteTag from "../DeleteTag/DeleteTag";
import {useDispatch, useSelector} from "react-redux";
import {removeSpecialCharacters} from "../../../helper/removeSpecialCharacters";
import Button from "../Button/Button";
import {apiCreateTag} from "../../../redux/api/tags";
import {newComment} from "../../../redux/actions/comment";
import {useTextField} from "../../../hooks/formHooks";
import {getContent} from "../../../redux/actions/content";

const CommentTextMobile = ({createComment, setCreateComment, content}) => {
  const dispatch = useDispatch();
  const tags = useSelector(state => state.tags?.data);
  const contentId = useSelector(state => state.content?.data?.id);
  const [listTags] = useState([]);
  const [text, setText, validText] = useTextField("");
  const [contentReference, setContentReference, validContentReference] = useTextField("");
  const [fromSeconds, setFromSeconds, validFromSeconds] = useTextField("");
  const [toSeconds, setToSeconds, validToSeconds] = useTextField("");
  const [listTagsID] = useState([]);
  const [valueInput, setValueInput] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [nameTag, setNameTag] = useState("");
  const [errorCreateTag, setErrorCreateTag] = useState(false);
  
  const handleCreateTag = () => {
    if (valueInput.includes(",")) {
      const noSpecialCharacters = removeSpecialCharacters(valueInput)
      if (noSpecialCharacters.length < 2) {
        setValueInput(removeSpecialCharacters(valueInput))
        setErrorCreateTag(true)
      } else if (noSpecialCharacters.length > 40) {
        setValueInput(removeSpecialCharacters(valueInput))
        setErrorCreateTag(true)
      } else {
        setValueInput("")
        listTags.push(noSpecialCharacters)
      }
    }
  }
  
  const resultInput = !valueInput ? tags :
    tags.filter(item => item.name.toLowerCase().includes(valueInput.toLocaleLowerCase()))
      .filter(item => item.name !== valueInput);
  
  const cards = resultInput.slice(0, 4)
  
  const removeTag = (name) => {
    setNameTag(name)
    listTags.splice(listTags.indexOf(name), 1)
  }
  
  const removeTagID = (name) => {
    listTagsID.splice(listTagsID.indexOf(name.toUpperCase()), 1)
  }
  
  const handleClick = name => {
    setValueInput(name)
  }
  
  const changeContentReference = e => {
    e.preventDefault();
    setContentReference(e.target.value)
  }
  
  const changeText = e => {
    e.preventDefault();
    setText(e.target.value)
  }
  
  const renderComment = () => {
    if (content?.mediaType === "AUDIO" ||
      content?.mediaType === "VIDEO") {
      return (<div className="comment-audio">
        <Input label="Iniciar" placeholder=":" type="time" value={fromSeconds} updateState={setFromSeconds}/>
        <Input label="até:" type="time" value={toSeconds} updateState={setToSeconds}/>
      </div>)
    } else {
      return (
        <div>
          <label>Cole aqui uma citação do conteúdo</label>
          <textarea type="text"
                    value={contentReference}
                    onChange={changeContentReference}
          />
        </div>
      )
    }
  }
  
  const timeToSecondsOnly = str => {
    let convert = str.split(':'),
      seconds = 0, minutes = 1;
    
    while (convert.length > 0) {
      seconds += minutes * parseInt(convert.pop(), 10);
      minutes *= 60;
    }
    
    return seconds;
  }
  
  const handleComment = (e) => {
    e.preventDefault()
    if (!validText) {
      alert("Preencha todos os campos")
    } else {
      const payload = {
        text: text,
      }
      if (content?.mediaType === "AUDIO" ||
        content?.mediaType === "VIDEO") {
        if (!validFromSeconds || !validToSeconds) {
          alert("Preencha todos os campos")
        }
        payload.contentReference = {
          fromSeconds: timeToSecondsOnly(fromSeconds),
          toSeconds: timeToSecondsOnly(toSeconds)
        }
      } else {
        if (!validContentReference) {
          alert("Preencha todos os campos")
        }
        payload.contentReference = {text: contentReference}
      }
      let tagsToCreate = listTags.filter(tag => !tags.some(item => tag === item.name));
      let newTagsPromisses = tagsToCreate.map(tag => apiCreateTag({
        name: tag
      }));
      Promise.all(newTagsPromisses).then((results) => {
        let newTagIds = results.map(result => result.id);
        payload.tags = listTagsID.concat(newTagIds);
        dispatch(newComment(payload, contentId))
          .then(() => {
            setCreateComment(false)
            dispatch(getContent(contentId))
            alert("Comentário enviado com sucesso!")
          })
          .catch(() => {
            alert("Houve um erro, tente novamente mais tarde!")
          });
      })
    }
  }
  
  return (
    <section style={{
      visibility: `${createComment ? "visible" : "hidden"}`,
      opacity: `${createComment ? 1 : 0}`
    }}
             className="CommentTextMobile">
      <button className="exit-entry-mobile" onClick={() => setCreateComment(false)}/>
      <div className="create-comment-container">
        <p>Qual a sua opinião sobre essa lente?</p>
        <div className="create-comment-inputs">
          <form onSubmit={() => setText("") && setContentReference("")}>
            {renderComment()}
            <div>
              <label>Escreva o seu comentário</label>
              <textarea type="text"
                        onChange={changeText}
              />
            </div>
            <Input className={errorCreateTag ? "error-tag" : ""}
                   value={valueInput}
                   updateState={setValueInput}
                   onEnter={() => setErrorCreateTag(false)}
                   type="text"
                   label="Adicione as tags do seu comentário"
                   placeholder="Adicionar tags"
                   o nChange={handleCreateTag()}/>
            {valueInput && cards.length > 0 ? (
              <div style={{display: !valueInput ? "none" : "flex"}} className="container-search-tags">
                {cards.map((item, key) =>
                  <SearchTagComment item={item} key={key} onClick={() => {
                    listTags.push(item.name)
                    listTagsID.push(item.id)
                    handleClick(item.name)
                    setValueInput("")
                  }}/>
                )}
              </div>
            ) : <p className="insert-tag">Insira uma vírgula depois de cada tag</p>}
            <div className="container-delete-tags">
              {listTags.map((item, key) => {
                return <DeleteTag item={item} key={key} onClick={(e) => {
                  e.preventDefault()
                  removeTag(item)
                  removeTagID(item)
                }
                }/>
              })}
            </div>
            <div className="submit-container">
              <Button children="Postar comentário" primaryColor="#CFEC59" onClick={(e) => handleComment(e)}/>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CommentTextMobile;
