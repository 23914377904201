import React, {useState} from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./home/Home/Home";
import SearchResult from "./searchResult/SearchResult/SearchResult";
import ContentScreen from "./contentScreen/ContentScreen/ContentScreen";
import Entry from "./entry/Entry/Entry";
import GenericPage from "./genericPage/GenericPage/GenericPage";
import UserProfile from "./userProfile/UserProfile/UserProfile";
import Extension from "./extension/Extension/Extension";
import About from "./about/About/About";
import CollectionView from "./userProfile/collectionInfo/CollectionView/CollectionView";
import PrivateRoute from "./privateRoute/PrivateRoute/PrivateRoute";
import EditUser from "./userProfile/EditUser/EditUser";
import FollowTags from "./FollowTags/FollowTags";
import CollectionShare from "./userProfile/collectionInfo/CollectionShare/CollectionShare";
import Library from "./library/Library/Library";
import Playlist from "./library/Playlist/Playlist";
import EditProfile from "./userProfile/EditUser/EditProfile/EditProfile";
import EditPassword from "./userProfile/EditUser/EditPassword/EditPassword";
import CreateContent from "./userProfile/CreateContent/CreateContent";

function App() {
  const [activeTag, setActiveTag] = useState(false);

  const renderMobile = () => {
    if (window.innerWidth > 1024) {
      return (
        <>
          <Route
            path="/perfil"
            component={() => (
              <GenericPage>
                <UserProfile />
              </GenericPage>
            )}
          />
          <Route
            path="/sobre"
            component={() => (
              <GenericPage>
                <About />
              </GenericPage>
            )}
          />
          <Route
            path="/editar/perfil/usuario"
            exact
            component={() => (
              <PrivateRoute>
                <GenericPage>
                  <EditUser />
                </GenericPage>
              </PrivateRoute>
            )}
          />
          <Route
            path="/editar/perfil/senha"
            exact
            component={() => (
              <PrivateRoute>
                <GenericPage>
                  <EditUser />
                </GenericPage>
              </PrivateRoute>
            )}
          />
          <Route
            path={"/playlist/:playlistId/:slug?"}
            component={() => (
              <GenericPage>
                <CollectionShare />
              </GenericPage>
            )}
          />
        </>
      );
    } else {
      return (
        <>
          <Route
            path="/perfil"
            component={() => (
              <GenericPage>
                <Library />
              </GenericPage>
            )}
          />
          <Route
            path="/editar/perfil/usuario"
            exact
            component={() => (
              <PrivateRoute>
                <GenericPage>
                  <EditProfile />
                </GenericPage>
              </PrivateRoute>
            )}
          />
          <Route
            path="/editar/perfil/senha"
            exact
            component={() => (
              <PrivateRoute>
                <GenericPage>
                  <EditPassword />
                </GenericPage>
              </PrivateRoute>
            )}
          />
          <Route
            path={"/playlist/:playlistId/:slug?"}
            component={() => (
              <GenericPage>
                <Playlist />
              </GenericPage>
            )}
          />
        </>
      );
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        {renderMobile()}
        <Route path="/login" exact component={() => <Entry />} />
        <Route path="/registrar" exact component={() => <Entry />} />
        <Route path="/redefinir" exact component={() => <Entry />} />
        <Route path="/redefinir/codigo" exact component={() => <Entry />} />
        <Route
          path="/redefinir/codigo/:code"
          exact
          component={() => <Entry />}
        />
        <Route
          path="/"
          exact
          component={() => (
            <GenericPage setActiveTag={setActiveTag}>
              <Home activeTag={activeTag} setActiveTag={setActiveTag}/>
            </GenericPage>
          )}
        />
        <Route
          path="/pesquisa"
          exact
          component={() => (
            <GenericPage>
              <SearchResult />
            </GenericPage>
          )}
        />
        <Route
          path={"/conteudo/:contentId/:slug?" }
          exact
          component={() => (
            <GenericPage>
              <ContentScreen />
            </GenericPage>
          )}
        />
        <Route
          path="/extensao"
          component={() => (
            <GenericPage>
              <Extension />
            </GenericPage>
          )}
        />
        <Route
          path="/editar/perfil"
          exact
          component={() => (
            <PrivateRoute>
              <GenericPage>
                <EditUser />
              </GenericPage>
            </PrivateRoute>
          )}
        />
        <Route
          path={"/editar/jornada/playlist/:playlistId/:slug?"}
          component={() => (
            <GenericPage>
              <CollectionView />
            </GenericPage>
          )}
        />
        <Route
          path="/seguir-tags"
          component={() => (
            <PrivateRoute exact>
              <FollowTags />
            </PrivateRoute>
          )}
        />
        <Route
          path="/criar-conteudo"
          exact
          component={() => (
            <PrivateRoute exact>
              <GenericPage>
                <CreateContent />
              </GenericPage>
            </PrivateRoute>)}/>
        <Route
          path="/editar-conteudo/:id"
          component={() => (
            <PrivateRoute exact>
              <GenericPage>
                <CreateContent />
              </GenericPage>
            </PrivateRoute>)}/>
      </BrowserRouter>
    </div>
  );
}

export default App;
