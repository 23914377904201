import React from "react";
import "./FacebookSignIn.scss";
import { ReactComponent as FacebookIcon } from "../../../assets/images/facebook-icon.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerFacebookToken } from "../../../redux/actions/login";
import Button from "../../global/Button/Button";
import queryString from "querystring";
import { FACEBOOK_CLIENT_ID } from "../../../constants/login";
import { updateUserStorage } from "../../../helper/loginStorage";

const FacebookSignIn = ({ errorFacebook, textButton, openDesktop }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const facebookLogin = async () => {
    const code = await new Promise((resolve, reject) => {
      const popup = window.open(
        `https://www.facebook.com/dialog/oauth?client_id=${FACEBOOK_CLIENT_ID}&display=popup&redirect_uri=${window.location.href}&scope=email`,
        "Login com Facebook",
        `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
              width=0,height=0,left=-500,top=-500`
      );
      popup.addEventListener("load", async (e) => {
        try {
          const popupUrlQueries = Object.values(
            queryString.parse(popup.location.href.replace("?", ""))
          );
          const popupCode = popupUrlQueries[0].split("#")[0];
          resolve(popupCode);
          popup.close();
        } catch {
          reject(null);
        }
      });
    });

    const payload = {
      code: code,
      redirectUri: `${window.location.href}`,
    };

    dispatch(registerFacebookToken(payload))
      .then((json) => {
        updateUserStorage(json);
        setTimeout(() => {
          openDesktop ? window.location.reload() : history.push("/");
        }, 1000);
      })
      .catch(() => {
        errorFacebook(true);
      });
  };

  return (
    <div className="FacebookSignIn">
      <Button primaryColor="#3b5998" onClick={() => facebookLogin()}>
        <FacebookIcon />
        {textButton}
      </Button>
    </div>
  );
};

export default FacebookSignIn;
