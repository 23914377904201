import React from 'react';
import LibraryInfo from "../LibraryInfo/LibraryInfo";
import PrivateRoute from "../../privateRoute/PrivateRoute/PrivateRoute";
import PlaylistMobile from "../PlaylistMobile/PlaylistMobile";
import SeeLater from "../../userProfile/SeeLater/SeeLater";
import Favorite from "../../userProfile/Favorite/Favorite";
import HistoryMobile from "../HistoryMobile/HistoryMobile";
import MyContents from "../../userProfile/MyContents/MyContents";

const Library = () => {
  return (
    <div>
      <LibraryInfo />
      <PrivateRoute path="/perfil/meus-conteudos" exact={false}><MyContents/></PrivateRoute>
      <PrivateRoute path="/perfil/colecao" exact={false}><PlaylistMobile/></PrivateRoute>
      <PrivateRoute path="/perfil/ver-mais" exact={false}><SeeLater/></PrivateRoute>
      <PrivateRoute path="/perfil/favoritos" exact={false}><Favorite/></PrivateRoute>
      <PrivateRoute path="/perfil/historico" exact={false} ><HistoryMobile/></PrivateRoute>
    </div>
  );
};

export default Library;
