import React from 'react';
import './BluePopUp.scss';

const BluePopUp = ({text, onClick}) => {
  return (
    <div className="BluePopUp">
      <div className={`${!text && "red"}`}>
        <p>{!text ? "Houve um erro inesperado, tente novamente." : text}</p>
        <button onClick={onClick}>Fechar</button>
      </div>
    </div>
  );
};

export default BluePopUp;
