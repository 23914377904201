import { useState } from "react";
import isValidEmail from "../helper/isValidEmail";

export const useEmailField = initialValue => {
  const [email, setEmail] = useState(initialValue);
  const [valid, setValid] = useState(isValidEmail(initialValue));
  const handleChange = newValue => {
    setEmail(newValue);
    setValid(isValidEmail(newValue));
  };
  return [email, handleChange, valid];
};

export const useTextField = initialValue => {
  const [text, setText] = useState(initialValue);
  const [valid, setValid] = useState(initialValue !== "");
  const handleChange = newValue => {
    setText(newValue);
    setValid(newValue !== "" && newValue.length > 2);
  };
  return [text, handleChange, valid];
};

export const useNumberField = initialValue => {
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }
  const [number, setNumber] = useState(initialValue);
  const [valid, setValid] = useState(initialValue !== "");
  const handleChange = newValue => {
    setNumber(newValue);
    setValid(newValue !== "" && isNumeric(newValue));
  };
  return [number, handleChange, valid];
};

export const usePasswordField = initialValue => {
  const passwordValidationRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%.,\|+*#?&])[A-Za-z\d@$!%*#?&.,\|].{6,}/;
  const [password, setPassword] = useState(initialValue);
  const [valid, setValid] = useState(passwordValidationRegex.test(initialValue));
  const handleChange = newValue => {
    setPassword(newValue);
    setValid(passwordValidationRegex.test(newValue));
  };
  return [password, handleChange, valid];
};
