import React, { useEffect, useState } from "react";
import "./CollectionView.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ContentCard from "../../../global/ContentCard/ContentCard";
import { infoJourney, updateJourney } from "../../../../redux/actions/journey";
import DeleteCollectionPopUp from "./DeleteCollectionPopUp/DeleteCollectionPopUp";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import { Helmet } from "react-helmet";
import CardFetching from "../../../global/CardFetching/CardFetching";

const CollectionView = () => {
  const history = useHistory();
  const { playlistId } = useParams();
  const dispatch = useDispatch();
  const journey = useSelector((state) => state.journey?.data);
  const journeyFetching = useSelector((state) => state.journey?.isFetching);
  const [updateMode, setUpdateMode] = useState(false);
  const [listJourney, setListJourney] = useState([]);
  const [popUpDeleteJourneyList, setPopUpDeleteJourneyList] = useState(false);
  const [popUpDeleteBlue, setPopUpDeleteBlue] = useState(false);
  const [activeButtonDelete] = useState(true);
  const [updateJourneyPop, setUpdateJourneyPop] = useState(false);
  const [updateJourneyMessage, setUpdateJourneyMessage] = useState("");

  useEffect(() => {
    setListJourney(journey?.contents);
  }, [journey?.contents]);

  const removeContent = (item) => {
    const newList = [...listJourney];
    newList.splice(listJourney.indexOf(item), 1);
    setListJourney(newList);
  };

  const handleDeleteContent = () => {
    const payload = {
      name: journey?.name,
      contentIds: listJourney?.map((x) => x.id),
      platform: "ALEXANDRIA",
    };
    dispatch(updateJourney(playlistId, payload))
      .then(() => {
        setUpdateJourneyPop(true);
        setUpdateJourneyMessage("Jornada atualizada com sucesso!");
        setUpdateMode(false);
      })
      .catch(() => {
        setUpdateJourneyPop(true);
        setUpdateJourneyMessage("");
      });
  };

  useEffect(() => {
    dispatch(infoJourney(playlistId));
  }, [dispatch, playlistId]);

  return (
    <div className="CollectionView">
      <Helmet title={`${journey?.name} - Alexandria`} />
      <div className="edit-collection-view">
        <div>
          <button onClick={() => history.push("/perfil/colecao")}>
            VOLTAR
          </button>
          <h2>{journey?.name}</h2>
        </div>
        <div>
          {updateMode ? (
            <button
              style={{ opacity: 0.3, color: "#000000" }}
              className="cancel-update"
              onClick={() => setUpdateMode(false)}
            >
              Cancelar
            </button>
          ) : (
            <button onClick={() => setPopUpDeleteJourneyList(true)}>
              Excluir
            </button>
          )}
          {updateMode ? (
            !journeyFetching ? (
              <button onClick={() => handleDeleteContent()}>Salvar</button>
            ) : (
              <div
                className="loader-update"
                style={{
                  width: "50px",
                  paddingRight: "20px",
                }}
              >
                <div />
              </div>
            )
          ) : (
            <button
              onClick={() => {
                setUpdateMode(true);
              }}
            >
              Editar
            </button>
          )}
        </div>
      </div>
      <div className="collection-view-cards">
        {journeyFetching
          ? <CardFetching amount={4} />
          : updateMode
          ? listJourney?.map((item, key) => (
              <ContentCard
                onDelete={() => {
                  removeContent(item);
                }}
                activeButtonDelete={activeButtonDelete}
                item={item}
                key={key}
              />
            ))
          : journey?.contents &&
            [...journey?.contents].map((item, key) => {
              return <ContentCard item={item} key={key} />;
            })}
      </div>
      {popUpDeleteJourneyList && (
        <DeleteCollectionPopUp
          isOpenPopUpDeleteBlue={popUpDeleteBlue}
          setPopUpDeleteBlue={setPopUpDeleteBlue}
          setPopUpDeleteJourneyList={setPopUpDeleteJourneyList}
        />
      )}
      {popUpDeleteBlue && (
        <BluePopUp
          text="Coleção excluida com sucesso!"
          onClick={() => history.push("/perfil/colecao")}
        />
      )}
      {updateJourneyPop && (
        <BluePopUp
          text={updateJourneyMessage}
          onClick={() => setUpdateJourneyPop(false)}
        />
      )}
    </div>
  );
};

export default CollectionView;
