import React from 'react';
import "./GenericCreationCard.scss";

const GenericCreationCard = ({label, children, action}) => {
  return (
    <div className="GenericCreationCard">
      <div className="creation-button">
        <button onClick={action} aria-describedby="label"/>
        <p id="label">{label}</p>
      </div>
      {children}
    </div>
  );
};

export default GenericCreationCard;
