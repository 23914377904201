import React, { useEffect, useRef, useState } from 'react';
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../../../../redux/actions/history";
import HistoryDay from "../HistoryDay/HistoryDay";
import HistoryFilter from "../HistoryFilter/HistoryFilter";
import "./History.scss";

const History = ({ startFrom }) => {
  const dispatch = useDispatch();
  const historyFetching = useSelector(state => state.history?.isFetching);
  const historyData = useSelector(state => state.history?.data || { items: [], totalPages: 1 });
  const [fromState, setFromState] = useState(startFrom || null);
  const [toState, setToState] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadedItems, setLoadedItems] = useState([]);
  const observer = useRef(null);
  const endOfPageRef = useRef(null);

  useEffect(() => {
    if (page === 1) {
      setLoadedItems([]);
    }

    if (!loading) {
      setLoading(true);
      dispatch(getHistory({ ...buildFilters(), page })).then((newData) => {
        setLoadedItems((prevItems) => [...prevItems, ...newData.items]);
        setLoading(false);
      });
    }
  }, [page]);

  useEffect(() => {
    if (historyFetching || page >= historyData.totalPages) return;

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    });

    if (endOfPageRef.current) {
      observer.current.observe(endOfPageRef.current);
    }
  }, [loading, historyFetching]);

  const buildFilters = () => {
    let filters = {};
    if (fromState) filters['from'] = fromState.toISOString().substring(0, 10);
    if (toState) filters['to'] = toState.toISOString().substring(0, 10);
    return filters;
  };

  const mapHistoryDays = () => {
    let mapped = {};
    loadedItems.forEach((historyItem) => {
      let date = new Date(historyItem.createdAt);
      let parsedDate = date.toISOString().substring(0, 10);
      if (!mapped[parsedDate]) mapped[parsedDate] = [];
      mapped[parsedDate].push(historyItem);
    });
    return mapped;
  };

  const historyDaysToComponent = () => {
    return Object.keys(mapHistoryDays()).map(day => {
      let date = new Date(day + "T23:59:59.000Z");
      let dateDiff = Math.floor((date - new Date()) / (1000 * 60 * 60 * 24));
      let showcaseName = day;
      if (dateDiff === 0) showcaseName = "Hoje";
      else if (dateDiff === -1) showcaseName = "Ontem";
      else if (dateDiff >= -30) showcaseName = -dateDiff + " Dias";
      return <HistoryDay title={showcaseName} items={mapHistoryDays()[day]} key={day} />;
    });
  };

  return (
    <div className="History">
      <Helmet title="Histórico - Alexandria" />
      <HistoryFilter
        fromState={fromState}
        setFromState={setFromState}
        toState={toState}
        setToState={setToState}
      />
      <div className="history-container">
        {historyDaysToComponent()}
        {loading && <div className="loading">Carregando...</div>}
        <div ref={endOfPageRef} />
      </div>
    </div>
  );
};

export default History;
