export const ADD_SEE_LATER_REQUEST = "ADD_SEE_LATER_REQUEST";
export const ADD_SEE_LATER_REQUEST_SUCCEEDED = "ADD_SEE_LATER_REQUEST_SUCCEEDED";
export const ADD_SEE_LATER_REQUEST_FAILED = "ADD_SEE_LATER_REQUEST_FAILED";

export const GET_SEE_LATER_REQUEST = "GET_SEE_LATER_REQUEST";
export const GET_SEE_LATER_REQUEST_SUCCEEDED = "GET_SEE_LATER_REQUEST_SUCCEEDED";
export const GET_SEE_LATER_REQUEST_FAILED = "GET_SEE_LATER_REQUEST_FAILED";

export const DELETE_SEE_LATER_REQUEST = "DELETE_SEE_LATER_REQUEST";
export const DELETE_SEE_LATER_REQUEST_SUCCEEDED = "DELETE_SEE_LATER_REQUEST_SUCCEEDED";
export const DELETE_SEE_LATER_REQUEST_FAILED = "DELETE_SEE_LATER_REQUEST_FAILED";
