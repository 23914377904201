import React, { useState } from 'react';
import ChatButton from '../ChatButton/ChatButton';
import ChatModal from '../ChatModal/ChatModal';
import './Chat.scss';

const Chat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);

  const toggleChat = () => {
    if (!chatStarted) {
      setChatStarted(true);
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="chat-container">
      {isOpen && <ChatModal onClose={toggleChat} />}
      {!isOpen && <ChatButton onClick={toggleChat} chatStarted={chatStarted} />}
    </div>
  );
};

export default Chat;
