import React from 'react';
import './LoginHome.scss';
import GoogleSignIn from "../../entry/GoogleSignIn/GoogleSignIn";
import FacebookSignIn from "../../entry/FacebookSignIn/FacebookSignIn";
import Button from "../../global/Button/Button";
import {useHistory} from "react-router-dom";

const LoginHome = () => {
  const history = useHistory();

  return (
    <section className="LoginHome">
      <p>Ainda não tem uma conta?</p>
      <GoogleSignIn buttonText="Continuar com o Google"/>
      <FacebookSignIn textButton="Continuar com o Facebook"/>
      <Button children="Continuar com email" onClick={() => history.push("/login")}/>
    </section>
  );
};

export default LoginHome;
