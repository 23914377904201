import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow} from "./common";

export  const apiContent = (contentId) => {
  return authorizedFetch((`${baseUrl}/content/content/${contentId}`),
    {
      method: "GET",
      headers: buildHeaders()
    }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((error) => Promise.reject(error));
};

export const apiCreateContent = (token, payload) => {
  return authorizedFetch((`${baseUrl}/content/content`),
    {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((error) => Promise.reject(error));
};

export const apiDeleteContents = (token, id) => {
  return fetch(`${baseUrl}/content/content/${id}`, {
    method: "DELETE",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};

export const apiUpdateContents = (token, id, payload) => {
  return fetch(`${baseUrl}/content/content/${id}`, {
    method: "PATCH",
    headers: buildHeaders(token),
    body:JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};
