import {
  addContentInJourneyRequest,
  addContentInJourneyRequestFailed,
  addContentInJourneyRequestSucceeded,
  createJourneyRequest,
  createJourneyRequestFailed,
  createJourneyRequestSucceeded,
  deleteJourneyRequest,
  deleteJourneyRequestFailed,
  deleteJourneyRequestSucceeded,
  getJourneyListRequest,
  getJourneyListRequestFailed,
  getJourneyListRequestSucceeded,
  getJourneyRequest,
  getJourneyRequestFailed,
  getJourneyRequestSucceeded,
  shareJourneyRequest,
  shareJourneyRequestFailed,
  shareJourneyRequestSucceeded,
  UpdateJourneyRequest,
  UpdateJourneyRequestFailed,
  UpdateJourneyRequestSucceeded
} from "../action-creators/journey";
import {
  apiAddContentInJourney,
  apiCreateJourney, apiDeleteJourneyList, apiGetJourney, apiGetJourneyList, apiShareJourney, apiUpdateJourney
} from "../api/journey";

const createJourney = (payload) => dispatch => {
  dispatch(createJourneyRequest());
  const userID = localStorage.getItem('USER_ID');
  return apiCreateJourney(payload, userID)
    .then(json => {
      dispatch(createJourneyRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(createJourneyRequestFailed(json));
      return Promise.reject();
    });
};

export const newJourney = (payload) => dispatch => dispatch(createJourney(payload));

const getJourneyList = (filters) => dispatch => {
  dispatch(getJourneyListRequest());
  return apiGetJourneyList(filters)
    .then(json => {
      dispatch(getJourneyListRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(getJourneyListRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const infoJourneyList = (filters) => dispatch => dispatch(getJourneyList(filters));

const addContentInJourneyList = (journeyId, contentId) => dispatch => {
  dispatch(addContentInJourneyRequest());
  const userID = localStorage.getItem('USER_ID');
  return apiAddContentInJourney(journeyId, contentId, userID)
    .then(json => {
      dispatch(addContentInJourneyRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(addContentInJourneyRequestFailed(response));
      return Promise.reject(response);
    }
    );
};

export const contentInJourneyList = (journeyId, contentId) => dispatch => dispatch(addContentInJourneyList(journeyId, contentId));

const getJourney = (playlistId) => dispatch => {
  dispatch(getJourneyRequest());
  return apiGetJourney(playlistId)
    .then(json => {
      dispatch(getJourneyRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(getJourneyRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const infoJourney = (playlistId) => dispatch => dispatch(getJourney(playlistId));

const deleteJourneyList = (playlistId) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(deleteJourneyRequest());
  return apiDeleteJourneyList(userID, playlistId)
    .then(json => {
      dispatch(deleteJourneyRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(deleteJourneyRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const deleteJourney = (playlistId) => dispatch => dispatch(deleteJourneyList(playlistId));

const updateJourneyList = (playlistId, payload) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  const token = localStorage.getItem('ALEXANDRIA_AUTH_TOKEN');
  dispatch(UpdateJourneyRequest());
  return apiUpdateJourney(userID, token, playlistId, payload)
    .then(json => {
      dispatch(UpdateJourneyRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(UpdateJourneyRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const updateJourney = (playlistId, payload) => dispatch => dispatch(updateJourneyList(playlistId, payload));

const shareJourneyList = (playlistId) => dispatch => {
  dispatch(shareJourneyRequest());
  return apiShareJourney(playlistId)
    .then(json => {
      dispatch(shareJourneyRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
      dispatch(shareJourneyRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const shareJourney = (playlistId) => dispatch => dispatch(shareJourneyList(playlistId));
