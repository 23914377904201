import React, { useState } from "react";
import "./EditPassword.scss";
import Input from "../../../global/form/Input/Input";
import { usePasswordField } from "../../../../hooks/formHooks";
import { useDispatch, useSelector } from "react-redux";
import { updateNewPassword } from "../../../../redux/actions/user";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import GreyPopUp from "../../../global/GreyPopUp/GreyPopUp";
import ExitButton from "../../../global/ExitButton/ExitButton";
import { useWindowSizeHook } from "../../../../hooks/WindowHooks";
import { useHistory } from "react-router";

const EditPassword = () => {
  const dispatch = useDispatch();
  const passwordFetching = useSelector(
    (state) => state.newPassword?.isFetching
  );
  const [oldPassword, setOldPassword] = usePasswordField("");
  const [newPassword, setNewPassword, validNewPassword] = usePasswordField("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] =
    useState(false);
  const [popUpSucceedUpdatePassword, setPopUpSucceedUpdatePassword] =
    useState(false);
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState("");
  const [verifyOldPassword, setVerifyOldPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { width } = useWindowSizeHook();
  const history = useHistory();

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    setShowErrorMessage(false);

    if (!newPassword || !newPasswordConfirmation || !oldPassword) {
      setErrorMessage("Preencha todos os campos");
      setShowErrorMessage(true);
      return;
    }

    if (newPassword !== newPasswordConfirmation) {
      setErrorMessage("Suas novas senhas não coincidem");
      setShowErrorMessage(true);
      return;
    } else if (!validNewPassword) {
      setErrorMessage(
        "A senha escolhida deverá ter no mínimo 6 caracteres e deve possuir números, letras maiúsculas e minúsculas"
      );
      setShowErrorMessage(true);
      return;
    } else {
      const payload = {
        old: oldPassword,
        new: newPassword,
      };
      dispatch(updateNewPassword(payload))
        .then(() => {
          setPopUpSucceedUpdatePassword(true);
          setUpdatePasswordMessage("Senha atualizada com sucesso");
          emptyForms();
        })
        .catch(() => {
          setVerifyOldPassword(true);
        });
    }
  };

  const emptyForms = () => {
    setOldPassword("");
    setNewPassword("");
    setNewPasswordConfirmation("");
  };

  const renderPopUpMobile = () => {
    if (width > 1024) {
      return (
        <BluePopUp
          text={updatePasswordMessage}
          onClick={() => setPopUpSucceedUpdatePassword(false)}
        />
      );
    } else {
      return (
        <GreyPopUp
          text={updatePasswordMessage}
          onClick={() => setPopUpSucceedUpdatePassword(false)}
        />
      );
    }
  };

  return (
    <div className="EditPassword">
      <div className="section-header">
        <h2>Alterar senha</h2>
        <ExitButton onClick={() => history.push("/perfil/colecao")} />
      </div>
      {showErrorMessage && <p className="alert-password">{errorMessage}</p>}
      <form onSubmit={(e) => handleUpdatePassword(e)}>
        <Input
          label="Sua senha atual"
          type="password"
          displayErrorMessage={verifyOldPassword}
          onKeyUp={() => setVerifyOldPassword(false)}
          errorMessage="Verifique sua antiga senha"
          value={oldPassword}
          updateState={setOldPassword}
          required
        />
        <Input
          label="Nova senha"
          type="password"
          onChange={setNewPassword}
          value={newPassword}
          updateState={setNewPassword}
          required
        />
        <Input
          label="Confirma nova senha"
          type="password"
          displayErrorMessage={errorPasswordConfirmation}
          value={newPasswordConfirmation}
          updateState={setNewPasswordConfirmation}
          onKeyUp={() => setErrorPasswordConfirmation(false)}
          errorMessage="Senha e confirmação diferentes"
          required
        />
        {passwordFetching ? (
          <div className="fetching-update">
            <div />
          </div>
        ) : (
          <button>{width > 1024 ? "Salvar" : "Salvar alterações"}</button>
        )}
      </form>
      {popUpSucceedUpdatePassword && renderPopUpMobile()}
    </div>
  );
};

export default EditPassword;
