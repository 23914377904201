export const canLoadFrame = (url, saveData, isFetching) => {
  const api = `https://2bpdqf0iwl.execute-api.sa-east-1.amazonaws.com/default/can-i-render-an-iframe?url=`
  fetch(api + url)
    .then(response => {
      if (response.ok) {
        return response.json()
      }
      throw response;
    })
    .then(data => {
      saveData(data)
    })
    .catch(error => {
      return error
    })
    .finally(() => {
      isFetching(false);
    })
}
