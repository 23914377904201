import React, { useEffect, useRef, useState } from 'react';
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSizeHook } from "../../../hooks/WindowHooks";
import { deleteContentSeeLater, infoSeeLaterList } from "../../../redux/actions/seeLater";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import CardFetching from "../../global/CardFetching/CardFetching";
import ContentCard from "../../global/ContentCard/ContentCard";
import ContentCardMobile from "../../global/ContentCardMobile/ContentCardMobile";
import "./SeeLater.scss";

const SeeLater = () => {
  const dispatch = useDispatch();
  const seeLater = useSelector(state => state.seeLater?.data);
  const seeLaterFetching = useSelector(state => state.seeLater?.isFetching);
  const [SeeLaterPopUp, setSeeLaterPopUp] = useState(false);
  const [seeLaterMessage, setSeeLaterMessage] = useState("");
  const { width } = useWindowSizeHook();
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadedItems, setLoadedItems] = useState([]);
  const observer = useRef(null);
  const endOfPageRef = useRef(null);

  useEffect(() => {
    if (pageNo === 1) {
      setLoadedItems([]);
    }

    if (!loading) {
      setLoading(true);
      dispatch(infoSeeLaterList({ page: pageNo })).then((newData) => {
        setLoadedItems((prevItems) => [...prevItems, ...newData.items]);
        setLoading(false);
      });
    }
  }, [pageNo]);

  useEffect(() => {
    if (seeLaterFetching || pageNo > seeLater?.totalPages) {
      return;
    }

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading) {
        setPageNo((prevPage) => prevPage + 1);
      }
    });

    if (endOfPageRef.current) {
      observer.current.observe(endOfPageRef.current);
    }
  }, [loading, seeLaterFetching]);

  const clickButton = e => {
    e.stopPropagation()
  }

  const renderCards = (item, key) => {
    if (width > 1024) {
      return !seeLaterFetching &&
        <ContentCard item={item} key={key} onDelete={(e) => {
          handleDeleteSeeLater(item.id)
          clickButton(e)
        }} />
    } else {
      return !seeLaterFetching && <ContentCardMobile key={key} item={item} />
    }
  }

  const handleDeleteSeeLater = (id) => {
    dispatch(deleteContentSeeLater(id))
      .then(() => {
        setSeeLaterPopUp(true)
        setSeeLaterMessage(`Conteúdo excluido da lista "Ver Mais Tarde"`)
        dispatch(infoSeeLaterList())
      })
      .catch(() => {
        setSeeLaterPopUp(true)
        setSeeLaterMessage("")
        dispatch(infoSeeLaterList())
      });
  }

  return (
    <div className="SeeLater">
      <Helmet title="Ver mais tarde - Alexandria" />
      <div className="seelater-view-cards">
        {seeLaterFetching && <CardFetching amount={4} />}
        {!seeLaterFetching && loadedItems?.map((item, key) => {
          return renderCards(item, key)
        })}
        {pageNo <= seeLater?.totalPages && <div className='end-of-page' ref={endOfPageRef} />}
      </div>
      {SeeLaterPopUp && <BluePopUp text={seeLaterMessage} onClick={() => setSeeLaterPopUp(false)} />}
    </div>
  );
};

export default SeeLater;
