import React from 'react';
import "./NotesTitle.scss";
import {ReactComponent as TextIcon} from "../../../../assets/images/card-text-black.svg";
import {ReactComponent as AudioIcon} from "../../../../assets/images/card-audio-black.svg";
import {ReactComponent as VideoIcon} from "../../../../assets/images/card-video-black.svg";
import NotesDescription from "../NotesDescription/NotesDescription";
import {slugify} from "../../../../redux/api/slugify";
import {useHistory} from "react-router-dom";

const NotesTitle = ({items, setDeleteNotePopUp, setDeleteNoteMessage}) => {
  const history = useHistory();
  const slug = slugify(items && items[0]?.content?.title);

  const icon = {
    TEXT: <TextIcon />,
    AUDIO: <AudioIcon />,
    VIDEO: <VideoIcon />
  }

  const text = {
    TEXT: "Texto",
    AUDIO: "Audio",
    VIDEO: "Video"
  }

  return (
    <div className="NotesTitle">
      <div className="notes-title-container">
        <div className="blue-marker">
          <span />
        </div>
        <div className="notes-suggestion-head"
             onClick={() => history.push(`/conteudo/${items && items[0]?.content?.id}/${slug}`)}>
          <div className="notes-suggestion-title">
            <p>{items && items[0]?.content?.title}</p>
          </div>
          <div className="notes-suggestion-icon">
            {icon[items && items[0]?.content?.mediaType]}
            <p>{text[items && items[0]?.content?.mediaType]}</p>
          </div>
        </div>
        {items?.map((info, key) => {
          const infoData = {
            text: info?.text,
            contentReference: {
              text: info?.contentReference?.text
            }
          }
          return <NotesDescription
            setDeleteNotePopUp={setDeleteNotePopUp}
            setDeleteNoteMessage={setDeleteNoteMessage}
            info={infoData} item={items} key={key} />
        })}
      </div>
    </div>
  );
};

export default NotesTitle;
