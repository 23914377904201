import {
  UPLOAD_USER_PHOTO_FAILED,
  UPLOAD_USER_PHOTO_REQUEST,
  UPLOAD_USER_PHOTO_REQUEST_SUCCEEDED
} from "../constants/user";

export const photo = (state = { data: [], isFetching: false, error: {} }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case UPLOAD_USER_PHOTO_REQUEST:
      newState.isFetching = true;
      return newState;

    case UPLOAD_USER_PHOTO_REQUEST_SUCCEEDED:
      newState.data = action.data;
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case UPLOAD_USER_PHOTO_FAILED:
      newState.error = action.error;
      newState.isFetching = false;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
