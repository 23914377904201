import React, {useEffect, useState} from 'react';
import "./NavbarMobile.scss";
import {useSelector} from "react-redux";
import Button from "../Button/Button";
import {useHistory, useLocation} from "react-router-dom";

const NavbarMobile = () => {
  const history = useHistory();
  const location = useLocation();
  const {pathname} = location;
  const [openSearch, setOpenSearch] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const tags = useSelector(state => state.tags?.data);
  const tagsList = [];
  
  Array.isArray(tags) && tags?.forEach((item) => {
    if (item?.referenceCount * item?.followerCount) {
      tagsList.push(item)
    }
  })
  
  const tagsReference = tagsList.reverse().splice(0, 25)
  
  useEffect(() => {
    openSearch
      ? document.body.style.overflow = 'hidden'
      : document.body.style.overflow = 'unset';
  }, [openSearch]);
  
  const handleSearch = () => {
    if (textSearch.length > 1) {
      history.push('/pesquisa?search=' + textSearch)
    }
  }
  
  const handleTagClick = nome => {
    history.push('/pesquisa?tags=' + nome)
  }
  
  return (
    <>
      <nav className="NavbarMobile">
        <button  style={{visibility: `${pathname !== "/" || openSearch ? "visible" : "hidden"}`}}
                 className="exit-entry-mobile" onClick={() => {
          pathname === "/" ? history.push("/") : history.go(-1)
          setOpenSearch(false)
        }}/>
        <p onClick={() => history.push("/")}>Alexandria</p>
        <button onClick={() => setOpenSearch(!openSearch)}/>
      </nav>
      <section
        style={{
          height: `${openSearch ? "100vh" : "0"}`,
          visibility: `${openSearch ? "visible" : "hidden"}`,
          opacity: `${openSearch ? 1 : 0}`
        }}
        className="search-navbar">
        <div
          className="search-nav-container">
          <input
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            placeholder="Pesquise por autor, título ou tema"
          />
          <Button onClick={() => handleSearch()} children="Pesquisar" primaryColor="#CFEC59"/>
          <div
            className="search-nav-tags">
            <h4>Explore uma tag nova</h4>
            {tagsReference?.map((item, key) => {
              return <p onClick={() => {
                setTextSearch(item?.name)
                handleTagClick(item?.id)
                pathname === "/pesquisa" && window.location.reload()
              }}
                        key={key}>{item?.name}</p>
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default NavbarMobile;
