import React from 'react';
import "./BottomComponent.scss";
import SecondImage from "../../../assets/images/extension-02.png"
import ThirdImage from "../../../assets/images/extension-03.png"
import FourthImage from "../../../assets/images/extension-04.png"
import Button from "../../global/Button/Button";
import {Link} from "react-router-dom";

const BottomComponent = () => {
  return (
    <div className="BottomComponent">
      <div className="firstContainer">
        <div className="imageBox">
          <img 
            className="firstSize" 
            src={SecondImage} 
            alt="Ilustração de pessoas cartunizadas utilizando a internet" 
          />
        </div>
        <div className="textBox">
          <h2>O que é reindexar?</h2>
          <p>Indexar novamente. E indexar? Organizar por parâmetros que viabilizem encontros. A internet indexou
            endereços digitais, o Google indexou informação. Usamos tags nas principais redes sociais para isso.
            E é por isso que reindexar é tão importante. Significa que agora vocês pode definir e ajustar os
            parâmetros pela sua lente.</p>
        </div>
      </div>
      <div className="secondContainer">
        <div className="textBox">
          <h2>Amplie a sua e demais lentes com os conteúdos curados.</h2>
          <p>Alexandria é uma nova forma de aprender e ensinar com a internet. Somos um ecossistema de
            conhecimentos importantes e de pessoas que pensam sobre eles. Somos transparentes nas recomendações e
            organização de resultados de busca. Nosso objetivo é o seu melhor aprendizado.</p>
        </div>
        <div>
          <img 
            className="secondSize" 
            src={ThirdImage} 
            alt="Ilustração cartunizada de uma pessoa desenhando" 
          />
        </div>
      </div>
      <div className="firstContainer">
        <div className="imageBox">
          <img 
            className="firstSize" 
            src={FourthImage} 
            alt="Ilustração cartunizada de papéis com texto" 
          />
        </div>
        <div className="textBox">
          <h2>Reindexe textos, vídeos e áudios diretamente na sua conta.</h2>
          <p>Aprofunde seu ponto de vista e inicie conversas poderosas com a sua rede.</p>
        </div>
      </div>
      <div className="thirdContainer">
        <div className="textBox">
          <h2>Cure-se com conteúdo!</h2>
          <p>Faça o download gratuitamente da extensão Alexandria.</p>
          <Link to={{ pathname: "https://chrome.google.com/webstore/detail/alexandria-extens%C3%A3o/ilamehpcfijmklpngcbemikbpmenhdnj?hl=en" }} target="_blank">
            <Button children="Fazer download" primaryColor="#CFEC59"/>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BottomComponent;
