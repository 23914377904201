import React from "react";
import "./MediaFormat.scss";
import PropTypes from "prop-types";

const MediaFormat = ({ label, updateState, checked, value }) => {
  const handleCheckbox = (event) => {
    updateState(event.target.checked);
  };

  return (
    <div className="MediaFormat">
      <div className="checkboxContent">
        <label className="container">
          {label}
          <input
            value={value}
            type="checkbox"
            onChange={handleCheckbox}
            name="radio"
            checked={checked}
          />
          <span className="checkmark" />
        </label>
      </div>
    </div>
  );
};

MediaFormat.propTypes = {
  label: PropTypes.string,
  updateState: PropTypes.func,
  value: PropTypes.bool,
};

export default MediaFormat;
