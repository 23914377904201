import * as queryString from "querystring";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { getContentsRequest } from "../../../redux/actions/contents";
import { urlFilters } from "../../../redux/api/common";
import SearchResultList from "../SearchResultList/SearchResultList";
import ResearchInformation from "./ResearchInformation/ResearchInformation";
import './SearchResult.scss';

const SearchResult = () => {
  const location = useLocation();
  const urlParams = queryString.parse(location.search.replace("?", ""))
  const [search] = useState(urlParams['search'])
  const [platform, setPlatform] = useState(urlParams['platform'])
  const [tags, setTags] = useState(!urlParams['tags'] || Array.isArray(urlParams['tags']) ? urlParams['tags'] : urlParams['tags'].split('?tags='))
  const [mediaType, setMediaType] = useState(urlParams['mediaType'])
  const [title] = useState(urlParams['title'])
  const [author, setAuthor] = useState(urlParams['author'])
  const [diversityAspects] = useState(urlParams['diversityAspects'])
  const [origin, setOrigin] = useState(urlParams['origin'])
  const [fromState, setFromState] = useState(urlParams['from'] ? new Date(urlParams['from']) : null)
  const [toState, setToState] = useState(urlParams['to'] ? new Date(urlParams['to']) : null)
  const [communicationVehicle, setCommunicationVehicle] = useState(urlParams['communicationVehicle']);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const contents = useSelector(state => state.contents)
  const dispatch = useDispatch();

  useEffect(() => {
    let filters = buildFilters()
    filters.pageSize = 9;
    filters.page = pageNo
    history.push(urlFilters("/pesquisa", buildFilters(filters)))

    dispatch(getContentsRequest(filters)).then(response => {
      setTotalPages(response?.totalPages)
      if (pageNo > 1) {
        let arr = [...data, ...response.items];
        setData(arr);
      }
      else {
        let arr = response.items;
        setData(arr);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setData,
    pageNo,
    search,
    platform,
    tags,
    mediaType,
    title,
    author,
    diversityAspects,
    origin,
    fromState,
    toState,
    communicationVehicle,
  ])

  const history = useHistory();

  const buildFilters = () => {
    let filters = {};

    if (search) {
      filters['search'] = search
    }
    if (platform) {
      filters['platform'] = platform
    }
    if (tags) {
      filters['tags'] = tags
    }
    if (mediaType) {
      filters['mediaType'] = mediaType
    }
    if (title) {
      filters['title'] = title
    }
    if (author) {
      filters['author'] = author
    }
    if (diversityAspects) {
      filters['diversityAspects'] = diversityAspects
    }
    if (origin) {
      filters['origin'] = origin
    }
    if (fromState) {
      filters['from'] = fromState.toISOString();
    }
    if (toState) {
      filters['to'] = toState.toISOString();
    }
    if (communicationVehicle) {
      filters['communicationVehicle'] = communicationVehicle
    }
    return filters;
  }

  return (
    <div className="SearchResult">
      <ResearchInformation tags={tags} setTags={setTags} search={search} />
      <div className="cards-search">
        <SearchResultList totalPages={totalPages}
          setPageNo={setPageNo} pageNo={pageNo}
          isFetching={contents.isFetching} contents={data}
          contentSearch={contents.data}
          mediaType={mediaType}
          setMediaType={setMediaType}
          origin={origin}
          setOrigin={setOrigin}
          author={author}
          setAuthor={setAuthor}
          platform={platform}
          setPlatform={setPlatform}
          fromState={fromState}
          setFromState={setFromState}
          toState={toState}
          setToState={setToState}
          communicationVehicle={communicationVehicle}
          setCommunicationVehicle={setCommunicationVehicle}
          tags={tags}
          isEmptyContent={!contents?.isFetching && contents.data.items ? contents.data.items.length === 0 : contents.length === 0}
        />
      </div>
    </div>
  );
};

export default SearchResult;
