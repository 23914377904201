import {
  addFavoriteRequest,
  addFavoriteRequestFailed,
  addFavoriteRequestSucceeded, deleteFavoriteRequest, deleteFavoriteRequestFailed, deleteFavoriteRequestSucceeded,
  getFavoriteRequest, getFavoriteRequestFailed, getFavoriteRequestSucceeded,
  getFavoriteListRequest, getFavoriteListRequestFailed, getFavoriteListRequestSucceeded
} from "../action-creators/favorite";
import {apiAddFavorite, apiDeleteFavorite, apiGetFavoriteList, apiGetIsFavoritedUserList} from "../api/favorite";

const addContentInFavorite = (contentId, payload) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(addFavoriteRequest());
  return apiAddFavorite(userID, contentId, payload)
    .then(json => {
      dispatch(addFavoriteRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
        dispatch(addFavoriteRequestFailed(response));
        return Promise.reject();
      }
    );
};

export const addFavorite = (contentId, payload) => dispatch => dispatch(addContentInFavorite(contentId, payload));

const getFavoriteList = () => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(getFavoriteRequest());
  return apiGetFavoriteList(userID)
    .then(json => {
      dispatch(getFavoriteRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
        dispatch(getFavoriteRequestFailed(response));
        return Promise.reject();
      }
    );
};

export const infoFavoriteList = () => dispatch => dispatch(getFavoriteList());

const deleteFavorite = (contentId) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(deleteFavoriteRequest());
  return apiDeleteFavorite(userID, contentId)
    .then(json => {
      dispatch(deleteFavoriteRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
        dispatch(deleteFavoriteRequestFailed(response));
        return Promise.reject();
      }
    );
};

export const deleteContentFavorite = (contentId) => dispatch => dispatch(deleteFavorite(contentId));

const getFavoriteUserList = (payload) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(getFavoriteListRequest());
  return apiGetIsFavoritedUserList(userID, payload)
    .then(json => {
      dispatch(getFavoriteListRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(response => {
        dispatch(getFavoriteListRequestFailed(response));
        return Promise.reject();
      }
    );
};

export const infoFavoriteUserList = (payload) => dispatch => dispatch(getFavoriteUserList(payload));
