import React, {useEffect} from 'react';
import "./Playlist.scss";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {shareJourney} from "../../../redux/actions/journey";
import CardSlide from "../../home/SlideCards/CardSlide/CardSlide";
import {Helmet} from "react-helmet";
import CardFetching from "../../global/CardFetching/CardFetching";

const Playlist = () => {
  const dispatch = useDispatch();
  const {playlistId} = useParams();
  const journey = useSelector(state => state.journey?.data);
  const journeyFetching = useSelector(state => state.journey?.isFetching);

  useEffect(() => {
    dispatch(shareJourney(playlistId))
  }, [dispatch, playlistId])
  
  return (
    <section className="Playlist">
      <Helmet title={`${journey?.name} - Alexandria`} />
      {!journeyFetching &&
      <div className="playlist-top">
        <h2>{journey?.name}</h2>
        <p><strong>{journey?.contents?.length}</strong> indexações</p>
      </div>}
      <div className="playlist-container">
        {journeyFetching ? <CardFetching amount={1}/> :
          journey?.contents && [...journey?.contents].map((item, key) => {
            return <CardSlide item={item} key={key} />
          })}
      </div>
    </section>
  );
};

export default Playlist;
