import {
  GET_RECOMMENDATION_REQUEST,
  GET_RECOMMENDATION_REQUEST_FAILED,
  GET_RECOMMENDATION_REQUEST_SUCCEEDED,
} from "../constants/recommendation";

export const recommendationRequest = () => {
  return {
    type: GET_RECOMMENDATION_REQUEST
  }
};

export const recommendationRequestSucceeded = (json) => {
  return {
    type: GET_RECOMMENDATION_REQUEST_SUCCEEDED,
    data: json
  }
};

export const recommendationRequestFailed = (error) => {
  return {
    type: GET_RECOMMENDATION_REQUEST_FAILED,
    error: error
  }
};
