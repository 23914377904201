import React from 'react';
import './Tag.scss';

const Tag = ({invertColors, item, onClick, active, passed}) => {
  return (
    <button className={`Tag ${invertColors ? "invertColors" : ""} ${active ? "active" : ""} ${passed ? "passed" : ""}`} onClick={onClick}>
      {item && item}
    </button>
  );
};

export default Tag;
