import {
  FACEBOOK_DATA_REQUEST,
  FACEBOOK_DATA_REQUEST_FAILED,
  FACEBOOK_DATA_REQUEST_SUCCEEDED,
  FACEBOOK_OAUTH_SUCCEEDED,
  GOOGLE_LOGIN_FAILED,
  GOOGLE_LOGIN_SUCCEEDED,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILED,
  LOGIN_REQUEST_SUCCEEDED,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_GOOGLE_FAILED,
  LOGIN_WITH_GOOGLE_SUCCEEDED
} from "../constants/login";

export const loginRequest = () => ({
  type: LOGIN_REQUEST
});

export const loginRequestSucceeded = (json) => ({
  type: LOGIN_REQUEST_SUCCEEDED,
  data: json
});

export const loginRequestFailed = (json) => ({
  type: LOGIN_REQUEST_FAILED,
  error: json
});

export const loginWithGoogle = () => ({
  type: LOGIN_WITH_GOOGLE
});

export const loginWithGoogleSucceeded = json => ({
  type: LOGIN_WITH_GOOGLE_SUCCEEDED,
  data: json
});

export const loginWithGoogleFailed = json => ({
  type: LOGIN_WITH_GOOGLE_FAILED,
  error: json
});

export const googleLoginSucceeded = (googleData, data) => ({
  type: GOOGLE_LOGIN_SUCCEEDED,
  googleData: googleData,
  data: data
});

export const googleLoginFailed = message => ({
  type: GOOGLE_LOGIN_FAILED,
  error: message
});

export const facebookOAuthSucceeded = code => ({
  type: FACEBOOK_OAUTH_SUCCEEDED,
  data: code
});

export const facebookDataRequest = () => ({
  type: FACEBOOK_DATA_REQUEST
});

export const facebookDataRequestSucceeded = json => ({
  type: FACEBOOK_DATA_REQUEST_SUCCEEDED,
  data: json
});

export const facebookDataRequestFailed = json => ({
  type: FACEBOOK_DATA_REQUEST_FAILED,
  error: json
});
