import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./EditProfile.scss";
import womanProfile from "../../../../assets/images/woman-edit-profile.svg";
import Input from "../../../global/form/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { useEmailField, useTextField } from "../../../../hooks/formHooks";
import { getUserInfo, updateUserDetails } from "../../../../redux/actions/user";
import BluePopUp from "../../../global/BluePopUp/BluePopUp";
import ExitButton from "../../../global/ExitButton/ExitButton";
import ModalUpdatePhoto from "../ModalUpdatePhoto/ModalUpdatePhoto";
import { useWindowSizeHook } from "../../../../hooks/WindowHooks";
import GreyPopUp from "../../../global/GreyPopUp/GreyPopUp";
import isValidEmail from "../../../../helper/isValidEmail";

const EditProfile = () => {
  const userData = useSelector((state) => state.user?.data);
  const userIsFetching = useSelector((state) => state.user.isFetching);
  const dispatch = useDispatch();
  const [name, setName] = useTextField(null);
  const [userName, setUserName] = useTextField(null);
  const [email, setEmail] = useEmailField(null);
  const [oldUserInfo, setOldUserInfo] = useState({
    email: null,
    name: null,
    userName: null
  });
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const [showUpdateProfileMessage, setShowUpdateProfileMessage] = useState("");
  const [errorUserName, setErrorUserName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [requiredField, setRequiredField] = useState(false);
  const [activeUpdatePhoto, setActiveUpdatePhoto] = useState(false);
  const { width } = useWindowSizeHook();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    setOldUserInfo({
      email: userData?.email,
      name: userData?.name,
      userName: userData?.username
    });
  }, [userData?.email, userData?.name, userData?.username]);

  const handleUpdateProfile = (e) => {
    e.preventDefault();

    const payload = {
      name: name || oldUserInfo.name,
      username: userName || oldUserInfo.userName,
      email: email?.toLowerCase() || oldUserInfo.email
    };
    
    if (
        oldUserInfo.name === payload.name && 
        oldUserInfo.email === payload.email && 
        oldUserInfo.userName === payload.username
      ) {
      setRequiredField(true);
      setTimeout(() => {
        setRequiredField(false);
      }, 3000);
      return;
    }

    if (!isValidEmail(payload.email)) {
      setErrorEmail(true);
      return;
    }

    dispatch(updateUserDetails(payload))
      .then(() => {
        emptyForms();
        dispatch(getUserInfo());
        setShowUpdateProfile(true);
        setShowUpdateProfileMessage("O seu perfil foi atualizado com sucesso!");
      })
      .catch((response) => {
        if (response.error === "User already registered") {
          setErrorUserName(true);
        }
        if (response.error === "Email already registered") {
          setErrorEmail(true);
        }
        if (response.error === "None of fields were informed") {
          setRequiredField(true);
          setTimeout(() => {
            setRequiredField(false);
          }, 3000);
        }
        return response.error;
      });
  };

  const emptyForms = () => {
    setUserName("");
    setEmail("");
    setName("");
  };

  const renderPhotoMobile = () => {
    if (width > 1024) {
      return (
        <button
          style={{
            backgroundImage: `url(${
              userData?.photo === undefined || userData?.photo === null
                ? womanProfile
                : userData?.photo
            })`,
          }}
          onClick={() => setActiveUpdatePhoto(true)}
        >
          <div className="image-photographic" />
        </button>
      );
    } else {
      return (
        <>
          <button
            style={{
              backgroundImage: `url(${
                userData?.photo === undefined || userData?.photo === null
                  ? womanProfile
                  : userData?.photo
              })`,
            }}
            onClick={() => setActiveUpdatePhoto(true)}
          ></button>
          <p
            onClick={() => setActiveUpdatePhoto(true)}
            style={{ marginTop: "10px" }}
          >
            Alterar foto de perfil
          </p>
        </>
      );
    }
  };

  const renderPopUpMobile = () => {
    if (width > 1024) {
      return (
        <BluePopUp
          text={showUpdateProfileMessage}
          onClick={() => setShowUpdateProfile(false)}
        />
      );
    } else {
      return (
        <GreyPopUp
          text={showUpdateProfileMessage}
          onClick={() => setShowUpdateProfile(false)}
        />
      );
    }
  };

  return (
    <div className="EditProfile">
      <div className="section-header">
        <h2>Editar perfil</h2>
        <ExitButton onClick={() => history.push("/perfil/colecao")} />
      </div>
      {renderPhotoMobile()}
      {requiredField && <p>Preencha ou altere algum dos campos!</p>}
      <form onSubmit={(e) => handleUpdateProfile(e)}>
        <Input
          label="Nome de usuário"
          placeholder={userData.username || "Casantiago"}
          updateState={(value) => setUserName(value.trim())}
          onKeyUp={() => setErrorUserName(false)}
          displayErrorMessage={errorUserName}
          errorMessage="Este nome de usuário já está em uso"
          type="text"
          value={userName ?? userData.username}
        />
        <Input
          label="Nome completo"
          placeholder={userData.name || "Capitolina Santiago"}
          updateState={setName}
          type="text"
          value={name ?? userData.name}
        />
        <Input
          label="Email"
          placeholder={userData.email || "capitolina@email.com"}
          onKeyUp={() => setErrorEmail(false)}
          displayErrorMessage={errorEmail}
          errorMessage="Este e-mail é inválido ou já está cadastrado"
          updateState={(value) => setEmail(value.trim())}
          type="email"
          value={email ?? userData.email}
        />
        {userIsFetching ? (
          <div className="fetching-update">
            <div />
          </div>
        ) : (
          <button>{width > 1024 ? "Salvar" : "Salvar alterações"}</button>
        )}
      </form>
      {activeUpdatePhoto && (
        <ModalUpdatePhoto setOpenUpdatePhoto={setActiveUpdatePhoto} />
      )}
      {showUpdateProfile && renderPopUpMobile()}
    </div>
  );
};

export default EditProfile;
