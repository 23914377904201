import React from 'react';
import './ContentAudio.scss'
import logoLune from "../../../assets/images/logo-lune.png"
import ReactPlayer from "react-player";

const ContentAudio = ({audioURL}) => {

  return (
    <section className="ContentAudio">
      <ReactPlayer
        style={{backgroundImage: `url(${logoLune})`}}
        controls={true} url={audioURL} />
    </section>
  );
};

export default ContentAudio;
