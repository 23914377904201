import React from "react";
import "./FrontCover.scss";
import UploadIcon from "../../../../assets/images/upload-icon.svg";

const FrontCover = ({ imageUrl, setImageUrl }) => {
  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    return validTypes.indexOf(file.type) !== -1;
  };

  return (
    <div className="FrontCover">
      <h2>Capa</h2>
      <div className="front-cover-container">
        <div className="container-photo">
          {imageUrl ? (
            <img
              className="image-cover"
              src={
                validateFile(imageUrl)
                  ? URL.createObjectURL(imageUrl)
                  : imageUrl
              }
            />
          ) : (
            <>
              <p>Seu conteúdo recebe mais acessos quando tem uma boa imagem!</p>
              <label htmlFor="file-upload" className="custom-file-upload">
                Carregar imagem <img src={UploadIcon} />
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={(e) => setImageUrl(e.target.files[0])}
              />
            </>
          )}
        </div>
        {imageUrl && (
          <div className="re-upload">
            <p>Seu conteúdo recebe mais acessos quando tem uma boa imagem!</p>
            <label htmlFor="file-upload" className="custom-file-upload">
              Carregar imagem <img src={UploadIcon} />
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={(e) => setImageUrl(e.target.files[0])}
            />
          </div>
        )}
      </div>
      <div className="cover-extension">
        <h3>Crie conteúdo mais rápido!</h3>
        <p>Use a extensão do Alexandria para seu navegador!</p>
        <a
          rel="noopener noreferrer"
          href="https://chrome.google.com/webstore/detail/alexandria-extens%C3%A3o/ilamehpcfijmklpngcbemikbpmenhdnj"
          target="_blank"
        >
          Baixa grátis <img src={UploadIcon} />
        </a>
      </div>
    </div>
  );
};

export default FrontCover;
